import {Button, Card, Col, Flex, Form, Input, Modal, Row, Space, Table, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/es/table";
import apiCall from "../../../functions/apiCall";
import {KeywordSearchProps} from "../../../pages/dataMng/competitorsRankHistoryType";
import SkeletonTable, {SkeletonTableColumnsType} from "../../table/skeletonTable";
import {emptyTableLayout, paginationOptions} from "../../../functions/tableFormatter";

const { Title } = Typography;

interface KeywordListModalProps {
    nvLoginId: string,
    selectedKwds: selectedKwdListProps[],  // selectedRows 타입 정의
    setSelectedKwds: React.Dispatch<React.SetStateAction<selectedKwdListProps[]>>, // setSelectedRows 타입 정의
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

/** 전체 키워드 리스트 Props */
interface kwdAllListProps {
    key: React.Key;
    kwdName: string;
}

// 선택된 키워드 목록의 타입 정의
interface selectedKwdListProps {
    kwdName: string;
}

const KeywordListModal = ({nvLoginId, selectedKwds, setSelectedKwds, isOpen, setOpen}: KeywordListModalProps) => {
    // 키워드 검색
    const [searchParams, setSearchParams] = useState<KeywordSearchProps>({nvLoginId: nvLoginId, kwdName: ''})
    // 키워드 전체 목록
    const [ rows, setRows ] = useState<kwdAllListProps[]>([]);
    // 선택된 키워드 목록 (최대 5개까지 가능)
    const [prevSelectedRows, setPrevSelectedRows] = useState<selectedKwdListProps[]>([]);

    /*************** 그리드 ****************/
    const [ gridLoading, setGridLoading ] = useState<boolean>(true);
    // 전체 키워드 목록 그리드
    const kwdAllListColumnsOptions: ColumnsType<kwdAllListProps> = [
        {title: '키워드', dataIndex: 'kwdName', align: 'center' },
        {title: '추가', dataIndex: 'addKwdBtn', align: 'center', render: (_, record) => {
                return <Button type="primary" className="pink" icon={<i className="ico ico-add"></i>}
                           onClick={() => {
                               setSelectedKwds(prevSelectedKwds => [...prevSelectedKwds, {
                                   key: record.key, kwdName: record.kwdName
                               }]);
                           }}
                           disabled={selectedKwds.some(item => item.kwdName === record.kwdName) || selectedKwds.length >= 5} // 중복 방지, 최대 5개 제한
                >추가</Button>
            }
        },
    ]

    // 선택된 키워드 목록 그리드
    const selectedKwdListColumnsOptions: ColumnsType<selectedKwdListProps> = [
        {title: '키워드', dataIndex: 'kwdName', align: 'center'},
        {title: '삭제', dataIndex: 'addKwdBtn', align: 'center', render: (_, record) => {
                return <Button type="primary" className="gray" size="small" icon={<i className="ico ico-delete"></i>}
                           onClick={() => {
                               setSelectedKwds(prevSelectedKwds => prevSelectedKwds.filter(item => item.kwdName !== record.kwdName));
                           }}
                >삭제</Button>
            }
        },
    ]


    // 키워드 목록 get~
    const searchEvent = () => {
        setGridLoading(true);
        apiCall.get("/data/competitors/rank/keywords", { params: searchParams })
            .then(resp => {
                // 배열인지 확인
                if (Array.isArray(resp.data)) {
                    const kwdData = resp.data.map((item: string, index: number) => ({ key: index, kwdName: item }));
                    setRows(kwdData);
                    setGridLoading(false);
                } else {
                    console.error('키워드 선택 API 응답 데이터 형식이 올바르지 않습니다.');
                    setRows([]);
                }
            })
            .catch(error => {
                console.error(" 키워드 선택 API 호출 중 오류 발생:", error);
            })
            .finally(() => {
                setGridLoading(false);  // 로딩 상태 종료
            });
    };

    useEffect(() => {
        searchEvent();
        setPrevSelectedRows(selectedKwds);
    }, [])

    return (
        <>
            <Modal
                title="키워드 선택"
                open={isOpen}
                width={1600}
                maskClosable={false} // 모달 외부 클릭 시 닫히지 않게 설정
                onCancel={() => {
                    setOpen(false);  setSelectedKwds(prevSelectedRows);
                }}
                footer={[
                    <Button type="primary" className="gray" size="large" key="footerCancelBtn" onClick={() => {
                        setOpen(false);
                        setSelectedKwds(prevSelectedRows);
                    }}>
                        취소
                    </Button>,
                    <Button type="primary" className="pink" size="large" key="footerSaveBtn" onClick={() => setOpen(false)}>저장</Button>,
                ]}
            >
                <Row gutter={32}>
                    <Col span={11}>
                        {/* <!-- Wrap-Datagrid-01 : Start --> */}
                        <section className="wrap-section wrap-datagrid">
                            <div className="box-body">
                                {/* <!-- Wrap-Filter : Start --> */}
                                <section className="wrap-section wrap-filter">
                                    <div className="box-body">
                                        <Card>
                                            <Flex gap={24}>
                                                <div className="box-left">
                                                    <i className="ico ico-filter"></i>
                                                </div>
                                                <div className="box-center">
                                                    <Form>
                                                        <Space size={[32, 24]} wrap>
                                                            <Form.Item colon={false} rules={[{required: true, message: '검색할 키워드를 입력하세요.'}]}>
                                                                <Input placeholder="키워드를 입력하세요." onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    const {value} = e.target;
                                                                    setSearchParams({ ...searchParams, kwdName: value});
                                                                }} onPressEnter={searchEvent}
                                                                />
                                                            </Form.Item>
                                                        </Space>
                                                    </Form>
                                                </div>
                                                <div className="box-right">
                                                    <Button type="primary" className="pink" onClick={searchEvent}>조회하기</Button>
                                                </div>
                                            </Flex>
                                        </Card>
                                    </div>
                                </section>
                                {/* <!-- Wrap-Filter : End --> */}
                                <SkeletonTable loading={gridLoading} columns={kwdAllListColumnsOptions as SkeletonTableColumnsType[]}>
                                    <Table
                                        key={"key"} bordered
                                        columns={kwdAllListColumnsOptions}
                                        dataSource={rows}
                                        rowKey="key"
                                        pagination={{
                                            ...paginationOptions(rows),  // 기존 옵션들 유지
                                            showSizeChanger: false,      // 페이지 사이즈 선택 박스 숨기기
                                        }}
                                        locale={emptyTableLayout(["키워드가 존재하지 않습니다."])}
                                    />
                                </SkeletonTable>
                            </div>
                        </section>
                        {/* <!-- Wrap-Datagrid-01 : End --> */}
                    </Col>
                    <Col span={2} className="txt-center" style={{margin: 'auto'}}>
                        <i className="ico ico-arrow-next"></i>
                    </Col>
                    <Col span={11}>
                        <div style={{marginTop: '55px'}}></div>
                        {/* <!-- Wrap-Datagrid : Start --> */}
                        <section className="wrap-section wrap-datagrid">
                            <div className="box-body">
                                <section className="wrap-section wrap-filter">
                                    <div className="box-header">
                                        <div className="box-left">
                                            <Title level={4} className="fc-gray-700">추가된 키워드</Title>
                                        </div>
                                    </div>
                                </section>
                                <SkeletonTable loading={gridLoading} columns={selectedKwdListColumnsOptions as SkeletonTableColumnsType[]}>
                                    <Table
                                        key={"kwdName"}
                                        bordered
                                        columns={selectedKwdListColumnsOptions}
                                        dataSource={selectedKwds}
                                        rowKey="kwdName"
                                        pagination={false}
                                        locale={emptyTableLayout(["선택된 키워드가 없습니다."])}
                                    />
                                </SkeletonTable>
                            </div>
                        </section>
                        {/* <!-- Wrap-Datagrid : End --> */}
                    </Col>
                </Row>
            </Modal>

        </>
    )
}
export default KeywordListModal;