import {KeywordBidManageGridProps} from "../../../../pages/kwdBid/kwdBidTypes";
import React, {useEffect, useState} from "react";
import {Button, Card, DatePicker, Flex, Form, Modal, Radio, Space, Table, Tag, Typography} from "antd";
import dayjs from "dayjs";
import {emptyTableLayout, paginationOptions, prettyNumber} from "../../../../functions/tableFormatter";
import locale from 'antd/es/date-picker/locale/ko_KR';
import {CSVLink} from "react-csv";
import {nameTreeFormatter} from "../../../../functions/nbsFormatter";
import apiCall from "../../../../functions/apiCall";
import SkeletonTable, {SkeletonTableColumnsType} from "../../../table/skeletonTable";
import {ColumnsType} from "antd/es/table";
import RankHistoryModal from "./rankHistoryModal";
import BidStatusCaseModal from "./bidStatusCaseModal";


interface BidHistoryModalProps {
    selectRow: KeywordBidManageGridProps,
    isOpen: boolean,
    setOpen:  React.Dispatch<React.SetStateAction<boolean>>
}
interface BidHistoryRowProps {
    hisId: number, kwdName: String
    nowRank : number | string, hopeRank : number,
    nowBidCost : number, maxBidCost : number,
    bidStatus: string, bidApiTime : string
}
const BidHistoryModal = ({selectRow, isOpen, setOpen}: BidHistoryModalProps) => {
    const [ searchParams, setSearchParams ] = useState<{searchType: string, searchDate: string}>({searchType:"RECENT", searchDate: dayjs().format('YYYYMMDD')});
    const [ historyRows, setHistoryRows ] = useState<BidHistoryRowProps[]>([]);
    const [ historyDownloadRows, setHistoryDownloadRows ] = useState<BidHistoryRowProps[]>([]);
    const [ gridLoading, setGridLoading ] = useState<boolean>(true);

    /** 모달 플래그 **/
    const [ rankHistoryModalYn, setRankHistoryModalYn ]  = useState<boolean>(false);
    const [ bidStatusCaseModalYn, setBidStatusCaseModalYn ] = useState<boolean>(false);

    const gridColumns: ColumnsType<BidHistoryRowProps> = [
        {title: '현재 순위', dataIndex: 'nowRank', align: 'center',
            render: (nowRank: number) => nowRank === 0 ? "없음" : nowRank
        },
        {title: '목표 순위', dataIndex: 'hopeRank', align: 'center' },
        {title: '현재 입찰가', dataIndex: 'nowBidCost', align: 'center',
            render: (nowBidCost: number) => prettyNumber(nowBidCost)
        },
        {title: '최대 입찰가', dataIndex: 'maxBidCost', align: 'center',
            render: (maxBidCost: number) => prettyNumber(maxBidCost)
        },
        {title: '입찰 상태', dataIndex: 'bidStatus', align: 'center',
            render: (text: string, record) => drawBidStatus(text, record),
        },
        {title: '입찰 시간', dataIndex: 'bidApiTime', align: 'center'},
    ];

    const loadBidHistories = () => {
        const bidDate = searchParams.searchDate === undefined ? null : dayjs(searchParams.searchDate).format("YYYYMMDD");
        setGridLoading(true);
        apiCall.post("/keywordBid/bidKwd/loadBidHistories", {basicDate: bidDate, kwdId: selectRow.kwdId})
            .then(resp => {
                setHistoryRows(resp.data)
            })
            .finally(() => setGridLoading(false));
    }

    useEffect(() => {
        let copies = [...historyRows];
        copies.map(v => v.bidStatus = v.bidStatus === "[크롤링 에러] 광고 부족" ? "노출광고 부족" : v.bidStatus === "[크롤링 에러] 노출광고 부족" ? "노출광고 부족" : v.bidStatus)
        setHistoryDownloadRows(copies)
    }, [historyRows])

    const drawBidStatus = (text: string, record: BidHistoryRowProps) => {
        const keyId = record.hisId+"_"+text;
        let successCase = ["광고 미노출 시간(SKIP)", "순위 동결 (SKIP)", "MIN 입찰", "MAX 입찰", "정상 입찰"];
        let failCase = ["노출광고 부족", "[크롤링 에러] 노출광고 부족", "[크롤링 에러] 노출광고 부족", "[크롤링 에러] 슬롯 부족", "캠페인 OFF", "캠페인 중지", "비즈채널 오류", "광고그룹 오류", "광고그룹 OFF", "광고그룹 중지", "키워드 오류", "키워드 OFF","광고영역 오류","API 오류"];

        if (successCase.includes(text)) return <Tag color={"green"} key={keyId}>{text}</Tag>;
        else if(text === "[크롤링 에러] 광고 부족") return <Tag color={"red"} key={keyId}>노출광고 부족</Tag>;
        else if(text === "[크롤링 에러] 노출광고 부족") return <Tag color={"red"} key={keyId}>노출광고 부족</Tag>;
        else if (failCase.includes(text)) return <Tag color={"red"} key={keyId}>{text}</Tag>;
        else return <Tag color={"red"} key={keyId}>{"모르는 입찰"}</Tag>;
    }

    // 순위보기 모달 open 이벤트
    const openRankHistoryModal = () => {
        setRankHistoryModalYn(true);
    }
    
    useEffect(() => {
        //입찰 이력 로드
        loadBidHistories();
    }, [])

    return (
        <>
            <Modal
                title="입찰 로그 이력"
                open={isOpen}
                maskClosable={false} // 모달 외부 클릭 시 닫히지 않게 설정
                onCancel={() => setOpen(false)}
                width={1200}
                footer={[ <Button type="primary" className="pink" size="large" key={"footerCloseBtn"} onClick={() => setOpen(false)}>확인</Button> ]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">키워드</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Typography.Text className="fz-15 fw-exbold fc-gray-500">{selectRow.kwdName}</Typography.Text>
                                    </div>
                                    <div className="form-group">
                                        {nameTreeFormatter([selectRow.advName, selectRow.campName, selectRow.groupName])}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}

                {/* <!-- Wrap-Datagrid : Start --> */}
                <section className="wrap-section wrap-datagrid">
                    <div className="box-header">
                        <div className="box-left">
                            <Typography.Title level={4} className="fc-gray-700">입찰 이력 목록</Typography.Title>
                        </div>
                        <div className="box-right">
                            <Button className="pink" onClick={() => setBidStatusCaseModalYn(true)}>입찰 상태 케이스</Button>
                            <CSVLink
                                headers={[
                                    {label:"키워드", key: "kwdName"},
                                    {label:"현재 순위", key: "nowRank"},
                                    {label:"목표 순위", key: "hopeRank"},
                                    {label:"현재 입찰가", key: "nowBidCost"},
                                    {label:"최대 입찰가", key: "maxBidCost"},
                                    {label:"입찰 상태", key: "bidStatus"},
                                    {label:"입찰 시간", key: "bidApiTime"}
                                ]}
                                filename={"입찰 이력 다운로드"}
                                data={historyDownloadRows.map(historyRow =>
                                        historyRow.nowRank === 0 ? { ...historyRow, nowRank: "없음" } :
                                        historyRow.bidStatus === "[크롤링 에러] 광고 부족" ? { ...historyRow, bidStatus : "노출광고 부족"} : historyRow.bidStatus === "[크롤링 에러] 노출광고 부족" ? { ...historyRow, bidStatus : "노출광고 부족"} : historyRow
                                    )
                                }
                                className="btn btn-primary">
                                <Button className="pink">다운로드</Button>
                            </CSVLink>
                        </div>
                    </div>
                    <div className="box-body">
                        {/* <!-- Wrap-Filter : Start --> */}
                        <section className="wrap-section wrap-filter">
                            <div className="box-body">
                                <Card>
                                    <Flex gap={24}>
                                        <div className="box-left">
                                            <i className="ico ico-filter"></i>
                                        </div>
                                        <div className="box-center">
                                            <Form>
                                                <Space size={[32, 24]} wrap>
                                                    <Form.Item colon={false}>
                                                        <Radio.Group name={"searchType"} value={searchParams.searchType}
                                                                     onChange={(e)=>
                                                                         setSearchParams({searchType : e.target.value,
                                                                             searchDate: dayjs().format("YYYYMMDD")
                                                                         })
                                                                     }
                                                        >
                                                            <Space>
                                                                <Radio value={"RECENT"} defaultChecked>최근 이력 보기</Radio>
                                                                <Radio value={"PAST"} defaultChecked={false}>
                                                                    과거 이력 조회
                                                                    {searchParams.searchType === "PAST" ?
                                                                        <Space>
                                                                        <DatePicker
                                                                            locale={locale}
                                                                            format="YYYY-MM-DD"
                                                                            value={dayjs(searchParams.searchDate)}
                                                                            allowClear={false}
                                                                            disabledDate={(current) => current > dayjs().endOf('day')}
                                                                            onChange={(date) => {
                                                                                setSearchParams({...searchParams,
                                                                                    searchDate: dayjs(date).format('YYYYMMDD')
                                                                                })
                                                                            }}
                                                                        />
                                                                            <Button className="pink" onClick={openRankHistoryModal}>순위 보기</Button>
                                                                        </Space>
                                                                        : null
                                                                    }
                                                                </Radio>
                                                            </Space>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Space>
                                            </Form>
                                        </div>
                                        <div className="box-right">
                                            <Button type="primary" className="pink" onClick={loadBidHistories}>조회하기</Button>
                                        </div>
                                    </Flex>
                                </Card>
                            </div>
                        </section>
                        {/* <!-- Wrap-Filter : End --> */}
                        <SkeletonTable loading={gridLoading} columns={gridColumns as SkeletonTableColumnsType[]}>
                            <Table
                                key={"bidHistoryGrid"}
                                rowKey={"hisId"}
                                columns={gridColumns}
                                dataSource={historyRows}
                                locale={emptyTableLayout(["등록된 키워드가 없습니다.", "[키워드 등록] 버튼을 클릭하여 입찰 키워드를 등록하세요."])}
                                bordered
                                pagination={paginationOptions(historyRows)}
                            />
                        </SkeletonTable>
                    </div>
                </section>
                {/* <!-- Wrap-Datagrid : End --> */}
            </Modal>

            {/* <!-- 순위보기 모달 --> */}
            {rankHistoryModalYn &&
                <RankHistoryModal selectRow={selectRow} basicDate={searchParams.searchDate} isOpen={rankHistoryModalYn} setOpen={setRankHistoryModalYn} />
            }
            {/* <!-- 입찰 상태 케이스 모달 --> */}
            {bidStatusCaseModalYn &&
                <BidStatusCaseModal isOpen={bidStatusCaseModalYn} setOpen={setBidStatusCaseModalYn} />
            }
        </>
    )
};

export default BidHistoryModal;