import {Input, Select, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {
    BidScheduleManageGridProps,
    BidScheduleProps,
    BidScheduleStateAdvProps
} from "../../../pages/kwdBid/kwdBidTypes";

interface BidScheduleStateAdvComponentProps {
    advList: {advId: number, advName: string}[],
    selectedAdv:  undefined | BidScheduleManageGridProps,
    scheduleParams: BidScheduleProps,
    setScheduleParams:  React.Dispatch<React.SetStateAction<BidScheduleProps>>
    componentResetEventYn: boolean
}
const BidScheduleStateAdv = ({ advList, selectedAdv, scheduleParams, setScheduleParams, componentResetEventYn }: BidScheduleStateAdvComponentProps) => {

    /** 상단 광고주 / 스케줄명 파라미터 */
    const [ advParams, setAdvParams ] = useState<BidScheduleStateAdvProps>(
        {advId: undefined, advName: '', bidScheduleId: undefined, bidScheduleName: ''});

    /** 수정 화면 진입시 광고주 선택 클릭 불가를 위한 파라미터 */
    const [ isAdvParams, setIsAdvParams ] = useState<BidScheduleStateAdvProps>(
        {advId: undefined});

    const advComponentInit = () => {
        if(selectedAdv !== undefined) {
            setAdvParams({
                advId: selectedAdv.advId,
                advName: selectedAdv.advName,
                bidScheduleId: selectedAdv.bidScheduleId,
                bidScheduleName: selectedAdv.bidScheduleName});
            setIsAdvParams({advId: selectedAdv.advId});
        }
    }

    useEffect(() => {
        advComponentInit();
    }, [])

    useEffect(() => {
        setScheduleParams({...scheduleParams,
            advId: advParams.advId, advName: advParams.advName,  bidScheduleId: advParams.bidScheduleId, bidScheduleName: advParams.bidScheduleName
        });
    }, [advParams])

    useEffect(() => {
        if(componentResetEventYn){
            setAdvParams({advId: undefined, advName: '', bidScheduleId: undefined, bidScheduleName: ''});
        }
    }, [componentResetEventYn])

    return (
        <>
        {/* <!-- Wrap-Tbl : Start --> */}
        <section className="wrap-section wrap-tbl">
            <div className="box-header">
                <div className="box-left">
                    <Typography.Title level={3} className="fc-gray-700">입찰 스케줄 등록 정보</Typography.Title>
                </div>
            </div>

            <div className="box-body">
                <div className="tbl">
                    <dl>
                        <dt>
                            <div className="dt-inner">
                                <span className="fz-15 fc-gray-500">광고주</span>
                                <i className="txt-essential"></i>
                            </div>
                        </dt>
                        <dd>
                            <div className="form-group">
                                <Select
                                    labelInValue
                                    placeholder="광고주를 선택하세요."
                                    style={{ width: 500 }}
                                    value={advParams.advId === undefined ? undefined : { value: advParams.advId, label: advParams.advName }}
                                    disabled={isAdvParams.advId !== undefined}
                                    onChange={(selectedOption) => {
                                        setAdvParams({
                                            ...advParams,
                                            advId: selectedOption?.value,
                                            advName: selectedOption?.label,
                                        });
                                    }}
                                    dropdownStyle={{ maxHeight: advParams.advId === undefined ? 500 : 200, overflowY: 'auto' }}
                                    listHeight={200}
                                >
                                    {advList.map((item) => (
                                        <Select.Option key={item.advId} value={item.advId}>
                                            {item.advName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </dd>
                    </dl>
                    <dl>
                        <dt>
                            <div className="dt-inner">
                                <span className="fz-15 fc-gray-500">스케줄명</span>
                                <i className="txt-essential"></i>
                            </div>
                        </dt>
                        <dd>
                            <div className="form-group">
                                <Input className="w-500" name="bidScheduleName"
                                       value={advParams.bidScheduleName}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>)=> setAdvParams({...advParams, bidScheduleName: e.target.value})}
                                       placeholder="스케줄명을 입력하세요."
                                />
                            </div>
                        </dd>
                    </dl>
                </div>
            </div>
        </section>
        {/* <!-- Wrap-Tbl : End --> */}
        </>
    )
};

export default BidScheduleStateAdv;