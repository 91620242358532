import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "antd";

import {errorAlert, successAlert} from "../../../functions/alertFn";
import {ApiManageGridProps} from "../../../pages/connectionMng/connectionMngTypes";
import modal from "antd/es/modal";
import {ExclamationCircleFilled} from "@ant-design/icons";
import apiCall from '../../../functions/apiCall';

interface ModifyApiModalProps {
    record: ApiManageGridProps,
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setGridRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const ModifyApiModal = ({record, isOpen, setOpen, setGridRefresh }: ModifyApiModalProps) => {

    const initModifyForm ={apiLicenseId:record.apiLicenseId, loginId : record.loginId, customerId : record.customerId, accessLicence: '', secretKey: ''};
    const [ modifyForm, setModifyForm ] = useState<{apiLicenseId:number, loginId: string, customerId: number, accessLicence: string, secretKey: string}>(initModifyForm);

    const [form] = Form.useForm();

    //모달 초기화
    const modalReset = () => {
        form.resetFields();
        setModifyForm(initModifyForm);
    }

    //API 수정 벨리데이션
    const modifyValidation = () => {
        if(modifyForm.loginId === undefined || modifyForm.loginId === '' ) {
            errorAlert("로그인 ID를 입력해주세요.");
            return false;
        } else if(modifyForm.customerId === undefined || modifyForm.customerId === null ) {
            errorAlert("Customer Id를 입력해주세요.");
            return false;
        } else if(modifyForm.accessLicence === undefined || modifyForm.accessLicence === '' ) {
            errorAlert("AccessLicence를 입력해주세요.");
            return false;
        } else if(modifyForm.secretKey === undefined || modifyForm.secretKey === '' ) {
            errorAlert("SecretKey를 입력해주세요.");
            return false;
        }
        return true;
    }

    const onChangeModifyFormEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setModifyForm({ ...modifyForm, [name]: value});
    }

    //API 수정 이벤트
    const modifiyApiEvent = () => {
        if(modifyValidation()) {
            modal.confirm({
                icon: <ExclamationCircleFilled rev={undefined} />,
                title: '해당 내용으로 API 저장하시겠습니까?',
                content: '저장된 API는 이전 정보로 복구가 불가능합니다.',
                okText: '확인', cancelText: '취소',
                okButtonProps: { className: "pink ant-btn-lg" },
                cancelButtonProps: { type: 'primary', className: "gray ant-btn-lg" },
                onOk () {
                    apiCall.put("/connMng/apiMng/updateApiLicenseInfo", modifyForm)
                        .then(resp => {
                            successAlert("API 저장 완료되었습니다.")
                            modalReset();
                            setGridRefresh(true);
                            setOpen(false);
                        })
                        .catch(error => {
                            errorAlert("API 정보를 확인해 주세요.")
                        })
                        .finally(() => {
                        })
                    

                }

            })
        }
    }

    return (
        <>
            <Modal
                title="API 수정"
                open={isOpen}
                onCancel={() => {modalReset(); setOpen(false); }}
                afterClose={() => { modalReset(); setOpen(false); }}
                width={900}
                footer={[
                    <Button type="primary" className="gray" size="large" key="footerCancelBtn" onClick={() => {modalReset(); setOpen(false); }}>취소</Button>,
                    <Button type="primary" className="pink" size="large" key="footerChargeBtn" onClick={modifiyApiEvent}>저장</Button>,
                ]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    {/* <div className="box-header">
                        <div className="box-left">
                            <Title level={4} className="fc-gray-500">API 수정</Title>
                        </div>
                    </div>*/}
                    <div className="box-body">
                        <Form form={form} name="dependencies" autoComplete="off" layout="vertical">
                            <div className="tbl">
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-15 fc-gray-500">로그인 ID</span>
                                            <i className="txt-essential"></i>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <Input className="w-500" name="loginId" value={modifyForm.loginId} onChange={onChangeModifyFormEvent} />
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-15 fc-gray-500">Customer Id</span>
                                            <i className="txt-essential"></i>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <Input className="w-500" name="customerId" value={modifyForm.customerId} onChange={onChangeModifyFormEvent} />
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-15 fc-gray-500">AccessLicence</span>
                                            <i className="txt-essential"></i>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <Input className="w-500" name="accessLicence" value={modifyForm.accessLicence} onChange={onChangeModifyFormEvent} />
                                        </div>
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>
                                        <div className="dt-inner">
                                            <span className="fz-15 fc-gray-500">SecretKey</span>
                                            <i className="txt-essential"></i>
                                        </div>
                                    </dt>
                                    <dd>
                                        <div className="form-group">
                                            <Input className="w-500" name="secretKey" value={modifyForm.secretKey} onChange={onChangeModifyFormEvent} />
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </Form>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}
            </Modal>
        </>
    );
};
export default React.memo(ModifyApiModal);