import {KeywordBidManageGridProps} from "../../../../pages/kwdBid/kwdBidTypes";
import React, {useEffect, useState} from "react";
import {Button, Checkbox, Input, Modal, Select, Space, Tag, Typography} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {
    adAreaDivList,
    bidItemDivList,
    bidRankList,
    bidScheduleList,
    bookmarkList,
    onOffList
} from "../keywordBidManageFilterFixList";
import {useNavigate} from "react-router-dom";
import {errorAlert, successAlert} from "../../../../functions/alertFn";
import {isNullOrEmpty, nbsBidCostInputCheck} from "../../../../functions/validator";
import {nameTreeFormatter} from "../../../../functions/nbsFormatter";
import apiCall from "../../../../functions/apiCall";
import modal from "antd/es/modal";
import {ExclamationCircleFilled} from "@ant-design/icons";
import ScheduleCheckModal from "../../bidScheduleManage/scheduleCheckModal";

/** 키워드 입찰 관리 - 입찰 설정 변경 모달 */
interface BidConfigModalProps {
    selectedRows: KeywordBidManageGridProps[],
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setGridRefresh: React.Dispatch<React.SetStateAction<boolean>>,
}
/** 입찰 설정 항목 타입 **/
interface RowBidConfigProps {
    advName : string,
    bidKwdId : number,
    kwdName : string,
    campName : string,
    groupName : string,
    bookmarkDiv? : string,
    bidConfigYn : boolean,
    bidItemDiv?: string,
    adAreaDiv : string,
    hopeRank : number,
    nowRank? : number,
    maxBidCost? : string,
    nowBidCost? : number,
    minBidCost? : string,
    scheduleId : number | null,
    scheduleName? : string,
    scheduleList? : { scheduleId: number, scheduleName: string}[] | undefined
}

/** 다중 팝업일 경우 사용되는 체크박스 타입 */
interface BidConfigCheckedProps {
    bookmarkChecked : boolean,
    bidConfigYnChecked : boolean,
    bidItemDivChecked : boolean,
    adAreaDivChecked : boolean,
    hopeRankChecked : boolean,
    maxBidCostChecked : boolean,
    minBidCostChecked : boolean,

}

const BidConfigModal = ({selectedRows, isOpen, setOpen, setGridRefresh}: BidConfigModalProps) => {
    const navigate = useNavigate();
    /** 각 키워드 별 입찰 설정 항목 */
    const [ rowBidConfigs, setRowBidConfigs ] = useState<RowBidConfigProps[]>([]);

    /** 체크했을 경우 동일내용 복사 여부 체크(다중일 경우 한정) */
    const [ checkFlags, setcheckFlags ] = useState<BidConfigCheckedProps>({
        bookmarkChecked : false,
        bidConfigYnChecked : false,
        bidItemDivChecked : false,
        adAreaDivChecked : false,
        hopeRankChecked : false,
        maxBidCostChecked : false,
        minBidCostChecked : false,

    });

    /** 스케줄 확인용 파라미터 */
    const [ selectedScheduleId, setSelectedScheduleId] = useState<number>(0);
    const [ scheduleCheckModalYn, setScheduleCheckModalYn] = useState<boolean>(false);
    /** 항목별 체크박스 값 변경 */
    const onChangeCheckbox = (e: CheckboxChangeEvent) => {
        const { name, checked } = e.target;
        setcheckFlags({...checkFlags, [name!!]: checked});
    }

    const pageInit = () => {
        apiCall.post("/keywordBid/bidKwd/loadConfigData", selectedRows.map(value => value.bidKwdId))
            .then(resp => setRowBidConfigs(resp.data))
            .catch()
            .finally()
    }

    /** 입찰 설정 항목 셀렉트 박스로 변경되는 항목에 대한 업데이트 */
    const onChangeBidConfigSelectBox = (value: string | number | boolean, keyId: number, column: string) => {
        let copy = [...rowBidConfigs];
        setRowBidConfigs(copy.map((row) => {
            if(row.bidKwdId === keyId){
                return { ...row, [column] : value}
            } else {
                return {...row};
            }
        }));
    }
    /** 입찰 설정 항목 인풋으로 변경되는 항목에 대한 업데이트 */
    const onChangeBidConfigInputValue = (e:React.ChangeEvent<HTMLInputElement>, keyId: number) => {
        const { name, value } = e.target;
        let copy = [...rowBidConfigs];
        setRowBidConfigs(copy.map((row) => {
            if(row.bidKwdId === keyId){
                return { ...row, [name] : value}
            } else {
                return {...row};
            }
        }));
    }

    /** 체크박스된 항목을 동일하게 값 업데이트 **/
    const copyFirstBidConfigEvent = () => {
        const first = {...rowBidConfigs[0]};
        let copy = [...rowBidConfigs];
        copy.map((row) => {
            checkFlags.bookmarkChecked && (row.bookmarkDiv = first.bookmarkDiv)
            checkFlags.bidConfigYnChecked && (row.bidConfigYn = first.bidConfigYn)
            checkFlags.bidItemDivChecked && (row.bidItemDiv = first.bidItemDiv)
            checkFlags.adAreaDivChecked && (row.adAreaDiv = first.adAreaDiv)
            checkFlags.hopeRankChecked && (row.hopeRank = first.hopeRank)
            checkFlags.maxBidCostChecked && (row.maxBidCost = first.maxBidCost)
            checkFlags.minBidCostChecked && (row.minBidCost = first.minBidCost)
            }
        );

        setRowBidConfigs(copy);
    }
    const bidConfigValidation = () => {
        for(let i = 0; i < rowBidConfigs.length; i++){
            const row = rowBidConfigs[i];
            // 아래 벨리데이션 쭉 추가해주자..
            //최소 / 최대 입찰가 벨리데이션(화면상에 미기입 관련 내용이 없어서 그냥 넘어가는가보다 싶다..
            if(row.minBidCost !== undefined) {
                if(!nbsBidCostInputCheck(row.minBidCost)){
                    errorAlert("입찰가는 70 이상 100,000 이하, 10 단위의 숫자로 입력해주세요.");
                    return false;
                }
            }
            if(row.maxBidCost !== undefined) {
                if(!nbsBidCostInputCheck(row.maxBidCost)){
                    errorAlert("입찰가는 70 이상 100,000 이하, 10 단위의 숫자로 입력해주세요.");
                    return false;
                }
            }
            if(row.minBidCost !== undefined && row.maxBidCost !== undefined) {
                const numberMaxBidCost = parseInt(row.maxBidCost);
                const numberMinBidCost = parseInt(row.minBidCost);
                //최소 입찰가 > 최대 입찰가일 경우
                if(numberMinBidCost > numberMaxBidCost) {
                    errorAlert("최소입찰가를 최대입찰가 이하로 입력해주세요.");
                    return false;
                }
            }

            let isValidHopeRank: boolean = true;
            if (row.adAreaDiv === 'PC_TOTAL' && row.hopeRank > 15) isValidHopeRank = false;
            else if (row.adAreaDiv === 'PC_DETAIL' && row.hopeRank > 25) isValidHopeRank = false;
            else if (row.adAreaDiv === 'MO_TOTAL' && row.hopeRank > 5) isValidHopeRank = false;
            else if (row.adAreaDiv === 'MO_DETAIL' && row.hopeRank > 15) isValidHopeRank = false;
            if (!isValidHopeRank) {
                errorAlert("목표순위는 광고영역에 맞는 범위 내 숫자로 입력해주세요. <br /> PC: 1~15 / PC더보기: 1~25 / MO: 1~5 / MO더보기: 1~15");
                return false;
            }
        }
        return true;
    }
    const bidConfigEvent = () => {
        if(bidConfigValidation()) {
            rowBidConfigs.map((config)=> {
                // 정보 로드 시 북마크가 없는 형태이고, 셀렉트박스에서 변경하지 않았을 경우 데이터가 없으므로 북마크 없음을 강제로 마킹해준다.
                // 서버단으로 NULL 문자열을 보내면 db 적재 시 null로 처리함.
                if(isNullOrEmpty(config.bookmarkDiv)) {
                    config.bookmarkDiv = "NULL";
                }
            })
            apiCall.post("/keywordBid/bidKwd/changeConfigData", rowBidConfigs)
                .then(resp => {
                    successAlert(resp.data.replaceAll("<br/>", "\n"));
                    setGridRefresh(true);
                    setOpen(false);
                })
                .catch(error => {
                    errorAlert(error.response.data.message)
                })
        }
    }

    useEffect(() => {
        pageInit();
    }, [])

    useEffect(() => {
    }, [rowBidConfigs])

    function moveBidScheduleStatePage() {
        modal.confirm({
            icon: <ExclamationCircleFilled rev={undefined} />,
            title: '스케줄 등록 화면 이동',
            content: '설정 변경을 그만두고 스케줄 등록 화면으로 이동하시겠습니까?',
            okText: '확인', cancelText: '취소',
            okButtonProps: { className: "pink ant-btn-lg" },
            cancelButtonProps: { type: 'primary', className: "gray ant-btn-lg" },
            onOk () {
                let moveBidScheduleStatePage = "/kwdBid/bidScheduleState";
                navigate(moveBidScheduleStatePage, { state: { advList : [], selectedRow : undefined }});
            }
        })
    }

    const openScheduleStatModal = (row:RowBidConfigProps) => {
        row.scheduleId === null || row.scheduleId === 0 ?
            errorAlert("확인할 스케줄을 선택해주세요.") : setSelectedScheduleId(row.scheduleId);
    }

    useEffect(() => {
        if(selectedScheduleId !== 0) setScheduleCheckModalYn(true);
    }, [selectedScheduleId]);
    return (
        <>
            <Modal
                title={[
                    <Space key={"bidConfigModalTitleSpace"}>
                        <Typography.Title level={3} className="fz-24 fw-bold fc-gray-500">입찰설정 변경</Typography.Title>
                        <Typography.Text className="fz-14 fc-gray-300">* 선택한 키워드의 입찰 설정을 변경합니다.</Typography.Text>
                    </Space>
                ]}
                open={isOpen}
                //onOk={handleOk}
                onCancel={() => {setOpen(false); }}
                afterClose={() => {setOpen(false); }}
                width={1300}
                footer={[
                    <Button key="footerCancelBtn" type="primary" className="gray" size="large" onClick={() => { setOpen(false); }}>취소</Button>,
                    <Button key="footerSaveBtn" type="primary" className="pink" size="large" onClick={bidConfigEvent}>저장</Button>,
                ]}
            >
                {rowBidConfigs.map((row, index)=> {
                    return (
                        <React.Fragment key={row.bidKwdId}>
                            <section className="wrap-section wrap-tbl">
                                <div className="box-body">
                                    <div className="tbl">
                                        <dl>
                                            <dt>
                                               <div className="dt-inner">
                                                   <Tag className="ant-tag-pink">키워드 {index+1}</Tag>
                                               </div>
                                            </dt>
                                           <dd>
                                               <div className="form-group">
                                                   <Typography.Text className="fz-15 fw-exbold fc-gray-500">{row.kwdName}</Typography.Text>
                                               </div>
                                               <div className="form-group">
                                                   {nameTreeFormatter([row.advName, row.campName, row.groupName])}
                                               </div>
                                           </dd>
                                       </dl>
                                       <dl>
                                           <dd>
                                               <div className="form-group">
                                                   <Checkbox.Group name={"bookmarkChecked"} >
                                                       {rowBidConfigs.length > 1 && index === 0 ? <Checkbox onChange={onChangeCheckbox}><span className="fz-15 fc-gray-500">북마크</span></Checkbox> :
                                                           <>
                                                           <span className="comp-txt">
                                                               <span className="table">
                                                                   <span className="table-cell">
                                                                       <b className="fz-15 fc-gray-500">북마크</b>
                                                                   </span>
                                                               </span>
                                                           </span>
                                                           </>    
                                                       }
                                                       <Select
                                                           style={{ width: 100 }}
                                                           getPopupContainer = {triggerNode => triggerNode.parentElement}
                                                           value={row.bookmarkDiv === null ? "NULL" : row.bookmarkDiv}
                                                           onChange={(bookmarkDiv)=>onChangeBidConfigSelectBox(bookmarkDiv, row.bidKwdId, "bookmarkDiv")}
                                                           options={bookmarkList(false)}
                                                           dropdownRender={menu => (
                                                               <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                                                                   {menu}
                                                               </div>
                                                           )}
                                                       />
                                                   </Checkbox.Group>
                                                   <Checkbox.Group name={"bidConfigYnChecked"}>
                                                       {rowBidConfigs.length > 1 && index === 0 ? <Checkbox onChange={onChangeCheckbox}><span className="fz-15 fc-gray-500" >입찰 사용</span></Checkbox> :
                                                           <>
                                                           <span className="comp-txt">
                                                               <span className="table">
                                                                   <span className="table-cell">
                                                                       <b className="fz-15 fc-gray-500">입찰 사용</b>
                                                                   </span>
                                                               </span>
                                                           </span>
                                                           </>
                                                       }
                                                       <Select
                                                           style={{ width: 150 }}
                                                           value={row.bidConfigYn}
                                                           defaultValue={row.bidConfigYn}
                                                           onChange={(bidConfigYn)=>onChangeBidConfigSelectBox(bidConfigYn, row.bidKwdId, "bidConfigYn")}
                                                           options={onOffList(false)}
                                                       />
                                                   </Checkbox.Group>
                                                   <Checkbox.Group name={"bidItemDivChecked"}>
                                                       {rowBidConfigs.length > 1 && index === 0 ? <Checkbox onChange={onChangeCheckbox}><span className="fz-15 fc-gray-500">상품</span></Checkbox> :
                                                           <>
                                                           <span className="comp-txt">
                                                               <span className="table">
                                                                   <span className="table-cell">
                                                                       <b className="fz-15 fc-gray-500">상품</b>
                                                                   </span>
                                                               </span>
                                                           </span>
                                                           </>
                                                       }
                                                       <Select
                                                           style={{ width: 150 }}
                                                           value={row.bidItemDiv}
                                                           defaultValue={row.bidItemDiv === undefined ? "TARGET" : row.bidItemDiv}
                                                           onChange={(bidItemDiv)=>onChangeBidConfigSelectBox(bidItemDiv, row.bidKwdId, "bidItemDiv")}
                                                           options={bidItemDivList(false)}
                                                       />
                                                   </Checkbox.Group>
                                                   <Checkbox.Group name={"adAreaDivChecked"}>
                                                       { rowBidConfigs.length > 1 && index === 0 ? <Checkbox onChange={onChangeCheckbox}><span className="fz-15 fc-gray-500">광고 영역</span></Checkbox> :
                                                           <>
                                                           <span className="comp-txt">
                                                               <span className="table">
                                                                   <span className="table-cell">
                                                                       <b className="fz-15 fc-gray-500">광고 영역</b>
                                                                   </span>
                                                               </span>
                                                           </span>
                                                           </>
                                                       }
                                                       <Select
                                                           style={{ width: 150 }}
                                                           value={row.adAreaDiv}
                                                           defaultValue={row.adAreaDiv}
                                                           onChange={(adAreaDiv)=>onChangeBidConfigSelectBox(adAreaDiv, row.bidKwdId, "adAreaDiv")}
                                                           options={adAreaDivList(false)}
                                                       />
                                                   </Checkbox.Group>
                                               </div>
                                               <div className="form-group">
                                                   <Checkbox.Group name={"hopeRankChecked"}>
                                                       { rowBidConfigs.length > 1 && index === 0 ?
                                                           <Checkbox onChange={onChangeCheckbox}><span className="fz-15 fc-gray-500">목표 순위</span></Checkbox> :
                                                           <>
                                                           <span className="comp-txt">
                                                               <span className="table">
                                                                   <span className="table-cell">
                                                                       <b className="fz-15 fc-gray-500">목표 순위</b>
                                                                   </span>
                                                               </span>
                                                           </span>
                                                           </>
                                                       }
                                                       <Select
                                                           style={{ width: 150 }}
                                                           value={row.hopeRank}
                                                           defaultValue={row.hopeRank}
                                                           onChange={(hopeRank)=>onChangeBidConfigSelectBox(hopeRank, row.bidKwdId, "hopeRank")}
                                                           options={bidRankList(false, row.adAreaDiv)}
                                                       />
                                                       <span className="comp-txt">
                                                            <span className="table">
                                                                <span className="table-cell">
                                                                    <b className="fz-14 fc-gray-400">&nbsp;/&nbsp;</b>
                                                                    <b className="fz-14 fc-gray-400">{row.nowRank === 0 ? "현재 순위 없음" : `현재 ${row.nowRank}위`}</b>
                                                                </span>
                                                            </span>
                                                        </span>
                                                   </Checkbox.Group>
                                                   <Checkbox.Group name={"maxBidCostChecked"}>
                                                       { rowBidConfigs.length > 1 && index === 0 ? <Checkbox onChange={onChangeCheckbox}><span className="fz-15 fc-gray-500">최대 입찰가</span></Checkbox> :
                                                           <>
                                                           <span className="comp-txt">
                                                               <span className="table">
                                                                   <span className="table-cell">
                                                                       <b className="fz-15 fc-gray-500">최대 입찰가</b>
                                                                   </span>
                                                               </span>
                                                           </span>
                                                           </>
                                                       }
                                                       <Input className="w-150" suffix="원" name="maxBidCost" value={row.maxBidCost} onChange={(e)=> onChangeBidConfigInputValue(e, row.bidKwdId)} />
                                                       <span className="comp-txt">
                                                            <span className="table">
                                                                <span className="table-cell">
                                                                    <b className="fz-14 fc-gray-400">&nbsp;/&nbsp;</b>
                                                                    <b className="fz-14 fc-gray-400">현재 {row.nowBidCost}원</b>
                                                                </span>
                                                            </span>
                                                        </span>
                                                   </Checkbox.Group>
                                                   <Checkbox.Group name={"minBidCostChecked"}>
                                                       { rowBidConfigs.length > 1 && index === 0 ? <Checkbox onChange={onChangeCheckbox}><span className="fz-15 fc-gray-500">최소 입찰가</span></Checkbox> :
                                                           <>
                                                           <span className="comp-txt">
                                                               <span className="table">
                                                                   <span className="table-cell">
                                                                       <b className="fz-15 fc-gray-500">최소 입찰가</b>
                                                                   </span>
                                                               </span>
                                                           </span>
                                                           </>
                                                       }
                                                       <Input className="w-150" suffix="원" name="minBidCost" value={row.minBidCost} onChange={(e)=> onChangeBidConfigInputValue(e, row.bidKwdId)} />
                                                   </Checkbox.Group>
                                               </div>
                                               <div className="form-group">
                                                   <Checkbox.Group>
                                                       <span className="comp-txt">
                                                           <span className="table">
                                                               <span className="table-cell">
                                                                   <b className="fz-15 fc-gray-500">스케줄</b>
                                                               </span>
                                                           </span>
                                                       </span>
                                                       <Select
                                                           style={{ width: 400 }}
                                                           value={row.scheduleId === null ? 0 : row.scheduleId}
                                                           onChange={(scheduleId)=>onChangeBidConfigSelectBox(scheduleId, row.bidKwdId, "scheduleId")}
                                                           options={bidScheduleList(row.scheduleList, true, '사용안함')}
                                                           dropdownRender={menu => (
                                                               <div style={{ maxHeight: row.scheduleId === null ? 400 : 200, overflowY: 'auto' }}>
                                                                   {menu}
                                                               </div>
                                                           )}
                                                       />
                                                       <Button className="pink" icon={<i className="ico ico-add"></i>} onClick={()=> moveBidScheduleStatePage()}>
                                                           신규 스케줄 등록
                                                       </Button>
                                                       <Button className="pink" onClick={()=> openScheduleStatModal(row)}>
                                                           스케줄 확인
                                                       </Button>
                                                   </Checkbox.Group>
                                               </div>
                                           </dd>
                                       </dl>
                                   </div>
                               </div>
                               { rowBidConfigs.length > 1 && index === 0 &&
                               <div className="box-footer">
                                   <div className="box-center">
                                       <Button type="primary" className="pink" onClick={copyFirstBidConfigEvent}>선택 조건 이하 동일내용 적용</Button>
                                   </div>
                               </div>
                               }
                           </section>
                       </React.Fragment>
                   )
                })}
            </Modal>

            {scheduleCheckModalYn &&
                <ScheduleCheckModal bidScheduleId={selectedScheduleId} isOpen={scheduleCheckModalYn} setOpen={setScheduleCheckModalYn} />
            }
        </>
    )
};

export default BidConfigModal;