
import React from "react";
import {Skeleton, Space, Table, Typography} from "antd";
import { JSX } from "react/jsx-runtime";


/************************************ 테이블 기능 모음 : Start /

 /* 데이터 비어있을 경우 나오는 레이아웃(프로젝트마다 상이할 수 있음) */
export const emptyTableLayout = (msgs: string[]) => {
    return {
        emptyText: (
            <div className="ant-empty-normal">
                <i className="ant-empty-image"></i>
                {msgs.map((msg, index) => {
                    return index === 0 ?
                        <p className="ant-empty-description" key={"emptyDesc_" + index}>{msg}</p> :
                        <p className="ant-empty-description small" key={"emptyDescSmall_" + index}>{msg}</p>
                })}
            </div>
        )
    }
}
/* 데이터 비어있을 경우 나오는 레이아웃 - 로딩 항목 추가(프로젝트마다 상이할 수 있음) */
export const loadingEmptyTableLayout = (gridLoading: boolean, msgs: string[]) => {
    const makeSkeletonRows = () => {
        let rows: JSX.Element[] = [];
        for(let i = 0; i< 10; i++) {
            rows.push(<><Skeleton.Input active block={true} size={"small"} /></>)
        }

        return rows;
    }
    const skeletonRows = makeSkeletonRows();
    return gridLoading ? {
        emptyText : (
            <>
                {skeletonRows.map((row, index) => {
                    return (
                        <div key={"skeletonDiv_"+index}>
                            {row} <br /> <br />
                        </div>
                    )
                })}
            </>
        )
        } : {
        emptyText: (
            <div className="ant-empty-normal">
                <i className="ant-empty-image"></i>
                {msgs.map((msg, index) => {
                    return index === 0 ?
                        <p className="ant-empty-description" key={"emptyDesc_" + index}>{msg}</p> :
                        <p className="ant-empty-description small" key={"emptyDescSmall_" + index}>{msg}</p>
                })}
            </div>
        )
    }
}

/** 합계 로우 등 푸터 로우 리턴.
 * 숫자일 경우 데이터가 -일 때 빨갛게 나옴.
 * 그리드 노출 순서에 맞게 데이터 전달해줘야 함 */
export const drawFooterRow = (footerTitle: string, dataList: any[]) => {
    // 키는 그냥 string 형태로 대충 지어서 주면 됨
    return (
        <>
            <Table.Summary.Row>
                <Table.Summary.Cell key={0} index={0} align="center">{footerTitle}</Table.Summary.Cell>
                {dataList.map((data, index) => {
                    const numberType = (typeof data === "number");
                    if (!numberType) { // 숫자형태가 아닐 경우 그냥 찍어줌
                        return (
                            <Table.Summary.Cell key={index + 1} index={index + 1} align="center">
                                <Typography.Text>{data}</Typography.Text>
                            </Table.Summary.Cell>
                        )
                    } else { // 숫자 형태일 경우 - 값이라면 red 클래스 추가. 숫자는 , 형태로 나타나도록
                        return (
                            <Table.Summary.Cell key={index + 1} index={index + 1} align="center">
                                {data < 0 ? <Typography.Text className="c-red">{prettyNumber(data)}</Typography.Text> :
                                <Typography.Text>{prettyNumber(data)}</Typography.Text>}
                            </Table.Summary.Cell>
                        )
                    }
                })}
            </Table.Summary.Row>
        </>
    )
}

/* 기본 페이지네이션 옵션 */
export const paginationOptions = (rows:any[], option?:any) => {
    return {
        total: rows.length,
        showTotal : (total: number) => `Total ${total} items`,
        showSizeChanger: true, // true로 설정 안하면 50부터 나타남.
    }
}

/************************************ 테이블 기능 모음 : End */

/************************************ 테이블 포맷 모음 : Start */

 /* 그리드 내부 숫자 이쁘게 찍기 */
export const prettyNumber = (numberValue: string | number | undefined) => {
    if(numberValue === undefined) return "";
    return typeof numberValue === "string" ? numberValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : Math.floor(numberValue).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
};

/* 그리드 내부 숫자 이쁘게 하기 + 오른쪽에 텍스트 붙여주기 */
export const prettyNumberText = (numberValue: string | number, text: string) => {
    const nf = prettyNumber(numberValue);
    return nf +" "+text;
}

/* 리포트 데이터 등에서 사용할만한 날짜 포맷 변환 기능. 날짜 라이브러리 쓰고 싶지 않으면 이걸로... */
export const dateFormat = (date:string, dot?: string) => {
    if(date.length !== 8) return date; // 8글자 아니면 걍 리턴
    return date.replace(/(\d{4})(\d{2})(\d{2})/g, dot === undefined ? '$1-$2-$3' : `$1${dot}$2${dot}$3`);
}

/** 작업 상태 포맷 변경(없는 거 추가하세요) */
export const statusFormat = (status: string) => {
    interface statusType { [index: string]: string, REQ: string, ING: string, COMPLETE: string, ERROR: string};
    const statusMap:statusType = { REQ : "요청", ING: "진행 중", COMPLETE: "완료", ERROR: "에러" };
    return statusMap[status];
}

/************************************ 테이블 포맷 모음 : End */