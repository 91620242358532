import {Button} from "antd";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import DOMPurify from 'dompurify';

const ErrorPage = () => {
    const location = useLocation();

    const { state } = location;
    const [ type, setType ] = useState<string>("");
    const [ description, setDescription ] = useState<string[]>([]);
    const [ isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        if(state !== null){ // 특별한 문구가 들어갈 경우 state를 통해 값 설정
            
        } else { // 일단 모든 api 호출 시 에러 날 경우 여기로 오게끔 ...
            setType("Server Error");
            setDescription([
                "죄송합니다.<br/>현재 서버에서 응답이 없습니다.", //main
                "서버가 정기 점검 등의 이유로 인하여, <br/>응답을 할 수 없는 상태입니다. " + // sub
                "<br/><br/>해당 현상이 지속될 경우 관리자에게 문의 바랍니다."])
        }
        setLoading(false);
    }, [])
    return (
        <>
            {!isLoading &&
                <div className="wrap error">
                    <div className="box-inner">
                        <div className="box-left">
                            <div className="box-top">
                                <div className="fz-16 fw-bold fc-pink-400"
                                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(type)}}
                                />
                            </div>
                            <div className="box-middle">
                                <p className="fz-24 fw-bold fc-gray-600"
                                   dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(description[0])}}
                                />
                                <p className="fz-15 fw-bold fc-gray-300"
                                   dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(description[1])}}
                                />
                            </div>
                            <div className="box-bottom">
                                <Button type="primary" className="pink" onClick={()=> {
                                    window.location.href="/"
                                }}>홈 화면으로 이동</Button>
                            </div>
                        </div>
                        <div className="box-right">
                            <img src={require('../../images/img-error-object.png')} alt={"에러 페이지 이미지"} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

export default ErrorPage;