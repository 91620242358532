import ContentHeader from "../../components/layout/contentHeader";
import {Button, Input, Select, Table, Typography, Upload} from 'antd';
import React, {useEffect, useState} from "react";
import {KeywordBidTaskGridProps, KeywordBidTaskReqProps} from "./kwdBidTypes";
import {errorAlert, successAlert} from "../../functions/alertFn";
import {UploadOutlined} from "@ant-design/icons";
import apiCall from "../../functions/apiCall";
import {Link} from "react-router-dom";
import {AxiosResponse} from "axios";
import SkeletonTable, {SkeletonTableColumnsType} from "../../components/table/skeletonTable";
import {ColumnsType} from "antd/es/table";
import {emptyTableLayout, paginationOptions} from "../../functions/tableFormatter";

const { Option } = Select;

const KeywordBidBulkManage = () => {
    /** 작업 요청 시 광고주 목록 */
    const [ advList, setAdvList ] = useState<{advId: number, advName: string}[]>([]);

    /** 작업 요청 파라미터 */
    const initTaskReqParmas = { advId: 0, taskDiv: "KEYWORD_BID_REG", taskName: '', taskReqFile: undefined}
    const [ taskReqParams, setTaskReqParams] = useState<KeywordBidTaskReqProps>(initTaskReqParmas);

    /** 작업 내역(전체 광고주 기준) */
    const [ taskRows, setTaskRows] = useState<KeywordBidTaskGridProps[]>([]);

    const [ gridRefresh, setGridRefresh ] = useState<boolean>(false);
    const [ gridLoading, setGridLoading ] = useState<boolean>(true);

    const gridColumns: ColumnsType<KeywordBidTaskGridProps> = [
        {title: 'No', dataIndex: 'index', align: 'center', render: (_, record, index) => index+1},
        {title: '광고주', dataIndex: 'advName', align: 'center'},
        {title: '작업명', dataIndex: 'taskName', align: 'center'},
        {title: '작업 상태', dataIndex: 'taskStatus', align: 'center'},
        {title: '작업 결과 (성공/전체)', dataIndex: 'taskResult',align: 'center'},
        {title: '요청 시간', dataIndex: 'taskReqTime', align: 'center'},
        {title: '등록 파일', dataIndex: 'taskReqFile', align: 'center', render: (_, record) => {
                return (<Button className="pink" icon={<i className="ico ico-download"></i>} size="small"
                                onClick={()=> taskFileDownloadEvent(record.taskReqFile)}
                                disabled={record.taskReqFile === undefined}/>
                )
            }
        },
        {title: '결과 파일', dataIndex: 'taskResultFile', align: 'center', render: (_, record) => {
                return (<Button className="pink" icon={<i className="ico ico-download"></i>} size="small"
                                onClick={()=> taskFileDownloadEvent(record.taskResultFile)}
                                disabled={record.taskResultFile === undefined}/>
                )
            }
        },
    ];

    /** 작업 진행할 수 있는 광고주 리스트 로드 **/
    const loadAdvs = () => {
        apiCall.get("/keywordBid/bidKwdReg/loadAdvList")
            .then(resp => setAdvList(resp.data));
    }

    /** 작업 진행 내역 로드 **/
    const loadTaskList = () => {
        setGridLoading(true)
        apiCall.get("/keywordBid/bidKwdReg/taskReqList")
            .then(resp => setTaskRows(resp.data))
            .finally(() => setGridLoading(false));
    }

    /** 작업 요청 이벤트 */
    const taskReqEvent = () => {
        //Validation
        if(taskReqParams.advId === 0 || taskReqParams.taskName.trim() === '' || taskReqParams.taskReqFile === undefined) {
            errorAlert("필수 입력 정보를 입력해주세요.");
            return false;
        } else {
            const formData = new FormData();
            formData.append('advId', taskReqParams.advId.toString());
            formData.append('taskName', taskReqParams.taskName);
            formData.append('taskReqFile', taskReqParams.taskReqFile);

            apiCall.post("/keywordBid/bidKwdReg/uploadFile", formData, {
                headers: {'Content-Type': 'multipart/form-data', charset: 'utf-8'}
            })
                .then(() => successAlert("작업 요청이 완료되었습니다.<br />잠시만 기다려주시면 화면 하단에 작업 결과가 나타납니다"))
                .catch(error => errorAlert(error.response.data.message))
                .finally(() => setGridRefresh(true))
        }
    }

    /** 작업 내역 => 등록파일 및 결과 파일 다운로드 */
    const taskFileDownloadEvent = (filePath: string) => {
        apiCall.post("/keywordBid/bidKwdReg/downloadFile", filePath, {
            responseType: 'blob'
        })
            .then(resp => {
                // 다운로드 파일 이름을 추출하는 함수
                const extractDownloadFilename = (resp: AxiosResponse<any, any>) => {
                    const disposition = resp.headers["content-disposition"];
                    return decodeURI(
                        disposition
                            .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
                            .replace(/['"]/g, "")
                    );
                };
                // 다운로드(서버에서 전달 받은 데이터) 받은 바이너리 데이터를 blob으로 변환합니다.
                const blob = new Blob([resp.data]);

                // blob을 사용해 객체 URL을 생성합니다.
                const fileObjectUrl = window.URL.createObjectURL(blob);

                // blob 객체 URL을 설정할 링크를 만듭니다.
                const link = document.createElement("a");
                link.href = fileObjectUrl;
                link.style.display = "none";

                // 다운로드 파일 이름을 지정 할 수 있습니다.
                // 일반적으로 서버에서 전달해준 파일 이름은 응답 Header의 Content-Disposition에 설정됩니다.
                link.download = extractDownloadFilename(resp);

                // 링크를 body에 추가하고 강제로 click 이벤트를 발생시켜 파일 다운로드를 실행시킵니다.
                document.body.appendChild(link);
                link.click();
                link.remove();

                // 다운로드가 끝난 리소스(객체 URL)를 해제합니다.
                window.URL.revokeObjectURL(fileObjectUrl);
            })
            .catch(error => {
                error("결과 파일 다운로드 도중 에러가 발생했습니다.<br />관리자에게 문의해주세요.", {error});
            });
    }

    /** 페이지 진입 시 실행*/
    useEffect(() => {
        loadAdvs();
        loadTaskList();
        document.title = "키워드 입찰 등록";
    }, []);

    useEffect(() => {
        if (gridRefresh) {
            loadAdvs();
            loadTaskList();
            setGridRefresh(false)
        }
    }, [gridRefresh]);

    return (
        <>
            <ContentHeader pageTitle={"키워드 입찰 등록"} navigation={["키워드 입찰", "키워드 입찰 등록"]} />

            {/* <!-- Wrap-Tbl : Start --> */}
            <section className="wrap-section wrap-tbl">
                <div className="box-header">
                    <div className="box-left">
                        <Typography.Title level={3} className="fc-gray-700">입찰 작업 요청</Typography.Title>
                    </div>
                    <div className="box-right">
                        <Link to="/templateFiles/키워드추가템플릿.csv" target={"_blank"} download>
                            <Button className="pink"><i className="ico ico-download"></i>등록/수정 템플릿 다운로드</Button>
                        </Link>
                    </div>
                </div>
                <div className="box-body">
                    <div className="tbl">
                        <dl>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-15 fc-gray-500">광고주</span>
                                    <i className="txt-essential"></i>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <Select
                                        placeholder="광고주를 선택하세요." style={{ width: 500 }}
                                        onChange={(advId: number)=> { setTaskReqParams({...taskReqParams, "advId": advId}) }}
                                        dropdownStyle={{ maxHeight: advList.length === 0 ? 500 : 200, overflowY: 'auto' }}
                                    >
                                        {advList.map((data) => {
                                                return <Option key={data.advId} value={data.advId} label={data.advName}>{data.advName}</Option>
                                            }
                                        )}
                                    </Select>
                                </div>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-15 fc-gray-500">작업명</span>
                                    <i className="txt-essential"></i>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <Input className="w-500" placeholder="요청할 작업명을 입력하세요." value={taskReqParams.taskName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>)=>
                                            setTaskReqParams({...taskReqParams, "taskName" : e.target.value})
                                        }
                                    />
                                </div>
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-15 fc-gray-500">파일 업로드</span>
                                    <i className="txt-essential"></i>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <Upload
                                        beforeUpload={(file) => {
                                            const isCsv = file.type === "text/csv";
                                            if(!isCsv){
                                                errorAlert("파일은 csv 파일만 업로드 가능합니다.");
                                                return Upload.LIST_IGNORE;
                                            } else {
                                                setTaskReqParams({...taskReqParams, taskReqFile: file});
                                                return false;
                                            }
                                        }}
                                        onRemove={() => setTaskReqParams({...taskReqParams, taskReqFile: undefined})}
                                        maxCount={1}
                                    >
                                        <Button className="pink" icon={<UploadOutlined />}>업로드 파일 선택</Button>
                                    </Upload>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className="box-footer">
                    <div className="box-center">
                        <Button type="primary" className="pink" size="large" onClick={taskReqEvent}>작업 요청</Button>
                    </div>
                </div>
            </section>
            {/* <!-- Wrap-Tbl : End --> */}

            {/* <!-- Wrap-Datagrid : Start --> */}
            <section className="wrap-section wrap-datagrid">
                <div className="box-header">
                    <div className="box-left">
                        <Typography.Title level={3} className="fc-gray-700">작업 이력</Typography.Title>
                    </div>
                </div>
                <div className="box-body">
                    <SkeletonTable loading={gridLoading} columns={gridColumns as SkeletonTableColumnsType[]} >
                        <Table
                            key={"keywordBidGrid"}
                            rowKey={"taskId"}
                            columns={gridColumns}
                            dataSource={taskRows}
                            locale={emptyTableLayout(["등록된 작업 이력이 없습니다."])}
                            bordered
                            scroll={{ x: 1600 }}
                            pagination={paginationOptions(taskRows)}
                        />
                    </SkeletonTable>
                </div>
            </section>
            {/* <!-- Wrap-Datagrid : End --> */}

        </>
    )
};

export default KeywordBidBulkManage;