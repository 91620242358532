import React, {Fragment, useEffect, useState} from "react";

import {Button, Card, Col, DatePicker, Flex, Form, Row, Select, Space, Table, Typography,} from "antd";
import SkeletonTable, {SkeletonTableColumnsType} from "../../components/table/skeletonTable";
import {ColumnsType} from "antd/es/table";
import dayjs from "dayjs";


// Import - DatePicker - Range
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import ContentHeader from "../../components/layout/contentHeader";
import {emptyTableLayout, paginationOptions} from "../../functions/tableFormatter";
import apiCall from "../../functions/apiCall";
import {errorAlert} from "../../functions/alertFn";
import KeywordListModal from "../../components/dataMng/modal/keywordListModal";
import CompetitorsRankDetailModal from "../../components/dataMng/modal/competitorsRankDetailModal";
import {competitorRankNotice} from "../../components/common/noticeText";
import {CompetitorRankHistorySearchProps} from "./competitorsRankHistoryType";

// DatePicker - Range
const { RangePicker } = DatePicker;
const { Title, Text } = Typography;

interface CompetitorsRankHistoryRowProps {
    key: React.Key,
    kwdName: string,
    device: string,
    startDate: string,
    endDate: string,
    domain: string,
    advRank: number,
    hourlyRank: { [key: string]: number | null }
}

const CompetitorsRankHistory = () => {

    /************* 조회 필터 데이터 ***************/
    // 광고주 리스트
    const [ advList, setAdvList] = useState<{nvLoginId: string, advName: string}[]>([{nvLoginId: "전체", advName: "전체"}]);
    // 키워드
    const [selectedKwds, setSelectedKwds] = useState<{ kwdName: string }[]>([]);

    // 광고 영역
    const [multiSearchOptions, setMultiSearchOptions] = useState<any[]>([
        { value: 'select-opt-all', label: '전체', className: 'select-opt-all' },
        { value: 'PC_TOTAL', label: 'PC' },
        { value: 'PC_DETAIL', label: 'PC 더보기' },
        { value: 'MOBILE_TOTAL', label: 'MO' },
        { value: 'MOBILE_DETAIL', label: 'MO 더보기' }
    ]);

    // 광고 영역 멀티 셀렉트
    const [multiSearchSelectedItems, setmultiSearchSelectedItems] = useState<string[]>( multiSearchOptions.map(option => option.value));
    
    // 멀티 셀렉트 이벤트
    const multiSearchHandleChange = (selected : any) => {
        // 전체 클릭 시,
        if (selected.includes('select-opt-all')) {
            if (multiSearchSelectedItems.filter(value => value === 'select-opt-all').length === 0) {
                setmultiSearchSelectedItems(multiSearchOptions.map((v:any)=>v.value));
                setSearchParams(prev => ({ ...prev, device: multiSearchOptions.map((v:any)=>v.value).filter(v => v !== 'select-opt-all')}));
                setMultiSearchOptions(multiSearchOptions.map((o : any) => {
                    if (o['value'] === 'select-opt-all') o['className'] = 'select-opt-all';
                    return o;
                }));
            } else {
                if (selected.filter((value : any) => value !== 'select-opt-all').length === 0) {
                    setmultiSearchSelectedItems([]);
                    setSearchParams(prev => ({ ...prev, device: []}));
                    setMultiSearchOptions(multiSearchOptions.map((o : any) => {
                        if (o['value'] === 'select-opt-all') o['className'] = 'select-opt-all';
                        return o;
                    }));
                } else {
                    setmultiSearchSelectedItems(selected);
                    setSearchParams(prev => ({ ...prev, device: selected}));
                    if (selected.length === multiSearchOptions.length) {
                        setMultiSearchOptions(multiSearchOptions.map((o : any) => {
                            if (o['value'] === 'select-opt-all') o['className'] = 'select-opt-all';
                            return o;
                        }));
                    } else {
                        setMultiSearchOptions(multiSearchOptions.map((o : any) => {
                            if (o['value'] === 'select-opt-all') o['className'] = 'select-opt-all ant-checkbox-indeterminate';
                            return o;
                        }));
                    }
                }
            }
        } else {
            if (multiSearchSelectedItems.filter(value => value === 'select-opt-all').length === 0){
                if (selected.length + 1 === multiSearchOptions.length) {
                    setmultiSearchSelectedItems(multiSearchOptions.map((v:any)=>v.value));
                    setSearchParams(prev => ({ ...prev, device: multiSearchOptions.map((v:any)=>v.value).filter(v => v !== 'select-opt-all')}));
                    setMultiSearchOptions(multiSearchOptions.map((o : any) => {
                        if (o['value'] === 'select-opt-all') o['className'] = 'select-opt-all';
                        return o;
                    }));
                } else {
                    if (selected.length === 0) {
                        setmultiSearchSelectedItems([]);
                        setSearchParams(prev => ({ ...prev, device: []}));
                        setMultiSearchOptions(multiSearchOptions.map((o : any) => {
                            if (o['value'] === 'select-opt-all') o['className'] = 'select-opt-all';
                            return o;
                        }));
                    } else {
                        setmultiSearchSelectedItems([...selected, 'select-opt-all']);
                        setSearchParams(prev => ({ ...prev, device: [...selected]}));
                        setMultiSearchOptions(multiSearchOptions.map((o : any) => {
                            if (o['value'] === 'select-opt-all') o['className'] = 'select-opt-all ant-checkbox-indeterminate';
                            return o;
                        }));
                    }
                }
                // setmultiSearchSelectedItems(selected.length + 1 == multiSearchOptions.length ? multiSearchOptions : selected);
            } else {
                setmultiSearchSelectedItems([]);
                setSearchParams(prev => ({ ...prev, device: []}));
                setMultiSearchOptions(multiSearchOptions.map((o : any) => {
                    if (o['value'] === 'select-opt-all') o['className'] = 'select-opt-all';
                    return o;
                }));
            }

        }
    };

    const tagRender = (props: any) => {
        const {label, value, closable, onClose} = props;
        if (value === 'select-opt-all') {
            return (<Fragment/>);
        }
        return (
            <>
                    <span className="ant-select-selection-item" title={label}>
                        <span className="ant-select-selection-item-content">{label}</span>
                        <span
                            className="ant-select-selection-item-remove" unselectable="on" aria-hidden="true"
                            style={{userSelect: 'none'}}>
                            {closable && <span role="img" aria-label="close" className="anticon anticon-close" onClick={onClose}>

                            </span> }
                        </span>
                    </span>
            </>
        );
    };

    //기간
    const defaultEndDate = dayjs();
    const defaultStartDate = defaultEndDate.add(-30, 'd');

    /*************** 조회 파라미터 **************/
    const [ searchParams, setSearchParams] = useState<CompetitorRankHistorySearchProps>(
        {advName: '', kwdNames: '', device: [], startDate : defaultStartDate.format('YYYYMMDD'), endDate: defaultEndDate.format('YYYYMMDD')});


    /************** 그리드 **************/
    const [ gridLoading, setGridLoading ] = useState<boolean>(true);
    const [rows, setRows] = useState<CompetitorsRankHistoryRowProps[]>([]);

    const gridColumns: ColumnsType<CompetitorsRankHistoryRowProps> = [
        {title: '키워드', dataIndex: 'kwdName', align: 'center'},
        {title: '광고영역', dataIndex: 'device', align: 'center', render: (device: string) => {
                return multiSearchOptions.find((type) => type.value === device)?.label || device;
            },},
        {title: '기간', dataIndex: 'date', align: 'center', render: (_, record) => {
                return `${dayjs(record.startDate).format('YYYY-MM-DD')} ~ ${dayjs(record.endDate).format('YYYY-MM-DD')}`;
            }},
        {title: '도메인', dataIndex: 'domain', align: 'center'},
        {title: '평균', dataIndex: 'avgRank', align: 'center'},
    ];

    // 시간별 순위 push
    if (rows.length > 0) {
        Object.keys(rows[0].hourlyRank)
            .sort((a, b) => parseInt(a) - parseInt(b))
            .forEach((key) => {
                gridColumns.push({
                    title: key, // 시간 키를 컬럼 타이틀로 사용
                    dataIndex: ['hourlyRank', key], 
                    align: 'center',
                    render: (value: number | 0.0) => (value !== 0.0 ? value : '-'), // 0.0 값을 '-'로 표시
                });
            });
    }


    /** 모달 플래스 */
    // 키워드 선택 모달
    const [ keywordListModalYn, setKeywordListModalYn ]  = useState<boolean>(false);
    // 경쟁사 순위 상세조회 모달
    const [ competitorsRankDetailModalYn, setCompetitorsRankDetailModalYn ]  = useState<boolean>(false);

    // 검색 파라미터
    let params = {
        advName: searchParams.advName === '전체' ? undefined : searchParams.advName,
        kwdNames: selectedKwds.length <= 0 ? '' : selectedKwds.map(row => row.kwdName).join(','),
        device: Array.isArray(searchParams.device) && searchParams.device.length === 0 ? '' : searchParams.device?.join(','),
        startDate: searchParams.startDate || undefined,
        endDate: searchParams.endDate || undefined
    };

    const searchValidEvent = () => {
        if(params.advName === '' || params.advName === undefined) {
            setGridLoading(false);
            return errorAlert('광고주를 선택하세요.');
        }

        if(params.kwdNames === '' || params.kwdNames === undefined) {
            setGridLoading(false);
            return errorAlert('키워드를 선택하세요.');
        }

        // 시작일과 종료일은 2개월 이내여야 함
        if (params.startDate && params.endDate) {
            const startDate = dayjs(params.startDate, 'YYYYMMDD');
            const endDate = dayjs(params.endDate, 'YYYYMMDD');
            const diffMonths = endDate.diff(startDate, 'month'); // 두 날짜 간의 개월 차이 계산
            if (diffMonths > 2) {
                setGridLoading(false);
                return errorAlert('기간을 최대 2개월 사이로 선택해주세요.');
            }
        }

        return true;
    }

    const searchEvent = () => {
        setGridLoading(true);
        if(searchValidEvent()) {
            apiCall.get("/data/competitors/rank", {params})
                .then(resp => {

                    // 1. index 값을 key로 추가 & 내림차순
                    const updatedRows = resp.data
                        .map((row: CompetitorsRankHistoryRowProps, index: number) => ({
                            ...row, key: index,
                        }));

                    // 2. set!!!
                    setRows(updatedRows);
                    setGridLoading(false);
                })
                .catch(error => {
                    return errorAlert("경쟁사 순위 데이터가 정상적으로 로드 되지 않습니다.<br />관리자에게 문의바랍니다.", {error});
                }).finally(() => {
                setGridLoading(false);
            });
        }
    }

    /**************** 이벤트 함수 ***************/
    // 키워드 리스트 모달 Open 이벤트
    const openKeywordListModal = () => {
        if(searchParams.advName === undefined || searchParams.advName === '') {
            errorAlert("광고주를 선택하세요.");
            return setKeywordListModalYn(false);
        }
        setKeywordListModalYn(true);
    }

    // 경쟁사 순위 조회 이벤트


    // 광고주 리스트 get~
    const findAdvsList = () => {
        apiCall.get("/data/competitors/loadAdvList")
            .then(resp => {
                setAdvList(resp.data);
            })
            .catch(error => {
                console.log(error);
                setAdvList([]);
            })
    }


    const downloadEvent = () => {
        apiCall.post("/data/competitors/rank/download", params, {responseType: 'blob'})
            .then(resp => {

                // 다운로드 파일 이름을 추출하는 함수
                const extractDownloadFilename = (resp: any) => {
                    const disposition = resp.headers["content-disposition"];
                    return decodeURI(
                        disposition
                            .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
                            .replace(/['"]/g, "")
                    );
                };

                // // 다운로드(서버에서 전달 받은 데이터) 받은 바이너리 데이터를 blob으로 변환합니다.
                const blob = new Blob([resp.data]);
                // // 특정 타입을 정의해야 경우에는 옵션을 사용해 MIME 유형을 정의 할 수 있습니다.
                // // const blob = new Blob([this.content], {type: 'text/plain'})

                // // blob을 사용해 객체 URL을 생성합니다.
                const fileObjectUrl = window.URL.createObjectURL(blob);

                // // blob 객체 URL을 설정할 링크를 만듭니다.
                const link = document.createElement("a");
                link.href = fileObjectUrl;
                link.style.display = "none";
                //
                // // 다운로드 파일 이름을 지정 할 수 있습니다.
                // // 일반적으로 서버에서 전달해준 파일 이름은 응답 Header의 Content-Disposition에 설정됩니다.
                link.download = extractDownloadFilename(resp);

                // // 다운로드 파일의 이름은 직접 지정 할 수 있습니다.
                // link.download = "sample-file.xlsx";

                // // 링크를 body에 추가하고 강제로 click 이벤트를 발생시켜 파일 다운로드를 실행시킵니다.
                document.body.appendChild(link);
                link.click();
                link.remove();

                // // 다운로드가 끝난 리소스(객체 URL)를 해제합니다.
                window.URL.revokeObjectURL(fileObjectUrl);
            })
            .catch(error => {
                console.log(error)
                errorAlert("다운로드가 정상적으로 되지 않습니다.<br />관리자에게 문의바랍니다.", {error});
            }).finally(() => {
            setGridLoading(false);
        });
    }

    //최초 페이지 진입
    useEffect(() => {
        document.title = "경쟁사 순위 조회"; //페이지 제목
        setGridLoading(false);
        findAdvsList();
    }, []);


    return (
        <>
            <ContentHeader pageTitle={"경쟁사 순위 조회"} navigation={["데이터 관리", "경쟁사 순위 조회"]}/>

            {/* <!-- Wrap-Filter : Start --> */}
            <section className="wrap-section wrap-filter">

                <div className="box-body">
                    <Card>
                        <Flex gap={24}>
                            <div className="box-left">
                                <i className="ico ico-filter"></i>
                            </div>
                            <div className="box-center">
                                <Form>
                                    <Row gutter={[16, 16]} wrap>
                                        <Col span={8}>
                                             <Form.Item
                                                label="광고주"
                                                colon={false}
                                                rules={[{ required: true, message: '광고주를 선택하세요.' }]}
                                            >
                                                <Space>
                                                    <Select
                                                        placeholder="광고주를 선택하세요."
                                                        style={{ width: 250 }}
                                                        onChange={(nvLoginId:string)=> {setSearchParams({...searchParams, advName: nvLoginId})}}
                                                    >
                                                        {advList.map((adv: {nvLoginId: string, advName: string}) => {
                                                            return <Select.Option key={`${adv.advName}_CONTENT_SEARCH`} value={adv.nvLoginId}>{adv.advName}</Select.Option>
                                                        })}
                                                    </Select>
                                                </Space>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="키워드" colon={false} rules={[{required: true, message: '키워드를 선택하세요.'}]}>
                                                <Space>
                                                    {/* <!-- Comp-Txt : Start --> */}
                                                    <span className="comp-txt">
                                                    <span className="table">
                                                        <span className="table-cell">
                                                            <b className="fz-14 fc-gray-400">{selectedKwds.length > 0 ? selectedKwds.map(row => row.kwdName).join(', ') : ''}</b>
                                                        </span>
                                                    </span>
                                                </span>
                                                    {/* <!-- Comp-Txt : End --> */}
                                                    <Button className="pink" onClick={openKeywordListModal}>선택</Button>
                                                </Space>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                label="광고 영역"
                                                colon={false}
                                                rules={[{ required: true, message: '광고 영역을 선택하세요.' }]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    style={{ width: 225 }}
                                                    placeholder={'광고영역을 선택하세요'}
                                                    maxTagCount={'responsive'}
                                                    value={multiSearchSelectedItems}
                                                    onChange={multiSearchHandleChange}
                                                    popupClassName="ant-multi-selelct-dropdown"
                                                    getPopupContainer = {triggerNode => triggerNode.parentElement}
                                                    showSearch={false}
                                                    options={multiSearchOptions}
                                                    tagRender={tagRender}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="기간" colon={false} rules={[{ required: true, message: '기간을 선택하세요.' }]}>
                                                <RangePicker
                                                    allowClear={false}
                                                    locale={locale}
                                                    defaultValue={[defaultStartDate, defaultEndDate]}
                                                    onChange={(dates: any) => {
                                                        setSearchParams({ ...searchParams, startDate: dates[0].format('YYYYMMDD'), endDate: dates[1].format('YYYYMMDD') });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                            <div className="box-right">
                                <Button type="primary" className="pink" onClick={searchEvent}>조회하기</Button>
                            </div>
                        </Flex>
                    </Card>
                </div>
            </section>
            {/* <!-- Wrap-Filter : End --> */}

            {/* <!-- Wrap-Datagrid : Start --> */}
            <section className="wrap-section wrap-datagrid">
                <div className="box-header">
                    <div className="box-left">
                        <Title level={3} className="fc-gray-700">경쟁사 순위</Title>
                        <Text className="fz-14 fc-pink-500">{competitorRankNotice}</Text>
                    </div>
                    <div className="box-right">
                            <Button className="pink" onClick={() => setCompetitorsRankDetailModalYn(true)} disabled={rows.length <= 0}>상세조회</Button>
                        <Button className="pink" onClick={downloadEvent}>
                            <i className="ico ico-download"></i>다운로드</Button>
                    </div>
                </div>
                <div className="box-body">
                    <SkeletonTable loading={gridLoading} columns={gridColumns as SkeletonTableColumnsType[]}>
                        <Table
                            key={"key"}
                            bordered // 선
                            columns={gridColumns}
                            rowKey={"key"}
                            dataSource={rows}
                            pagination={paginationOptions(rows)}
                            scroll={{ x: 'max-content' }} // 가로 스크롤 추가
                            locale={emptyTableLayout(["데이터가 존재하지 않습니다."])}
                        />
                    </SkeletonTable>
                </div>
            </section>
            {/* <!-- Wrap-Datagrid : End --> */}


            {/* <!-- 키워드 선택 모달 --> */}
            {keywordListModalYn &&
                <KeywordListModal nvLoginId={searchParams.advName} selectedKwds={selectedKwds} setSelectedKwds={setSelectedKwds} isOpen={keywordListModalYn} setOpen={setKeywordListModalYn} />
            }

            {/* <!-- 상세조회 모달 --> */}
            {competitorsRankDetailModalYn &&
                <CompetitorsRankDetailModal searchParams={searchParams} selectedKwds={selectedKwds} isOpen={competitorsRankDetailModalYn} setOpen={setCompetitorsRankDetailModalYn} />
            }
        </>
    )
};
export default CompetitorsRankHistory;