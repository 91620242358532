import React, {useEffect, useState} from "react";
import {Button, Modal, Table, Tabs, Typography} from "antd";
import {KeywordBidManageGridProps} from "../../../../pages/kwdBid/kwdBidTypes";
import {nameTreeFormatter} from "../../../../functions/nbsFormatter";
import ReactApexChart from 'react-apexcharts';
import SkeletonTable, {SkeletonTableColumnsType} from "../../../table/skeletonTable";
import apiCall from "../../../../functions/apiCall";
import {ColumnsType} from "antd/es/table";
import {chartOptionsWithContext} from "../../../common/chartType";
import {emptyTableLayout} from "../../../../functions/tableFormatter";
import dayjs from "dayjs";
import {errorAlert} from "../../../../functions/alertFn";
import {competitorRankNotice} from "../../../common/noticeText";

const { Text } = Typography;

interface RankHistoryModalProps {
    selectRow: KeywordBidManageGridProps,
    basicDate: string
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

interface RankHistoryRowProps {
    domain: string;
    hourlyRank: { [key: string]: number | 0.0 };
}

const RankHistoryModal = ({selectRow, basicDate, isOpen, setOpen}: RankHistoryModalProps) => {
    /** 차트 데이터 */
    const [chartLoading, setChartLoading] = useState<boolean>(true)
    const [chartContext, setChartContext] = useState<any>(undefined);
    const [chartSeries, setChartSeries] = useState<{ name: string, data: number[] }[]>([])
    const [showYaxis, setShowYaxis] = useState(false); // 초기값을 false로 설정

    const chartOptions = chartOptionsWithContext(setChartContext, showYaxis);

    /** 그리드 */
    const [gridLoading, setGridLoading] = useState<boolean>(true);
    const [rows, setRows] = useState<RankHistoryRowProps[]>([]);

    const gridColumns: ColumnsType<RankHistoryRowProps> = [
        {title: '도메인', dataIndex: 'domain', align: 'center'}
    ];

    // 시간별 순위 push
    if (rows.length > 0) {
        Object.keys(rows[0].hourlyRank)
            .sort((a, b) => parseInt(a) - parseInt(b))
            .forEach((key) => {
                gridColumns.push({
                    title: key, // 시간 키를 컬럼 타이틀로 사용 ( 00, 01, ...)
                    dataIndex: ['hourlyRank', key],
                    align: 'center',
                    render: (value: number | 0.0) => (value !== 0.0 ? value : '-'), // 0.0 값을 '-'로 표시
                });
            });
    }

    // 파라미터
    let params = {
        basicDate: basicDate,
        kwdName: selectRow.kwdName,
        adArea: selectRow.adArea
    }

    const pageInit = () => {
        setGridLoading(true);
        setChartLoading(true);
        setShowYaxis(true);

        apiCall.get("/keywordBid/bidKwd/loadBidHistories/rank", {params})
            .then(resp => {
                // 차트용 데이터 가공
                const formedData = resp.data.map((item: RankHistoryRowProps) => ({
                    name: item.domain,
                    data: convertHourlyRankValuesToArray(item.hourlyRank), // 배열로 변환
                }));

                // set !!!
                setRows(resp.data)
                setChartSeries(formedData); // 차트에 사용할 변환 데이터

            })
            .catch((error) => {console.error('데이터 로드 실패:', error);
            }).finally(() => {
            setGridLoading(false);
            setChartLoading(false);
        });
    }

    /** hourlyRank객체를 배열로 변환 => value 리스트 */
    const convertHourlyRankValuesToArray = (hourlyRank: { [key: string]: number | 0.0 }): number[] => {
        return Object.keys(hourlyRank)
            .sort((a, b) => parseInt(a) - parseInt(b))
            .map(key => hourlyRank[key]!)
    };

    // 레전드 전체 비활성화 이벤트
    const allChartDataHideEvent = () => {
        if(chartContext === undefined) {
            errorAlert("오류가 발생했습니다.<br />관리자에게 문의바랍니다.");
            return false;
        }
        //업데이트 강제 중지(?)
        const update = chartContext.update;
        chartContext.update = () => { return Promise.resolve(); };

        //전체 가리기 실행
        const chart = ApexCharts.getChartByID('timeRankChart');
        chartSeries.map(v => chart?.hideSeries(v.name)); // hide OR show

        //업데이트 다시 실행
        chartContext.update = update;
        chartContext.update();
    }

    useEffect(() => {
        pageInit();
    }, []);

    return (
        <>
            <Modal
                title="순위 보기"
                open={isOpen}
                maskClosable={false} // 모달 외부 클릭 시 닫히지 않게 설정
                onCancel={() => setOpen(false)}
                width={1200}
                footer={[<Button type="primary" className="pink" size="large" key={"footerCloseBtn"}
                                 onClick={() => setOpen(false)}>확인</Button>]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">키워드</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Typography.Text
                                            className="fz-15 fw-exbold fc-gray-500">{selectRow.kwdName}</Typography.Text>
                                    </div>
                                    <div className="form-group">
                                        {nameTreeFormatter([selectRow.advName, selectRow.campName, selectRow.groupName])}
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">일자</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        {/* <!-- Comp-Txt : Start --> */}
                                        <span className="comp-txt">
                                            <span className="table">
                                                <span className="table-cell">
                                                     <Typography.Text
                                                         className="fz-15 fw-exbold fc-gray-500">{dayjs(basicDate, "YYYYMMDD").format("YYYY-MM-DD")}</Typography.Text>
                                                </span>
                                            </span>
                                        </span>
                                        {/* <!-- Comp-Txt : End --> */}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}


                {/* <!-- Comp-Tab : Start --> */}
                <Tabs
                    className="comp-tab"
                    type="card"
                    defaultActiveKey="1"
                    items={[
                        {
                            label: '전체 순위',
                            key: '1',
                            children:
                                <>
                                    {/* <!-- Wrap-Datagrid : Start --> */}
                                    <section className="wrap-section wrap-datagrid">
                                        <div className="box-header">
                                            <div className="box-left">
                                                <Text className="fz-14 fc-pink-500">{competitorRankNotice}</Text>
                                            </div>
                                        </div>
                                        <div className="box-body">
                                            <SkeletonTable loading={gridLoading}
                                                           columns={gridColumns as SkeletonTableColumnsType[]}>
                                                <Table
                                                    key={"domain"}
                                                    columns={gridColumns}
                                                    dataSource={rows}
                                                    rowKey={"domain"}
                                                    bordered
                                                    scroll={{x: 1200}}
                                                    pagination={false}
                                                    locale={emptyTableLayout(["데이터가 존재하지 않습니다."])}
                                                />
                                            </SkeletonTable>
                                        </div>
                                    </section>
                                    {/* <!-- Wrap-Datagrid : End --> */}
                                </>,
                        },
                        {
                            label: '상세 순위',
                            key: '2',
                            children:
                                <>
                                    <section>
                                        <div className="box-header">
                                            <div className="box-right">
                                                <Button className="pink" onClick={() => allChartDataHideEvent()}>
                                                    전제해제
                                                </Button>
                                            </div>
                                        </div>
                                    </section>
                                    {/* <!-- Wrap-Chart : Start --> */}
                                    <section className="wrap-section wrap-chart">
                                        {/* <!-- Wrap-ReactApexChart : Start --> */}
                                        {!chartLoading &&
                                            <ReactApexChart
                                                type="line"
                                                options={chartOptions}
                                                series={chartSeries}
                                                height={500}
                                            />
                                        }
                                        {/* <!-- Wrap-ReactApexChart : End --> */}
                                    </section>
                                    {/* <!-- Wrap-Chart : End --> */}
                                </>,
                        },
                    ]}
                />
                {/* <!-- Comp-Tab : End --> */}
            </Modal>
        </>
    )
};

export default RankHistoryModal;