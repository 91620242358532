import {Flex, Typography} from "antd";
import React from "react";
import {makeScheduleCubes, scheduleCubeHeader} from "../../functions/bidScheduleFn";
import {BidScheduleStateConfigProps} from "../../pages/kwdBid/kwdBidTypes";


interface ScheduleDrawProps {
    schedule : ScheduleProps | undefined
}
export interface ScheduleProps {
    advId : number,
    bidScheduleId : number,
    bidScheduleName : string,
    bidScheduleDetList: BidScheduleStateConfigProps[]
}
export const ScheduleDrawComponent = ({schedule} : ScheduleDrawProps) => {
    return (
        <>
            <section className="wrap-section wrap-schedule">
                <div className="box-body">
                    <Flex className="schedule-terms" align="center" justify="flex-start" gap={8}>
                        {schedule?.bidScheduleDetList.map((scheduleDet)=> {
                            const colorClassName = "color-palette selected-"+scheduleDet.color.toLowerCase();
                            return (
                                <React.Fragment key={scheduleDet.bidScheduleDetId}>
                                    <div className="box">
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <div className="table">
                                                <div className="box-left">
                                                    <div className={colorClassName}></div>
                                                </div>
                                                <div className="box-right">
                                                    <Flex>
                                                        <Typography.Text className="fz-11 fc-gray-300">목표순위 :</Typography.Text>
                                                        <Typography.Text className="fz-11 fw-exbold fc-gray-500">&nbsp;{scheduleDet.hopeRank === '' ? '키워드 설정값' : `${scheduleDet.hopeRank}위`}</Typography.Text>
                                                        <i className="dot"></i>
                                                        <Typography.Text className="fz-11 fc-gray-300">입찰 사용 :</Typography.Text>
                                                        <Typography.Text className={`fz-11 fw-exbold ${scheduleDet.bidConfigYn ? 'c-green' : 'fc-gray-500 c-red'}`}>&nbsp;{scheduleDet.bidConfigYn ? 'ON' : 'OFF'}</Typography.Text>
                                                    </Flex>
                                                    <Flex>
                                                        <Typography.Text className="fz-11 fc-gray-300">MAX :</Typography.Text>
                                                        <Typography.Text className="fz-11 fw-exbold fc-gray-500">&nbsp;{scheduleDet.maxBidCost === '' ? '키워드 설정값' : `${scheduleDet.maxBidCost}원`}</Typography.Text>
                                                        <i className="dot"></i>
                                                        <Typography.Text className="fz-11 fc-gray-300">MIN :</Typography.Text>
                                                        <Typography.Text className="fz-11 fw-exbold fc-gray-500">&nbsp;{scheduleDet.minBidCost === '' ? '키워드 설정값' : `${scheduleDet.minBidCost}원`}</Typography.Text>
                                                    </Flex>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </Flex>

                    <Flex className="elements selecto-area" id="selecto1" wrap="nowrap">
                        <div className="row-day-week">
                            {scheduleCubeHeader().date.map((dateText) => <div className="day" key={`${dateText}DateHeader`}>{dateText}</div>)}
                        </div>

                        <Flex className="time-box" vertical>
                            <Flex className="col-time">
                                {scheduleCubeHeader().time.map((timeText) => <div className="time" key={`${timeText}TimeHeader`}>{timeText}</div>)}
                            </Flex>
                            <div className="cube-group">
                                {makeScheduleCubes(true).map((dateCube, index)=> {
                                    return (
                                        <Flex key={`${dateCube}_${index}`}>
                                            {
                                                typeof dateCube !== "string" && dateCube.map((cube) => {
                                                    const classList = `cube ${cube.split("_")[0]}`;
                                                    return <div className={classList} key={cube} id={cube + "_column"}></div>
                                                })
                                            }
                                        </Flex>
                                    )
                                })}
                            </div>
                        </Flex>
                    </Flex>
                </div>
            </section>
        </>
    )
};

export default ScheduleDrawComponent;