import {KeywordBidManageGridProps} from "../../../../pages/kwdBid/kwdBidTypes";
import React, {useEffect, useState} from "react";
import {Button, Flex, Modal, Table, Typography} from "antd";
// Import - Icon
import {ArrowRightOutlined} from "@ant-design/icons";
import {paginationOptions} from "../../../../functions/tableFormatter";
import {nameTreeFormatter} from "../../../../functions/nbsFormatter";
import apiCall from "../../../../functions/apiCall";

interface ConfigHistoryModalProps {
    selectRow: KeywordBidManageGridProps,
    isOpen: boolean,
    setOpen:  React.Dispatch<React.SetStateAction<boolean>>
}
const ConfigHistoryModal = ({selectRow, isOpen, setOpen}: ConfigHistoryModalProps) => {
    //항목 : ID / 히스토리 구분 / 이전 내용 / 변경된 내용 / 변경 시간
    const [ historyRows, setHistoryRows ] = useState<{hisId: number, fieldName: string, prevDesc: string, laterDesc: string, updateTime: string}[]>([]);
    const modalInit = () => {
        //apiCall....
        apiCall.get(`/keywordBid/bidKwd/loadBidKwdConfigChangeHistories/${selectRow.bidKwdId}`)
            .then(resp => setHistoryRows(resp.data));
    }

    useEffect(() => { modalInit() }, [])
    return (
        <>
            <Modal
                title="설정 변경 이력"
                open={isOpen}
                onCancel={()=> setOpen(false)}
                width={1200}
                footer={[
                    <Button type="primary" className="pink" size="large" key={"footerCloseBtn"} onClick={()=> setOpen(false)}>확인</Button>,
                ]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">키워드</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Typography.Text className="fz-15 fw-exbold fc-gray-500">{selectRow.kwdName}</Typography.Text>
                                    </div>
                                    <div className="form-group">
                                        {nameTreeFormatter([selectRow.advName, selectRow.campName, selectRow.groupName])}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}

                {/* <!-- Wrap-Datagrid : Start --> */}
                <section className="wrap-section wrap-datagrid">
                    <div className="box-body">
                        <Table
                            key={"configHistoryGrid"}
                            rowKey={"hisId"}
                            columns={[
                                {title: '변경 내용', dataIndex: 'historyDescription', align: 'center',
                                    render: (text: string, record) =>
                                        <Flex justify="flex-start" align="center" gap={8}>
                                            <Typography.Text className="fc-gray-500">{record.fieldName}</Typography.Text>
                                            <Typography.Text className="fc-pink-500">{record.prevDesc}</Typography.Text>
                                            <ArrowRightOutlined className="fc-pink-500" rev={undefined} />
                                            <Typography.Text className="fc-pink-500">{record.laterDesc}</Typography.Text>
                                        </Flex>
                                },
                                {
                                    title: '변경 시간',
                                    dataIndex: 'updateTime',
                                    align: 'center',
                                },
                            ]}
                            dataSource={historyRows}
                            bordered
                            pagination={paginationOptions(historyRows)}
                        />
                    </div>
                </section>
                {/* <!-- Wrap-Datagrid : End --> */}
            </Modal>
        </>
    )
};

export default ConfigHistoryModal;