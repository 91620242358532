import Button from "antd/es/button";
import React, {useContext, useState} from "react";
import {AuthContext} from "../../auth/loginAuthContext";
import apiCall from "../../functions/apiCall";
import {Form, Input} from "antd";
import {errorAlert} from "../../functions/alertFn";

interface loginFormProps {
    memberId: string,
    pwd: string,
}
const Login = () => {
    const authContext = useContext(AuthContext);
    const [ loginForm, setLoginForm ] = useState<loginFormProps>({
        memberId : '', pwd : '',
    })
    const [ loginFail, setLoginFail] = useState<boolean>(false);

    const changeLoginForm = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLoginForm({ ...loginForm, [name]: value});
    }

    const loginEvent = () => {
        apiCall.post("/auth/login", loginForm)
            .then(res => authContext.login(res))
            .catch(error => {
                //로그인 실패(이메일 및 비밀번호 틀릴 경우) : ERR_BAD_REQUEST로 코드값을 던짐. 그 외의 경우에는 일단 전체 메시지로 처리.
                errorAlert(
                    error.code === "ERR_BAD_REQUEST" ? "아이디 및 비밀번호를 다시 확인해주세요." : "로그인이 실패되었습니다. 관리자에게 문의해주세요.",
                    {error}
                );
                setLoginFail(true)
            });
    }

    return (
    <>
        <div className="wrap login">
            <div className="box-inner">
                <div className="box-left">
                    <div className="box-top">
                        <div className="logo">Nbidon</div>
                        <div className="logo-sub">NHNAD 자동입찰 솔루션 로그인</div>
                    </div>
                    <div className="box-middle">
                        <div className="company-name">
                            <i className="ico ico-check"></i>
                            <span className="txt-company">Nbidon</span>
                        </div>
                        <Form>
                            <Input
                                name="memberId"
                                placeholder="아이디를 입력해주세요."
                                size="large"
                                prefix={<i className="ico ico-id"></i>}
                                defaultValue={loginForm.memberId}
                                onChange={changeLoginForm}
                            />
                            <Input.Password
                                name={"pwd"}
                                placeholder="비밀번호를 입력해주세요."
                                size="large"
                                prefix={<i className="ico ico-pw"></i>}
                                value={loginForm.pwd}
                                autoComplete={"off"}
                                onChange={changeLoginForm}
                                onPressEnter={loginEvent}
                            />
                        </Form>
                        {loginFail && <p className="txt-error show">아이디 또는 비밀번호가 일치하지 않습니다.</p>}
                    </div>
                    <div className="box-bottom">
                        <Button type="primary" className="pink" size="large" block onClick={loginEvent}>로그인</Button>
                    </div>
                </div>
                <div className="box-right">
                    <img src={require('../../images/img-login-object.jpg')} alt="Nbidon 솔루션 화면 이미지" />
                </div>
            </div>
        </div>
    </>
    );
}

export default Login;