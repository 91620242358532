import React, {useEffect, useState} from 'react';
import {Button, Modal, Table, Typography} from "antd";
import {BidKwdListGripProps, BidScheduleManageGridProps} from "../../../pages/kwdBid/kwdBidTypes";
import {paginationOptions} from "../../../functions/tableFormatter";
import modal from "antd/es/modal";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {errorAlert, successAlert} from "../../../functions/alertFn";
import apiCall from '../../../functions/apiCall';

interface KeywordInfoListModalProps {
    selectRow: BidScheduleManageGridProps,
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setGridRefresh: React.Dispatch<React.SetStateAction<boolean>>
}
const KeywordInfoListModal = ({selectRow, isOpen, setOpen, setGridRefresh }: KeywordInfoListModalProps) => {
    const [ keywordRows, setKeywordRows ] = useState<BidKwdListGripProps[]>([]);
  //  const [ keywordRows, setKeywordRows ] = useState<BidKwdListGripProps[]>(selectRow.keywords === undefined ? [] : rows);

    const exceptKeywordEvent = (kwdId: number) => {
        modal.confirm({
            icon: <ExclamationCircleFilled rev={undefined} />,
            title: '선택한 키워드를 스케줄 제외하시겠습니까?',
            content: '제외한 키워드는 더이상 스케줄을 적용하지 않습니다.',
            okText: '확인', cancelText: '취소',
            okButtonProps: { className: "pink ant-btn-lg" },
            onOk() {
                apiCall.put("/keywordBid/bidSchedule/deleteBidKwd", kwdId)
                .then(resp => {
                    successAlert("키워드 제외 완료되었습니다.");
                    setGridRefresh(true);
                    reloadKeywordList(kwdId);
                })
                .catch(error => {
                    errorAlert("키워드 제외 시 오류가 발생했습니다.<br />관리자에게 문의바랍니다.", {error});
                }).finally(() => {});
                
            },
            cancelButtonProps: { type: 'primary', className: "gray ant-btn-lg" },
        })
    }

    // /** 제외 시키고 나서 다시 키워드 리스트 로드 */
    const reloadKeywordList = (kwdId: number) => {
        setKeywordRows(keywordRows.filter((row) => row.kwdId !== kwdId));
        loadKwdList();
    }
    const loadKwdList = () => {
        const bidKwdIdList = selectRow.bidKwdIdList?.map(item => item.bidKwdId);
        const bidScheduleId = selectRow.bidScheduleId;
        apiCall.post("/keywordBid/bidSchedule/findBidKwdList", {bidScheduleId,bidKwdIdList})
        .then(resp => {
            setKeywordRows(resp.data);
        })
        .catch(error => {
            errorAlert("입찰키워드 목록 데이터가 정상적으로 로드 되지 않습니다.<br />관리자에게 문의바랍니다.", {error});
        })
        .finally();
    }

    useEffect(() => {
        loadKwdList(); // 조회필터 광고주 리스트 로드
    }, []);
    return (
        <>
            <Modal
                title="적용 키워드 목록"
                open={isOpen}
                onCancel={() => {setOpen(false); }}
                afterClose={() => { setOpen(false); }}
                width={1200}
                footer={[
                    <Button type="primary" className="pink" size="large" key="footerCloseBtn" onClick={() => setOpen(false)}>
                        확인
                    </Button>,
                ]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">스케줄명</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Typography.Text className="fz-15 fw-exbold fc-gray-500">{selectRow.bidScheduleName}</Typography.Text>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}

                {/* <!-- Wrap-Datagrid : Start --> */}
                <section className="wrap-section wrap-datagrid">
                    <div className="box-body">
                        <Table
                            key={"keywordListGrid"}
                            rowKey={"kwdId"}
                            columns={[
                                {title: '광고주 > 캠페인 > 그룹', dataIndex: 'groupName', align: 'center',
                                    render: (groupName:string, record) => `${selectRow.advName} > ${record.campName} > ${groupName}`
                                },
                                {title: '키워드 명', dataIndex: 'kwdName', align: 'center' },
                                {title: '사용', dataIndex: 'deleteBtn', align: 'center', render: (value, record) => {
                                    return <Button className="pink" size="small" onClick={() => { exceptKeywordEvent(record.kwdId) }}>제외</Button>
                                }}
                            ]}
                            dataSource={keywordRows}
                            bordered
                            pagination={paginationOptions(keywordRows)}
                        />
                    </div>
                </section>
                {/* <!-- Wrap-Datagrid : End --> */}
            </Modal>
        </>
    );
};
export default React.memo(KeywordInfoListModal);