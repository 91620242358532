import {Breadcrumb, Flex, Space} from "antd";
import React from "react";

interface ContentHeaderProps {
    pageTitle: string,
    navigation: string[]
}
const ContentHeader = ({pageTitle, navigation}: ContentHeaderProps) => {
    let item: {title: string}[] = [];
    navigation.map((title) => item.push({title : title}));
    return (
    <>
        <div className="content-header">
            <Flex justify="space-between" align="flex-end">
                <Space direction="vertical">
                    <Breadcrumb items={item} />
                    <h1 className="fz-32 fc-gray-900">{pageTitle}</h1>
                </Space>
            </Flex>
        </div>
    </>
    );
}

export default ContentHeader;