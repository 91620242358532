import React, {useEffect, useState} from "react";
import ContentHeader from "../../components/layout/contentHeader";
import {Table, Typography} from "antd";
import {ProductAdvRowProps, ProductUserRowProps} from "./kwdBidTypes";
import {drawFooterRow, prettyNumber} from "../../functions/tableFormatter";
import apiCall from "../../functions/apiCall";
import SkeletonTable, {SkeletonTableColumnsType} from "../../components/table/skeletonTable";
import {ColumnsType} from "antd/es/table";

const { Title, Text } = Typography;

const BidProductState = () => {
    //전체적인 정보
    //const [ _, setUserProductInfo ] = useState<BidProductStateProps>();
    const [ productUserGridLoading, setProductUserGridLoading ] = useState<boolean>(true);
    const [ productAdvGridLoading,  setProductAdvGridLoading ] = useState<boolean>(true);

    //상단 그리드 데이터
    const [ productUserRows, setProductUserRows ] = useState<ProductUserRowProps[]>([]);
    //하단 그리드 데이터
    const [ productAdvRows, setProductAdvRows] = useState<ProductAdvRowProps[]>([]);

    /** 광고주별 입찰 사용 목록 데이터 로드 */
    const loadBidItemOnOffInfo = () => {
        setProductUserGridLoading(true)
        apiCall.get("/keywordBid/bidProduct/findAdvBidItemUseList")
        .then(resp =>{
            setProductAdvRows(resp.data);
            setProductUserGridLoading(false);
        })
        .catch()
        .finally()
    };
    /** 광고주별 입찰 사용 목록 데이터 로드 */
    const loadBidItemStateCountInfo = () => {
        setProductAdvGridLoading(true);
        apiCall.get("/keywordBid/bidProduct/findBidItemStateTotalCount")
        .then(resp =>{
             //유저 정보 세팅
           setProductUserRows(resp.data.productState);
            setProductAdvGridLoading(false);
        })
        .catch()
        .finally()
       
    };

    /** 상단 그리드 푸터(잔여 개수) 데이터 가공 */
    const convertSummaryTopGrid = (rows: readonly ProductUserRowProps[]) => {
        let chargeTargetCnt = 0, chargeBasicCnt = 0, chargeUniqueCnt = 0, chargePremiumCnt = 0;
        let useTargetCnt = 0, useBasicCnt = 0, useUniqueCnt = 0, usePremiumCnt = 0;

        //값 설정
        rows.map((item) => {
            if(item.type === "CHARGE") {
                chargeTargetCnt = item.targetCnt
                chargeBasicCnt = item.basicCnt
                chargeUniqueCnt = item.uniqueCnt
                chargePremiumCnt = item.premiumCnt
            }  else {
                useTargetCnt = item.targetCnt
                useBasicCnt = item.basicCnt
                useUniqueCnt = item.uniqueCnt
                usePremiumCnt = item.premiumCnt
            }
        })
        return [chargeTargetCnt-useTargetCnt, chargeUniqueCnt-useUniqueCnt, chargePremiumCnt-usePremiumCnt, chargeBasicCnt-useBasicCnt];
    }

    /** 하단 그리드 푸터(총합계) 데이터 가공 */
    const convertSummaryBottomGrid = (rows: readonly ProductAdvRowProps[]) => {
        let totalOnTargetCnt = 0, totalOnUniqueCnt = 0, totalOnPremiumCnt = 0, totalOnBasicCnt = 0;
        let totalOffTargetCnt = 0, totalOffUniqueCnt = 0, totalOffPremiumCnt = 0, totalOffBasicCnt = 0;

        //값 설정
        rows.map((item) => {
            totalOnTargetCnt += item.onTargetCnt
            totalOnUniqueCnt += item.onUniqueCnt
            totalOnPremiumCnt += item.onPremiumCnt
            totalOnBasicCnt += item.onBasicCnt
            totalOffTargetCnt += item.offTargetCnt
            totalOffUniqueCnt += item.offUniqueCnt
            totalOffPremiumCnt += item.offPremiumCnt
            totalOffBasicCnt += item.offBasicCnt
        })
        const totalOnAll = totalOnTargetCnt+totalOnUniqueCnt+totalOnPremiumCnt+totalOnBasicCnt;
        const totalOffAll = totalOffTargetCnt+totalOffUniqueCnt+totalOffPremiumCnt+totalOffBasicCnt;
        return [
            totalOnTargetCnt, totalOnUniqueCnt, totalOnPremiumCnt, totalOnBasicCnt, totalOnAll,
            totalOffTargetCnt, totalOffUniqueCnt, totalOffPremiumCnt, totalOffBasicCnt, totalOffAll
        ];
    }

    /** 페이지 진입 시점 실행 */
    useEffect(() => {
        loadBidItemOnOffInfo();
        loadBidItemStateCountInfo();
        document.title = "입찰 상품 현황";
    }, []);

    const productUserGridColumns: ColumnsType<ProductUserRowProps> =[
        {title: '상품', dataIndex: 'productName', align: 'center',
            render: (_, record:ProductUserRowProps) => record.type === "CHARGE"? "충전 개수" : "사용 개수"},
        {title: '타겟', dataIndex: 'targetCnt', align: 'center', render: (_, record:ProductUserRowProps) => prettyNumber(record.targetCnt)},
        {title: '유니크', dataIndex: 'uniqueCnt', align: 'center', render: (_, record:ProductUserRowProps) => prettyNumber(record.uniqueCnt)},
        {title: '프리미엄', dataIndex: 'premiumCnt', align: 'center', render: (_, record:ProductUserRowProps) => prettyNumber(record.premiumCnt)},
        {title: '베이직', dataIndex: 'basicCnt', align: 'center', render: (_, record:ProductUserRowProps) => prettyNumber(record.basicCnt)}
    ]

    const productAdvGridColumns: ColumnsType<ProductAdvRowProps> =[
        { title: '광고주 / 상품', dataIndex: 'advName', align: 'center' },
        { title: '입찰사용 ON', align: 'center',
            children: [
                { title: '타겟', dataIndex: 'onTargetCnt', align: 'center'},
                { title: '유니크', dataIndex: 'onUniqueCnt', align: 'center'},
                { title: '프리미엄', dataIndex: 'onPremiumCnt', align: 'center'},
                { title: '베이직', dataIndex: 'onBasicCnt', align: 'center'},
                { title: '합계', dataIndex: 'onTotal', align: 'center', rowScope: 'row', render: (_, record) => {
                        return record.onTargetCnt + record.onUniqueCnt + record.onPremiumCnt + record.onBasicCnt;
                    }
                }
            ]
        },
        {title: '입찰사용 OFF', align: 'center',
            children: [
                {title: '타겟', dataIndex: 'offTargetCnt', align: 'center'},
                {title: '유니크', dataIndex: 'offUniqueCnt', align: 'center'},
                {title: '프리미엄', dataIndex: 'offPremiumCnt', align: 'center'},
                {title: '베이직', dataIndex: 'offBasicCnt', align: 'center'},
                {title: '합계', dataIndex: 'offTotal', align: 'center', rowScope: 'row', render: (_, record) => {
                        return record.offTargetCnt + record.offUniqueCnt + record.offPremiumCnt + record.offBasicCnt;
                    }
                }
            ]
        }
    ]

    return (
        <>
            <ContentHeader pageTitle={"입찰 상품 현황"} navigation={["키워드 입찰", "입찰 상품 현황"]} />

            {/* <!-- Wrap-Datagrid : Start --> */}
            <section className="wrap-section wrap-datagrid">
                <div className="box-header">
                    <div className="box-left">
                        <Text className="fz-14 fc-pink-500">잔여 개수 내에서 입찰 상품 사용이 가능하며, 상품 충전은 애드테크팀으로 문의 바랍니다.</Text><br/>
                        <Text className="fz-14 fc-pink-500">입찰 주기(공통): 2분, 모니터링 간격: 타겟 1분 / 유니크 3분 / 프리미엄 5분 / 베이직 20분</Text>
                    </div>
                </div>
                <div className="box-body">
                    <SkeletonTable loading={productUserGridLoading} columns={productUserGridColumns as SkeletonTableColumnsType[]}>
                        <Table
                            key={"productUserGrid"}
                            rowKey={(record) => record.type}
                            columns={productUserGridColumns}
                            dataSource={productUserRows}
                            pagination={false}
                            bordered
                            summary={(record) => {
                                let row = convertSummaryTopGrid(record)
                                return drawFooterRow("잔여 개수", row);
                            }}
                        />
                    </SkeletonTable>
                </div>
            </section>
            {/* <!-- Wrap-Datagrid : End --> */}

            {/* <!-- Wrap-Datagrid : Start --> */}
            <section className="wrap-section wrap-datagrid">
                <div className="box-header">
                    <div className="box-left">
                        <Title level={3} className="fc-gray-700">광고주별 입찰 사용 목록</Title>
                    </div>
                </div>
                <div className="box-body">
                    <SkeletonTable loading={productAdvGridLoading} columns={productAdvGridColumns as SkeletonTableColumnsType[]}>
                        <Table
                            key={"productAdvGrid"}
                            rowKey={(record) => record.advId}
                            columns={productAdvGridColumns}
                            dataSource={productAdvRows}
                            pagination={false}
                            bordered
                            summary={(record) => {
                                let row = convertSummaryBottomGrid(record);
                                return drawFooterRow("총합계", row);
                            }}
                        />
                    </SkeletonTable>
                </div>
            </section>
            {/* <!-- Wrap-Datagrid : End --> */}
        </>
    )
};

export default BidProductState;