import React from 'react';
import {Button, Modal, Table, Tag, Typography} from "antd";
import {paginationOptions} from "../../../functions/tableFormatter";
import {ApiManageGridProps} from "../../../pages/connectionMng/connectionMngTypes";

const { Text } = Typography;

interface AdvInfoListModalProps {
    selectRow: ApiManageGridProps,
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}
const AdvInfoListModal = ({selectRow, isOpen, setOpen }: AdvInfoListModalProps) => {
    const dataSource = selectRow.advs === undefined ? [] : selectRow.advs;
    return (
        <>
            <Modal
                title="등록 광고주 목록"
                open={isOpen}
                onCancel={() => {setOpen(false); }}
                afterClose={() => { setOpen(false); }}
                width={1200}
                footer={[
                    <Button type="primary" className="pink" size="large" key="footerCloseBtn" onClick={() => setOpen(false)}>
                        확인
                    </Button>
                ]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">API 등록 로그인 ID</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Text className="fz-15 fw-exbold fc-gray-500">{selectRow.loginId}</Text>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}

                {/* <!-- Wrap-Datagrid : Start --> */}
                <section className="wrap-section wrap-datagrid">
                    <div className="box-body">
                        <Table
                            key={"advListGrid"}
                            rowKey={(record) => record.nvLoginId}
                            columns={[
                                {title: 'No', dataIndex: 'index', align: 'center', render: (_, record, index) => index+1},
                                {title: '광고주 로그인 ID', dataIndex: 'nvLoginId', align: 'center' },
                                {title: 'customerId', dataIndex: 'nvAdvId', align: 'center' },
                                {title: '광고주명', dataIndex: 'customAdvName', align: 'center' },
                                {title: '상태', dataIndex: 'linkPossYn', align: 'center', render: (value, record) =>{
                                    return value === true ? <Tag color={"blue"} key={"blue"}>{"원활"}</Tag> :
                                        <Tag color={"default"} key={"default"}>{"에러"}</Tag>
                                    }
                                }
                            ]}
                            dataSource={dataSource} bordered pagination={paginationOptions(dataSource)}
                        />
                    </div>
                </section>
                {/* <!-- Wrap-Datagrid : End --> */}
            </Modal>
        </>
    );
};
export default React.memo(AdvInfoListModal);