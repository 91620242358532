import React, {useEffect, useState} from "react";
import KeywordBidManageFilterComponent from "../../components/kwdBid/keywordBidManage/keywordBidManageFilterComponent";
import {KeywordBidManageGridProps, KeywordBidManageSearchProps} from "./kwdBidTypes";
import KeywordBidManageGridComponent from "../../components/kwdBid/keywordBidManage/keywordBidManageGridComponent";
import apiCall from "../../functions/apiCall";

const KeywordBidManage = () => {
    /** 검색 조회 필터 항목 */
    const [ searchParams, setSearchParams ] = useState<KeywordBidManageSearchProps>({
        advId : 0, campId : 'ALL', groupId : 'ALL', kwdName : '', kwdSearchDiv : 'EXIST',
        bookmarkDiv: 'ALL', bidConfigYn : 'ALL', bidItemDiv : 'ALL', adAreaDiv : 'ALL', scheduleId : 0,
        searchEventYn: false, pageNumber : 1, pageSize : 10, sortColumn : "kwdName", sortOrder : "ascend" });
    /** 그리드 데이터 항목 */
    const [ gridLoading, setGridLoading ] = useState<boolean>(true);
    const [ gridRefresh, setGridRefresh ] = useState<boolean>(false);
    const [ rows, setRows ] = useState<KeywordBidManageGridProps[]>([]);
    const [ totalCnt, setTotalCnt ] = useState<number>(0);

    const searchEvent = () => {
        //검색 이벤트 변수 초기화
        setGridLoading(true); // 로드할때 true로 변경
        //전체일 경우에 데이터 가공이 필요하여 여기서 변수를 다시 대입해준다.
        let params = {...searchParams,
            advId : searchParams.advId === 0 ? undefined : searchParams.advId,
            campId : searchParams.campId === 'ALL' ? undefined : searchParams.campId,
            groupId : searchParams.groupId === 'ALL' ? undefined : searchParams.groupId,
            bookmarkDiv : searchParams.bookmarkDiv === 'ALL' ? undefined : searchParams.bookmarkDiv,
            bidConfigYn: searchParams.bidConfigYn === 'ALL' ? undefined : searchParams.bidConfigYn,
            bidItemDiv : searchParams.bidItemDiv === 'ALL' ? undefined : searchParams.bidItemDiv,
            adAreaDiv : searchParams.adAreaDiv === 'ALL' ? undefined : searchParams.adAreaDiv,
            scheduleId : searchParams.scheduleId === 0 ? undefined : searchParams.scheduleId,
            pageNumber : searchParams.pageNumber === undefined ? 1 : searchParams.pageNumber,
            pageSize : searchParams.pageSize === undefined ? 10 : searchParams.pageSize,
            sortColumn : searchParams.sortColumn === undefined ? "kwdName" : searchParams.sortColumn,
            sortOrder : searchParams.sortOrder === undefined ? "ascend" : searchParams.sortOrder,
        };
        //row 데이터 초기화. 안해주면 콘솔 창에서 경고 메시지 뜸.
        setRows([]);
        apiCall.post("/keywordBid/bidKwd/loadData", params)
            .then(resp => {
                setRows(resp.data.content);
                setTotalCnt(resp.data.totalElements);
                //setRows(resp.data);
                setGridLoading(false); // 데이터 세팅 완료되면 false로 변경
            })
        setSearchParams({...searchParams, searchEventYn: false})
    }

    useEffect(() => {
        searchEvent(); // 전체 광고주 대상 키워드 로드.
        document.title = "키워드 입찰 관리";
    }, [])

    useEffect(() => {
        if (gridRefresh) {
            searchEvent();
            setGridRefresh(false)
        }
    },[gridRefresh])

    useEffect(() => {
        if(searchParams.searchEventYn){ //검색이벤트 변수가 활성 상태일 경우에만 검색 이벤트를 진행한다.
            searchEvent();
        }
    }, [searchParams])
    return (
        <>
            {/* 키워드 입찰 관리 조회 필터 컴포넌트 : Start */}
            <KeywordBidManageFilterComponent
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />
            {/* 키워드 입찰 관리 조회 필터 컴포넌트 : End */}

            {/* 키워드 입찰 관리 그리드 컴포넌트 : Start */}
            <KeywordBidManageGridComponent searchParams={searchParams}
                                           setSearchParams={setSearchParams}
                                           rows={rows}
                                           totalCnt={totalCnt}
                                           gridLoading={gridLoading}
                                           setGridRefresh={setGridRefresh}
            />
            {/* 키워드 입찰 관리 그리드 컴포넌트 : End */}
        </>
    )
};

export default KeywordBidManage;