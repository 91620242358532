import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import {errorAlert, successAlert} from "../../../functions/alertFn";
import apiCall from "../../../functions/apiCall";


interface ModifyUserModalProps {
    record: any, // 업데이트 할 대상을 선정하는 항목(사용자) - 귀찮아서 일단 any로...
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setGridRefresh: React.Dispatch<React.SetStateAction<boolean>>

}

const ModifyUserModal = ({record, isOpen, setOpen, setGridRefresh }: ModifyUserModalProps) => {

    /** 비밀번호 및 비밀번호 확인용 */
    const [ memberName, setMemberName] = useState<string>(record.memberName);
    const [ pwd, setPwd] = useState<string>('');
    const [ pwdConfirm, setPwdConfirm] = useState<string>('');
    const [ accordPwdYn, setAccordPwdYn] = useState<boolean>(true);
    const [form] = Form.useForm();

    //모달 초기화
    const modalReset = () => {
        form.resetFields();
        setMemberName(record.memberName);
        setPwd('');
        setPwdConfirm('');
        setAccordPwdYn(true);
    }

    //사용자 정보 수정 벨리데이션
    const modifyValidation = () => {
        if(record === undefined) {
            errorAlert("사용자를 선택해주세요.");
            return false;
        }
        if(memberName === undefined || memberName === '') {
            errorAlert("사용자명을 입력해주세요.");
            return false;
        }
        if(!accordPwdYn) {
            errorAlert("비밀번호를 확인해주세요.");
            return false;
        }
        return true;
    }


    const onChangeModifiyFormEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if(name === "memberName") setMemberName(value)
        else if(name === "pwd") setPwd(value)
        else if(name === "pwdConfirm") setPwdConfirm(value);
    }

    const disabledBtnEvent = () => {
        return !accordPwdYn || memberName === undefined || memberName === '';
    }

    //사용자 수정 이벤트
    const modifiyFormEvent = () => {
        if (modifyValidation()) {
            const params = {memberId: record.memberId, memberName: memberName, pwd: pwd, pwdConfirm: pwdConfirm};
            apiCall.post("/auth/modify", params)
                .then(resp => {
                    successAlert("사용자 수정이 완료되었습니다.");
                    modalReset();
                    setGridRefresh(true);
                    setOpen(false);
                })
                .catch(error => {
                    errorAlert("올바른 정보를 입력해주세요.", {error});
                })
                .finally(() => {
                    // //시작시간, 종료시간 초기화
                    // setStartTime(null);
                    // setEndTime(null);
                    // //목표설정, 목표순위 초기화
                    // setHopeRankType(null);
                    // setHopeRank(null);
                })
        }
    }

    return (
        <>
            <Modal
                title="사용자 정보 수정"
                open={isOpen}
                onCancel={() => {modalReset(); setOpen(false); }}
                afterClose={() => { modalReset(); setOpen(false); }}
                width={900}
                footer={[
                    <Button type="primary" className="gray" size="large" key="footerCancelBtn" onClick={() => {modalReset(); setOpen(false); }}>취소</Button>,
                    <Button type="primary" className="pink" size="large" key="footerChargeBtn" disabled={disabledBtnEvent()} onClick={modifiyFormEvent}>저장</Button>,
                ]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    {/*<div className="box-header">
                        <div className="box-left">
                            <Title level={4} className="fc-gray-500">사용자 수정</Title>
                        </div>
                    </div>*/}
                    <div className="box-body">
                        <Form form={form} name="dependencies" autoComplete="off" layout="vertical">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">ID</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Input className="w-500" value={record.memberId} disabled />
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">사용자명</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Input className="w-500" name="memberName" value={memberName} onChange={onChangeModifiyFormEvent} />
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">비밀번호</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Form.Item name="password" rules={[{ required: true, message: "비밀번호를 입력해주세요." }]}>
                                            <Input.Password className="w-500" name={"pwd"} value={pwd} onChange={onChangeModifiyFormEvent} />
                                        </Form.Item>
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">비밀번호 확인</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Form.Item
                                            name="password2"
                                            dependencies={['password']}
                                            rules={[{ required: true, },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            setAccordPwdYn(true);
                                                        } else {
                                                            setAccordPwdYn(true);
                                                            return Promise.reject(new Error('입력하신 비밀번호와 일치하지 않습니다.'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password className="w-500" name={"pwdConfirm"} value={pwdConfirm} onChange={onChangeModifiyFormEvent} onPressEnter={modifiyFormEvent}/>
                                        </Form.Item>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                        </Form>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}
            </Modal>
        </>
    );
};
export default React.memo(ModifyUserModal);