/** 키워드 입찰 관리 - 데이터 고정 리스트(셀렉트박스에서 사용) */
import {StarFilled} from "@ant-design/icons";
import React from "react";

/** 상세 조회 - 북마크 리스트 */
export const bookmarkList = (allYn: boolean) => {
    let array: { value: string, label: string | JSX.Element[] }[] = [
        {value: 'RED', label: [<StarFilled rev={undefined} className="c-red" key={"redBookmark"}/>]},
        {value: 'ORANGE', label: [<StarFilled rev={undefined} className="c-orange" key={"orangeBookmark"}/>]},
        {value: 'YELLOW', label: [<StarFilled rev={undefined} className="c-yellow" key={"yellowBookmark"}/>]},
        {value: 'GREEN', label: [<StarFilled rev={undefined} className="c-green" key={"greenBookmark"}/>]},
        {value: 'BLUE', label: [<StarFilled rev={undefined} className="c-blue" key={"blueBookmark"}/>]},
        {value: 'INDIGO', label: [<StarFilled rev={undefined} className="c-navy" key={"navyBookmark"}/>]},
        {value: 'PURPLE', label: [<StarFilled rev={undefined} className="c-purple" key={"purpleBookmark"}/>]},
        {value: 'NULL', label: [<StarFilled rev={undefined} className="c-null" key={"nullBookmark"}/>]}
    ];
    if (allYn) {
        array.unshift({value: "ALL", label: '전체'})
    }
    return array;
}
/** 상세조회 - 광고 상태 / 입찰 사용 여부 리스트 */
export const onOffList = (allYn: boolean) => {
    let array: { value: string | boolean | null, label: string }[] = [{value: true, label: 'ON'}, {
        value: false,
        label: 'OFF'
    }];
    if (allYn) {
        array.unshift({value: 'ALL', label: '전체'});
    }
    return array;
}
/** 상세조회 - 입찰 상태 리스트 */
export const bidStatusList = () => {
    return [
        {value: 'ALL', label: '전체'},
        {value: 'BID_ING', label: '입찰 중'},
        {value: 'BID_READY', label: '입찰 대기'},
        {value: 'BID_ERROR', label: '입찰 에러'},
        {value: 'DIRECT_BID', label: '수동 입찰'},
        {value: 'BID_OFF', label: '입찰 OFF'},
    ]
}
/** 상세조회 - 상품 리스트 */
export const bidItemDivList = (allYn: boolean) => {
    let array: { value: string | number | null, label: string }[] = [
        {value: 'TARGET', label: '타겟'},
        {value: 'UNIQUE', label: '유니크'},
        {value: 'PREMIUM', label: '프리미엄'},
        {value: 'BASIC', label: '베이직'}
    ];
    if (allYn) {
        array.unshift({value: 'ALL', label: '전체'});
    }
    return array;
}
/** 상세조회 - 광고 영역 리스트 */
export const adAreaDivList = (allYn: boolean) => {
    let array: { value: string | number | null, label: string }[] = [
        {value: 'PC_TOTAL', label: 'PC'}, {value: 'PC_DETAIL', label: 'PC 더보기'}, {
            value: 'MOBILE_TOTAL',
            label: 'MO'
        }, {value: 'MOBILE_DETAIL', label: 'MO 더보기'}
    ];
    if (allYn) {
        array.unshift({value: 'ALL', label: '전체'});
    }
    return array;
}
/** 상세조회 - 순위 리스트 */
export const bidRankList = (allYn: boolean, adAreaDiv: string) => {
    let array: { value: string | number | null, label: string }[] = [];

    //PC: 1~15, PC더보기: 1~25, MO: 1~5, MO더보기: 1~15
    let maxRank: number;
    switch (adAreaDiv) {
        case 'PC_TOTAL':
            maxRank = 15;
            break;
        case 'PC_DETAIL':
            maxRank = 25;
            break;
        case 'MOBILE_TOTAL':
            maxRank = 5;
            break;
        case 'MOBILE_DETAIL':
            maxRank = 15;
            break;
        default:
            throw new Error('Unsupported type');
    }

    for (let i = 1; i <= maxRank; i++) {
        array.push({value: i, label: `${i}위`});
    }

    if (allYn) {
        array.unshift({value: null, label: '전체'});
    }
    return array;
}

export const bidScheduleList = (
    scheduleList: { scheduleId: string | number, scheduleName: string | undefined }[]
        | null
        | undefined,
    allYn: boolean,
    labelName: string = '전체'
) => {
    let array = scheduleList  ? scheduleList.map(schedule => ({
            value: schedule.scheduleId,
            label: schedule.scheduleName
        }))
        : [];
    if (allYn) {
        array.unshift({value: 0, label: labelName})
    }
    return array;
}

