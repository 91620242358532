import React from "react";
import {FolderOutlined} from "@ant-design/icons";
import {Typography} from "antd";

/************************************ Nbidon 전용 포맷 모음 : Start */

/* 고정 북마크 ID에 대한 화면 상에 필요한 항목을 리턴한다. 아이디는 정해지지 않아 정해지는 시점에 변경할 것 */
export const bookmarkFormatter = (bookmarkDiv: string | number, keyId: string | number) => {
    const key = keyId+"_"+bookmarkDiv;
    let colorClassName = "";
    switch(bookmarkDiv){
        case "RED" : colorClassName = "c-red"; break;
        case "ORANGE" : colorClassName = "c-orange"; break;
        case "YELLOW" : colorClassName = "c-yellow";  break;
        case "GREEN" : colorClassName = "c-green"; break;
        case "BLUE" : colorClassName = "c-blue"; break;
        case "INDIGO" : colorClassName = "c-navy"; break;
        case "PURPLE" : colorClassName = "c-purple"; break;
        default : colorClassName = "c-null"; break;
    }
    return { colorClassName, key };
}

/* 입찰 상태에 대한 화면 상에 필요한 항목을 리턴한다. 추가되는 이넘 값은 각자 추가할 것 */
export const bidStatusFormatter = (bidStatus: string, keyId: string | number) => {
    interface bidStatusMapProps {
        [index: string]: string,
        BID_ING: string,
        BID_READY: string,
        BID_ERROR: string,
        DIRECT_BID: string,
        BID_OFF: string,
        ETC: string,
    }
    //위의 bidStatusMapProps 동일한 이넘 값이 있어야 한다.
    const bidStatusMap:bidStatusMapProps = {
        BID_ING : "입찰 중", BID_READY: "입찰 대기", BID_ERROR: "입찰 에러", DIRECT_BID: "수동 입찰", BID_OFF: "입찰 OFF", ETC : "그 외"
    };
    const errorBidStatus = ["BID_ERROR"];
    const color = errorBidStatus.includes(bidStatus) ? "red" : bidStatus === "BID_OFF" ? "default" : "green";
    const key = keyId+"_"+bidStatus; // key는 고유한 값을 넘겨야 한다. ( ex : kwdId 등 )
    const desc = bidStatusMap[bidStatus] === undefined ? bidStatusMap["ETC"] : bidStatusMap[bidStatus];

    return { color, key, desc };
}

    /** 항목 내부의 이름 트리로 나오도록 JSX를 리턴한다. 대부분 광고주 -> 캠페인 -> 그룹 순이다. */
export const nameTreeFormatter = (tree: string[]) => {
    return (
        <>
            {tree.map((name: string, index) => {
                const key = `${name}_${index}`;
                return (
                    index === 0 ?
                        <React.Fragment key={key}>
                            <FolderOutlined rev={undefined} className="fc-gray-300"/>
                            <Typography.Text>{name}</Typography.Text>
                        </React.Fragment> :
                        <React.Fragment key={key}>
                            <Typography.Text>{">"} {name}</Typography.Text>
                        </React.Fragment>
                )
            })}
        </>
    )
}

/** 입찰 사용 여부 등 on/off 를 표시하는 JSX를 리턴한다. */
export const onOffFormatter = (text: string | boolean) => {
    if(typeof text === "string") {
        return <div className={text.toUpperCase() === "ON" ? "txt-state on" : "txt-state off"}><i className="ico dot"></i><span>{text.toUpperCase()}</span></div>
    } else {
        return <div className={text ? "txt-state on" : "txt-state off"}><i className="ico dot"></i><span>{text ? "ON" : "OFF"}</span></div>
    }
}
/************************************ Nbidon 전용 포맷 모음 : End */
