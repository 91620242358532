import {KeywordBidManageGridProps} from "../../../../pages/kwdBid/kwdBidTypes";
import React, {useEffect, useState} from "react";
import {Button, Input, Modal, Segmented, Typography} from "antd";
import {RightCircleOutlined} from "@ant-design/icons";
import {errorAlert, successAlert} from "../../../../functions/alertFn";
import {nbsBidCostInputCheck} from "../../../../functions/validator";
import {nameTreeFormatter} from "../../../../functions/nbsFormatter";
import apiCall from "../../../../functions/apiCall";

interface ManualBidModalProps {
    selectRow: KeywordBidManageGridProps,
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setGridRefresh: React.Dispatch<React.SetStateAction<boolean>>
}
const ManualBidModal = ({selectRow, isOpen, setOpen, setGridRefresh}: ManualBidModalProps) => {
    const [ manualBidConfig, setManualBidConfig ] = useState<{manualBidYn: number, bidKwdId: string | number, dirBidCost: string | number}>({
        manualBidYn: selectRow.directBidYn ? 1 : 0, bidKwdId : selectRow.bidKwdId,  dirBidCost : selectRow.directBidCost
    })
    const regManualBidEvent = () => {
        if(nbsBidCostInputCheck(manualBidConfig.dirBidCost)){
            apiCall.post("/keywordBid/bidKwd/bidDirect", manualBidConfig)
                .then(resp => {
                    let comment = (manualBidConfig.manualBidYn === 1) ? "수동 입찰에 성공했습니다." : "수동 입찰을 종료했습니다.";
                    successAlert(comment);
                    setOpen(false);
                    setGridRefresh(true);
                })
                .catch()
                .finally()
        } else {
            errorAlert("입찰가는 70 이상 100,000 이하, 10 단위의 숫자로 입력해주세요.");
        }
    }
    useEffect(() => {
    }, [])
    return (
        <>
            <Modal
                title="수동입찰 설정"
                open={isOpen}
                onCancel={() => {setOpen(false); }}
                afterClose={() => { setOpen(false); }}
                width={1200}
                footer={[
                    <Button type="primary" className="gray" size="large" key={"manualModalCloseBtn"} onClick={() => setOpen(false)}>취소</Button>,
                    <Button type="primary" className="pink" size="large" key={"manualModalRegBtn"} onClick={regManualBidEvent}>저장</Button>,
                ]}
            >
                {/* <!-- Wrap-Help : Start --> */}
                <section className="wrap-section wrap-help">
                    <ul className="box-body">
                        <li className="item-help">
                            <RightCircleOutlined rev={undefined}/>
                            <span className="fz-14 fc-gray-300">수동 입찰 [ON] 설정 후 저장 시, 기존 자동 입찰이 OFF되며 설정값으로 입찰가가 고정됩니다.</span>
                        </li>
                        <li className="item-help">
                            <RightCircleOutlined rev={undefined}/>
                            <span className="fz-14 fc-gray-300">수동 입찰 [OFF] 설정 후 저장 시, 설정된 수동 입찰가는 OFF되며 기존 설정된 자동 입찰이 ON으로 변경됩니다. </span>
                        </li>
                    </ul>
                </section>
                {/* <!-- Wrap-Help : End --> */}

                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">키워드</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Typography.Text className="fz-15 fw-exbold fc-gray-500">{selectRow.kwdName}</Typography.Text>
                                    </div>
                                    <div className="form-group">
                                        {nameTreeFormatter([selectRow.advName, selectRow.campName, selectRow.groupName])}
                                    </div>
                                </dd>
                            </dl>

                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">수동입찰 사용</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Segmented
                                            value={manualBidConfig.manualBidYn}
                                            defaultValue={manualBidConfig.manualBidYn}
                                            onChange={(manualBidYn) => {
                                                if(typeof manualBidYn === "number") {
                                                    setManualBidConfig({...manualBidConfig, manualBidYn})
                                                }
                                            }}
                                            // 옵션에서 true false가 안먹힌다...
                                            options={[{label: 'ON', value: 1},{label: 'OFF',value: 0}]}
                                        >
                                        </Segmented>
                                    </div>
                                </dd>
                            </dl>

                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">입찰가</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Input className="w-300"
                                               value={manualBidConfig.dirBidCost}
                                               onChange={(e: React.ChangeEvent<HTMLInputElement>)=>
                                                   setManualBidConfig({...manualBidConfig, bidKwdId: selectRow.bidKwdId, dirBidCost: e.target.value})}
                                               suffix="원"/>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}
            </Modal>
        </>
    )
};

export default ManualBidModal;