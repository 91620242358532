import React, {useEffect, useState} from "react";

import {Button, Card, DatePicker, Flex, Form, Select, Space, Table, Typography,} from "antd";
import SkeletonTable, {SkeletonTableColumnsType} from "../../components/table/skeletonTable";
import {ColumnsType} from "antd/es/table";
import dayjs from "dayjs";


// Import - DatePicker - Range
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import {ProdChargeManageGridProps, ProdChargeManageSearchProps} from "./adminTypes";
import ContentHeader from "../../components/layout/contentHeader";
import {paginationOptions, prettyNumber} from "../../functions/tableFormatter";
import ProdChargeModal from "../../components/admin/productChargeManage/productChargeModal";
import apiCall from "../../functions/apiCall";
import {errorAlert} from "../../functions/alertFn";

// Typography
const { Title, Text } = Typography;
// DatePicker - Range
const { RangePicker } = DatePicker;
const { Option } = Select;

const ProductChargeManage = () => {

    const defaultEndDate = dayjs();
    const defaultStartDate = defaultEndDate.add(-30, 'd');
    /** 조회 필터 */
    const [ searchParams, setSearchParams] = useState<ProdChargeManageSearchProps>(
        {memberId: '', startDate : defaultStartDate.format('YYYYMMDD'), endDate: defaultEndDate.format('YYYYMMDD')});
    const [ userList, setUserList ] = useState<{memberId: string}[]>([]);

    /** 그리드 */
    const [ gridLoading, setGridLoading ] = useState<boolean>(true);
    const [ gridRefresh, setGridRefresh ] = useState<boolean>(false);
    const [ rows, setRows ] = useState<ProdChargeManageGridProps[]>([]);

    /** 다이얼로그 */
    const [ prodChargeModalOpen, setProdChargeModalOpen ] = useState<boolean>(false);
    const pageInit = () => {
        setGridLoading(true);
        apiCall.post("/item/charge/search", searchParams)
            .then(resp => {
                setRows(resp.data);
                setGridLoading(false);
            })
            .catch(error => {
                errorAlert("상품 충전 내역 데이터가 정상적으로 로드 되지 않습니다.<br />관리자에게 문의바랍니다.", {error});
            }).finally(() => {});

    }

    const findListUsers = () => {
        //조회 필터의 사용자 목록 로드
        // then에서 데이터를 가공하던가 바로 리턴 처리 하는 형태로 하자.
        apiCall.get("/member/mng/list", {})
            .then(resp => {
                setUserList(resp.data);
            })
            .catch(error => {
                //재로딩 해야 하는 케이스가 아래 케이스 하나밖에 없음.
                errorAlert("상품 충전 내역 데이터가 정상적으로 로드 되지 않습니다.<br />관리자에게 문의바랍니다.", {error});
            }).finally(() => {});
    }
    //최초 페이지 진입
    useEffect(() => {
        findListUsers(); // 검색 필터 사용자 로드
        pageInit(); // 그리드 데이터 로드
        document.title = "상품 충전 관리";
    }, []);

    //화면 리프래시
    useEffect(() => {
        if(gridRefresh) {
            pageInit();
            setGridRefresh(false);
        }
        else return
    }, [gridRefresh])

    const gridColumns : ColumnsType<any> = [
        {title: '사용자', dataIndex: 'memberId', align: 'center', render: (memberId: string, record) => `${memberId} (${record.memberName})`},
        {title: '충전 상품 정보', dataIndex: ['chargeProdInfo','productId'], align: 'center', render: (product: any, record: ProdChargeManageGridProps) =>{
                const procData = record.chargeProdInfo === undefined ? [] : record.chargeProdInfo;
                return (
                    <Flex justify="center" align="center" gap={8}>
                        {procData.map((item, index)=> {
                            const productNameKey = record.memberId + "_" + item.productId + "_Name_" + item.bidItemChargeId;
                            const productCountKey = record.memberId + "_" + item.productId + "_Count_" + item.bidItemChargeId;
                            return (
                                <React.Fragment key={record.memberId + "_" + item.productId + "_" + item.bidItemChargeId}>
                                    <Text className="fc-gray-500" key={productNameKey}>{item.productName}</Text>
                                    <Text className="fc-pink-500" key={productCountKey}>{prettyNumber(item.productCount)}</Text>
                                </React.Fragment>
                            )
                        })}
                    </Flex>
                )
            }
        },
        {title: '사용 기간', dataIndex: 'useRange', align: 'center', render: (_, record: ProdChargeManageGridProps) =>
                <span>{record.useStartDate + " ~ " + record.useEndDate}</span>
        },
        {title: '상태', dataIndex: 'bidItemChargeStatus', align: 'center', render: (text: string) =>
                <span>{text === "USE_READY" ? "사용 예정" : text === "USE_ING" ? "사용 중" : "사용 완료"}</span> },
        {title: '충전 날짜', dataIndex: 'chargeTime', align: 'center'},
    ]


    return (
        <>
            <ContentHeader pageTitle={"상품 충전 관리"} navigation={["관리자", "상품 충전 관리"]}/>

            {/* <!-- Wrap-Filter : Start --> */}
            <section className="wrap-section wrap-filter">
                {/*<div className="box-header">
                    <div className="box-left">
                        <Title level={3} className="fc-gray-700">조회 필터</Title>
                    </div>
                </div>*/}
                <div className="box-body">
                    <Card>
                        <Flex gap={24}>
                            <div className="box-left">
                                <i className="ico ico-filter"></i>
                            </div>
                            <div className="box-center">
                                <Form>
                                    <Space size={[32, 24]} wrap>
                                        <Form.Item label="사용자" colon={false} rules={[{ required: true, message: '사용자를 선택하세요.' }]}>
                                            <Select
                                                defaultValue=""
                                                style={{ width: 250 }}
                                                placeholder={'사용자ID를 선택하세요.'}
                                                getPopupContainer = {triggerNode => triggerNode.parentElement}
                                                onChange={(memberId: string) => {
                                                    setSearchParams({ memberId : memberId, startDate : searchParams.startDate, endDate : searchParams.endDate});
                                                }}>
                                                <Option key="" value="">전체</Option>
                                                {userList.map((item) => {
                                                    return <Option key={item.memberId} value={item.memberId}>{item.memberId}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="충전 날짜" colon={false} rules={[{ required: true, message: '충전날짜를 선택하세요.' }]}>
                                            <RangePicker
                                                locale={locale}
                                                defaultValue={[defaultStartDate, defaultEndDate]}
                                                onChange={(dates: any) => {
                                                    setSearchParams({ ...searchParams, startDate : dates[0].format('YYYYMMDD'), endDate : dates[1].format('YYYYMMDD')})
                                                }}/>
                                        </Form.Item>
                                    </Space>
                                </Form>
                            </div>
                            <div className="box-right">
                                <Button type="primary" className="pink" onClick={pageInit}>조회하기</Button>
                            </div>
                        </Flex>
                    </Card>
                </div>
            </section>
            {/* <!-- Wrap-Filter : End --> */}

            {/* <!-- Wrap-Datagrid : Start --> */}
            <section className="wrap-section wrap-datagrid">
                <div className="box-header">
                    <div className="box-left">
                        <Title level={3} className="fc-gray-700">사용자별 상품 충전 내역</Title>
                    </div>
                    <div className="box-right">
                        <Button type="primary" className="pink" onClick={() => setProdChargeModalOpen(true)}>상품 충전</Button>
                    </div>
                </div>
                <div className="box-body">
                    <SkeletonTable loading={gridLoading} columns={gridColumns as SkeletonTableColumnsType[]}>
                        <Table
                            key={"productGrid"}
                            rowKey={"bidItemChargeId"}
                            columns={gridColumns}
                            bordered
                            dataSource={rows}
                            pagination={paginationOptions(rows)}
                        />
                    </SkeletonTable>
                </div>
            </section>
            {/* <!-- Wrap-Datagrid : End --> */}

            {/* 상품 충전 모달 */}
            { prodChargeModalOpen &&
                <ProdChargeModal
                    record={userList}
                    isOpen={prodChargeModalOpen}
                    setOpen={setProdChargeModalOpen}
                    setGridRefresh={setGridRefresh}
                />
            }
        </>
    );
}

export default ProductChargeManage;