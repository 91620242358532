import {allTimeConfigListType, timeConfigListType} from "../components/bid/scheduleModalTimeConfig";
import {autoBidInfoDto} from "../pages/bid/bidSetting";
import {BidScheduleStateConfigProps} from "../pages/kwdBid/kwdBidTypes";

/** Nbidon 입찰 스케줄 기능 : Start **/

/** 스케줄러 헤더 생성용 */
export const scheduleCubeHeader = () => {
    return {
        date : ["월","화","수","목","금","토","일"],
        time : ["00", "01", "02", "03", "04","05","06","07", "08", "09", "10",
                "11", "12", "13", "14","15","16","17", "18", "19", "20", "21", "22", "23"]
    }
}
//스케줄러용 박스 생성용 리스트( 아이디 값으로도 사용된다. )
const dateList = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

/* 스케줄러용 박스 생성.(화면에서 일별(Flex) 단위로 나눠야 하는 경우 true / Flex 사용하지 않을 경우 false) */
export const makeScheduleCubes = (dateArrayYn: boolean) => {
    if(dateArrayYn) {
        let cubeArray: string[][] = [];
        let dateCubeArray: string[] = [];
        dateList.forEach((date)=> {
            for(let time = 0; time < 24; time++){
                dateCubeArray.push(date+"_"+time);
            }
            cubeArray.push(dateCubeArray);
            dateCubeArray = []; // 넣었으니 초기화;
        })
        return cubeArray;
    } else {
        let cubeArray: string[] = [];
        dateList.forEach((date)=> {
            for(let time = 0; time < 24; time++){
                cubeArray.push(date+"_"+time);
            }
        })
        return cubeArray;
    }
}

/* 적용되어 있는 스케줄러의 데이터를 기준으로 각 조건별 색상 입히기 */
export const paintScheduleCubes = (scheduleDetConfigs: BidScheduleStateConfigProps[]) => {
    function checkDrawDateSchedule(cubePrefix: string, schedule: string[], selectedClassName: string) {

        schedule.map((time, index) => {
            if(time === "1") {
                const cubeId = `${cubePrefix}_${index}_column`;
                let cubeColumn = document.getElementById(cubeId);
                if(cubeColumn !== undefined && cubeColumn !== null){
                    cubeColumn.classList.add(selectedClassName);
                }
            }
        })
    }

    scheduleDetConfigs.map((config:BidScheduleStateConfigProps)=> {
        const selectedClassName = `selected-${config.color.toLowerCase()}`;
        checkDrawDateSchedule("mon", config.monSchedule.split(""), selectedClassName);
        checkDrawDateSchedule("tue", config.tueSchedule.split(""), selectedClassName);
        checkDrawDateSchedule("wed", config.wedSchedule.split(""), selectedClassName);
        checkDrawDateSchedule("thu", config.thuSchedule.split(""), selectedClassName);
        checkDrawDateSchedule("fri", config.friSchedule.split(""), selectedClassName);
        checkDrawDateSchedule("sat", config.satSchedule.split(""), selectedClassName);
        checkDrawDateSchedule("sun", config.sunSchedule.split(""), selectedClassName);
    })
}



/** Nbidon 입찰 스케줄 기능 : Start **/





/** Nbidon 1.0 입찰 스케줄 관련 기능 모음 : Start **/

/* 시작시간 및 종료시간 옵션 설정용 배열(Nbidon 1.0에 쓰던거다. 2.0에서는 안씀) */
export const timeOptionList = [
    {value: '00', label: '00'}, {value: '01', label: '01'}, {value: '02', label: '02'},
    {value: '03', label: '03'}, {value: '04', label: '04'}, {value: '05', label: '05'},
    {value: '06', label: '06'}, {value: '07', label: '07'}, {value: '08', label: '08'},
    {value: '09', label: '09'}, {value: '10', label: '10'}, {value: '11', label: '11'},
    {value: '12', label: '12'}, {value: '13', label: '13'}, {value: '14', label: '14'},
    {value: '15', label: '15'}, {value: '16', label: '16'}, {value: '17', label: '17'},
    {value: '18', label: '18'}, {value: '19', label: '19'}, {value: '20', label: '20'},
    {value: '21', label: '21'}, {value: '22', label: '22'}, {value: '23', label: '23'}
];
/* 목표 설정 시 옵션. 특정 순위 / 범위 순위로 나뉜다.(Nbidon 1.0에 쓰던거다. 2.0에서는 안씀) */
export const hopeRankOptionList = (type: string) => {
    switch (type){
        case "FIX": return [
            {value: '1', label: '1위'}, {value: '2', label: '2위'}, {value: '3', label: '3위'},
            {value: '4', label: '4위'}, {value: '5', label: '5위'}, {value: '6', label: '6위'},
            {value: '7', label: '7위'}, {value: '8', label: '8위'}, {value: '9', label: '9위'},
            {value: '10', label: '10위'}, {value: '11', label: '11위'}, {value: '12', label: '12위'},
            {value: '13', label: '13위'}, {value: '14', label: '14위'}, {value: '15', label: '15위'}
        ]
        case "RANGE": return [
            {value: '1~2', label: '1~2위'},{value: '1~3', label: '1~3위'},
            {value: '1~4', label: '1~4위'},{value: '1~5', label: '1~5위'},
            {value: '2~3', label: '2~3위'},{value: '2~4', label: '2~4위'},{value: '2~5', label: '2~5위'},
            {value: '3~4', label: '3~4위'},{value: '3~5', label: '3~5위'},
            {value: '4~5', label: '4~5위'}
        ]
        default: return [];
    }
};
/* 요일 설정의 MON -> 시간 설정의 키 값으로 치환하여 전달(Nbidon 1.0에 쓰던거다. 2.0에서는 안씀) */
export const getCurrentDateConfigKey = (value: string | number) => {
    switch(value) {
        case "MON": return "bidScheduleMon";
        case "TUE": return "bidScheduleTue";
        case "WED": return "bidScheduleWed";
        case "THU": return "bidScheduleThu";
        case "FRI": return "bidScheduleFri";
        case "SAT": return "bidScheduleSat";
        case "SUN": return "bidScheduleSun";
        default: return "bidScheduleMon";
    }
}
/* 해당 키워드의 전체 스케줄을 컨버팅 해주고 전달(Nbidon 1.0에 쓰던거다. 2.0에서는 안씀) */
export const convertAllTimeConfigListData = (record: autoBidInfoDto) => {
    const allTimeConfigArray: allTimeConfigListType[] = [];

    //데이터 파싱하고
    const jsonBidSchedules = JSON.parse(record.bidSchedules);
    const keys = Object.keys(jsonBidSchedules);
    for(let index in keys) {
        let timeStrgArray: timeConfigListType[] = [];
        const dateKey = keys[index]; // 서버 상에 bidScheduleMon.. 형식으로 들어감.
        const jsonTimeConfig = JSON.parse(jsonBidSchedules[dateKey]); // 일자별 시간 설정
        const timeConfigKeys = Object.keys(jsonTimeConfig);
        for(let index in timeConfigKeys){
            const timeConfigKey = timeConfigKeys[index];
            const splitTimeConfig = timeConfigKey.split(".."); // 00..18 => ["00", "19"];

            const startTime = splitTimeConfig[0];
            const endTime = splitTimeConfig[1];
            const hopeRank = jsonTimeConfig[timeConfigKey].hopeRank;
            const hopeRankType = hopeRank.includes("~") ? "RANGE" : "FIX";

            timeStrgArray.push({ startTime, endTime, hopeRankType, hopeRank});
        }
        allTimeConfigArray.push({key: dateKey, value: orderByTimeStrgList(timeStrgArray)});
    }
    return allTimeConfigArray;
}
/* 해당 키워드의 전체 스케줄 기준으로 요일설정에서 선택한 날짜의 시간설정을 컨버팅 해주고 전달(Nbidon 1.0에 쓰던거다. 2.0에서는 안씀) */
export const getCurrentTimeConfigData = (dateConfigValue: string | number, allTimeConfigList: allTimeConfigListType[]) => {
    const dateTimeConfig = allTimeConfigList.filter(dateTimeConfig => dateTimeConfig.key === getCurrentDateConfigKey(dateConfigValue));
    return dateTimeConfig[0].value;
}
/* 시간 설정 목록을 이쁘게 오더바이 쳐준다(Nbidon 1.0에 쓰던거다. 2.0에서는 안씀) */
export const orderByTimeStrgList = (timeStrg: {startTime: string, endTime: string, hopeRankType: string, hopeRank: string}[]) => {
    return timeStrg.sort(function(a, b){
        return parseInt(a.startTime) - parseInt(b.startTime);
    })
}
/* 시작시간 ~ 종료시간 사이의 모든 시간대를 배열 형태로 변환하여 리턴(Nbidon 1.0에 쓰던거다. 2.0에서는 안씀) */
export const makeTimeSpreadArray = (startTime: string, endTime: string) => {
    const integerStartTime = parseInt(startTime);
    const integerEndTime = parseInt(endTime);
    const timeArrayLength = integerEndTime - integerStartTime + 1;
    return [...Array.from({length:timeArrayLength},(_, i) => integerStartTime+i)];
}
/** Nbidon 1.0 입찰 스케줄 관련 기능 모음 : End **/



