import React, {useEffect, useState} from "react";
import {Button, Card, Flex, Form, Modal, Select, Space, Spin} from "antd";
import ReactApexChart from 'react-apexcharts';
import apiCall from "../../../functions/apiCall";
import {chartOptionsWithContext} from "../../common/chartType";
import {errorAlert} from "../../../functions/alertFn";
import {
    CompetitorRankDetailSearchProps,
    CompetitorRankHistorySearchProps
} from "../../../pages/dataMng/competitorsRankHistoryType";

interface CompetitorsRankDetailModalProps {
    searchParams: CompetitorRankHistorySearchProps,
    selectedKwds: { kwdName: string }[],
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

interface DetailRankRowProps {
    domain: string;
    hourlyRank: { [key: string]: number | null };
}

const CompetitorsRankDetailModal = ({searchParams, selectedKwds, isOpen, setOpen}: CompetitorsRankDetailModalProps) => {
    // 키워드 목록
    const [kwdNameList, setKwdNameList] = useState<{ kwdName: string }[]>([]);
    const [deviceList, setDeviceList] = useState<string[]>([]);
    const [params, setParams] = useState<CompetitorRankDetailSearchProps>(
        {advName: searchParams.advName, kwdName: '', device: '', startDate : searchParams.startDate, endDate: searchParams.endDate});

    // 광고영역
    let deviceType: { value: string | number | null, label: string}[] = [
        { value: 'PC_TOTAL', label: 'PC' },{ value: 'PC_DETAIL', label: 'PC 더보기' },{ value: 'MOBILE_TOTAL', label: 'MO' },{ value: 'MOBILE_DETAIL', label: 'MO 더보기' }
    ];

    // 차트 데이터
    const [chartLoading, setChartLoading] = useState<boolean>(true);
    const [chartContext, setChartContext] = useState<any>(undefined);
    const [chartSeries, setChartSeries] = useState<{ name: string, data: number[] }[]>([]);
    const [showYaxis, setShowYaxis] = useState(false); // 초기값을 false로 설정

    const chartOptions = chartOptionsWithContext(setChartContext, showYaxis);

    const searchEvent = () => {
        setChartLoading(false);

        if(params.kwdName !== '' && params.kwdName !== undefined && params.device !== '' && params.device !== undefined) {
            setChartLoading(true);
            setShowYaxis(chartSeries.length > 0);

            apiCall.get("/data/competitors/rank/detail", {params})
                .then(resp => {
                    // 차트용 데이터 가공
                    const formedData = resp.data.map((item: DetailRankRowProps) => ({
                        name: item.domain,
                        data: convertHourlyRankValuesToArray(item.hourlyRank), // 배열로 변환
                    }));
                    setChartSeries(formedData); // 차트에 사용할 변환 데이터
                    setShowYaxis(resp.data.length > 0); // 응답 데이터에 따라 Y축 표시 여부 설정
                })
                .catch((error) => {console.error('데이터 로드 실패:', error);
                }).finally(() => {
                setChartLoading(false);
            });
        }
    }

    const convertHourlyRankValuesToArray = (hourlyRank: { [key: string]: number | null }): number[] => {
        return Object.keys(hourlyRank)
            .sort((a, b) => parseInt(a) - parseInt(b))
            .map(key => hourlyRank[key]!)
    };

    // 레전드 전체 비활성화 이벤트
    const allChartDataHideEvent = () => {
        if(chartContext === undefined) {
            errorAlert("오류가 발생했습니다.<br />관리자에게 문의바랍니다.");
            return false;
        }
        //업데이트 강제 중지(?)
        const update = chartContext.update;
        chartContext.update = () => { return Promise.resolve(); };

        //전체 가리기 실행
        const chart = ApexCharts.getChartByID('timeRankChart');
        chartSeries.map(v => chart?.hideSeries(v.name)); // hide OR show

        //업데이트 다시 실행
        chartContext.update = update;
        chartContext.update();
    }


    /* ============ 차트 옵션 =================== */


    useEffect(() => {
        // selectedKwds에서 kwdName을 배열로 변환하고 정렬
        if (selectedKwds.length > 0) {
            const sortedKwdNameList = [...selectedKwds] // 원본 배열 복사
                .sort((a, b) => a.kwdName.localeCompare(b.kwdName, 'en', { sensitivity: 'base' }))
                .map(row => ({ kwdName: row.kwdName })); // kwdName만 추출
            setKwdNameList(sortedKwdNameList); // 정렬된 리스트를 상태로 설정
        }

        if (searchParams.device && searchParams.device.length > 0) {
            setDeviceList(searchParams.device);
        }

        // searchParams가 변경될 때마다 searchEvent 호출
        setChartLoading(false);
        setShowYaxis(false);
        searchEvent();

    }, [params, selectedKwds]); // 의존성 배열에 params 추가

    return (
        <>
            <Modal
                title="경쟁사 순위 상세 조회"
                open={isOpen}
                width={1200}
                maskClosable={false} // 모달 외부 클릭 시 닫히지 않게 설정
                onCancel={() => setOpen(false)}
                footer={null}
            >
                <section className="wrap-section wrap-filter">
                    <div className="box-body">
                        <Card>
                            <Flex gap={24}>
                                <div className="box-left">
                                    <i className="ico ico-filter"></i>
                                </div>
                                <div className="box-center">
                                    <Form>
                                        <Space size={[32, 24]} wrap>
                                            <Form.Item
                                                label="키워드"
                                                colon={false}
                                                rules={[{required: true, message: '키워드를 선택하세요.'}]}
                                            >
                                                <Space>
                                                    <Select
                                                        style={{width: 250}}
                                                        placeholder={'키워드를 선택하세요.'}
                                                        value={params.kwdName || undefined}
                                                        onChange={(kwd:string)=> {setParams({...params, kwdName: kwd})}}
                                                    >
                                                        {kwdNameList.map((kwd: { kwdName: string }, index: number) => {
                                                            return <Select.Option key={`${kwd.kwdName}_${index}`} value={kwd.kwdName}>{kwd.kwdName}</Select.Option >
                                                        })}
                                                    </Select>
                                                </Space>
                                            </Form.Item>

                                            <Form.Item
                                                label="광고 영역"
                                                colon={false}
                                                rules={[{ required: true, message: '광고 영역을 선택하세요.' }]}
                                            >
                                                <Select
                                                    // defaultValue="ALL"
                                                    style={{ width: 250 }}
                                                    placeholder="광고영역을 선택하세요."
                                                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                                                    value={params.device || undefined}
                                                    onChange={(device:string)=> {setParams({...params, device: device})}}
                                                >
                                                    {/* searchParams.device 값이 있으면 필터링, 없으면 전체 옵션 보여주기 */}
                                                    {(deviceList && deviceList.length > 0
                                                            ? deviceType.filter((dt) =>
                                                                dt.value !== null && deviceList.includes(String(dt.value)) // null 값 제거 후 string으로 변환하여 필터링
                                                            )
                                                            : deviceType
                                                    ).map((device: { value: string | number | null, label: string }) => (
                                                        <Select.Option key={`${device.value}_CONTENT_SEARCH`} value={device.value}>
                                                            {device.label}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Space>
                                    </Form>
                                </div>
                            </Flex>
                        </Card>
                    </div>
                </section>
                {/* <!-- Wrap-Filter : End --> */}

                <section>
                    <div className="box-header">
                        <div className="box-right">
                            <Button className="pink" onClick={() => allChartDataHideEvent()}>
                                전체해제
                            </Button>
                        </div>
                    </div>
                </section>

                {/* <!-- Wrap-Chart : Start --> */}
                <section className="wrap-section wrap-chart">
                    {/* <!-- Wrap-ReactApexChart : Start --> */}
                    {chartLoading ?
                        <Spin tip="Loading" size="large">
                            <ReactApexChart
                                type="line"
                                options={chartOptions}
                                series={chartSeries}
                                height={500}
                            />
                        </Spin>
                        :
                        <ReactApexChart
                            type="line"
                            options={chartOptions}
                            series={chartSeries}
                            height={500}
                        />
                    }
                    {/* <!-- Wrap-ReactApexChart : End --> */}
                </section>
                {/* <!-- Wrap-Chart : End --> */}
            </Modal>

        </>
    )
}

export default CompetitorsRankDetailModal;