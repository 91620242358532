import {Button, Flex, Spin, Switch, Table, TableProps, Tag, Typography} from "antd";
import {KeywordBidManageGridProps, KeywordBidManageSearchProps} from "../../../pages/kwdBid/kwdBidTypes";
import {loadingEmptyTableLayout, prettyNumber} from "../../../functions/tableFormatter";
import React, {SetStateAction, useEffect, useState} from "react";
// Import - Icon
import {ExclamationCircleFilled, StarFilled} from "@ant-design/icons";
import ScheduleRegModal from "./modal/scheduleRegModal";
import {errorAlert, successAlert} from "../../../functions/alertFn";
import BidConfigModal from "./modal/bidConfigModal";
import ManualBidModal from "./modal/manualBidModal";
import ConfigHistoryModal from "./modal/configHistoryModal";
import modal from "antd/es/modal";
import BidHistoryModal from "./modal/bidHistoryModal";
import {
    bidStatusFormatter,
    bookmarkFormatter,
    nameTreeFormatter,
    onOffFormatter
} from "../../../functions/nbsFormatter";
import KeywordBidDownloadModal, {KeywordBidDownloadParams} from "./modal/keywordBidDownloadModal";
import apiCall from "../../../functions/apiCall";
import {ColumnsType} from "antd/es/table";
import dayjs from "dayjs";
import {SorterResult} from "antd/es/table/interface";
import RivalRegModal from "./modal/rivalRegModal";

const { Title } = Typography;

interface KeywordBidManageGridComponentProps {
    searchParams: KeywordBidManageSearchProps,
    setSearchParams: React.Dispatch<SetStateAction<KeywordBidManageSearchProps>>
    rows : KeywordBidManageGridProps[],
    totalCnt : number,
    gridLoading : boolean,
    setGridRefresh: React.Dispatch<React.SetStateAction<boolean>>
}
const KeywordBidManageGridComponent = ({searchParams, setSearchParams, rows, totalCnt, gridLoading, setGridRefresh}: KeywordBidManageGridComponentProps) => {
    /** 선택된 그리드 로우 */
    const [selectRow, setSelectRow ] = useState<KeywordBidManageGridProps>();
    const [selectedRows, setSelectedRows] = useState<KeywordBidManageGridProps[]>([]); // 삭제 시 사용
    /** 로딩 여부 */
    const [ downloadLoading, setDownloadLoading ] = useState<boolean>(false);

    const [kwdBidDownloadGridRefresh, setKwdBidDownloadGridRefresh] = useState<boolean>(false);

    const convertBidItemDiv = (data: KeywordBidManageGridProps) => {
        switch(data.bidItemDiv) {
            case "TARGET" : return "타겟"
            case "UNIQUE" : return "유니크"
            case "PREMIUM" : return "프리미엄"
            case "BASIC" : return "베이직"
            default : return "-"
        }
    }
    /** 그리드 컬럼 */
    const gridColumns: ColumnsType<KeywordBidManageGridProps> = [
        {title: '북마크', dataIndex: 'bookmarkDiv', align: 'center',
            render: (bookmarkDiv: string, record) => {
                const { colorClassName, key } = bookmarkFormatter(bookmarkDiv, record.kwdId);
                return <StarFilled rev={undefined} key={key} className={colorClassName} />;
            },
            sorter: true,
        },
        {title: '키워드', dataIndex: 'kwdName', align: 'center', width: '23%',
            render: (kwdName: string, record) =>
                <div className="box-left">
                    <div className="box-top">
                        <Typography.Text className="fz-15 fw-exbold fc-gray-500">{kwdName}</Typography.Text>
                    </div>
                    <div className="box-bottom">
                        {nameTreeFormatter([record.advName, record.campName, record.groupName])}
                    </div>
                </div>,
            sorter: true,
        },
        {title: '광고 영역', dataIndex: 'adAreaDiv', align: 'center',
            sorter: true,
        },
        {title: '광고 상태', dataIndex: 'adConfigYn', align: 'center',
            render: (adConfigYn: boolean) => onOffFormatter(adConfigYn),
            sorter: false,
        },
        {title: '입찰 사용', dataIndex: 'bidConfigYn', align: 'center',
            render: (bidConfigYn: boolean, record) =>
                <Switch size="small" checked={bidConfigYn}
                        onChange={(checked, event: React.MouseEvent<HTMLButtonElement>) => changeBidConfig(event, record)} />,
            sorter: true,
        },
        {title: '상품', dataIndex: 'bidItemDiv', align: 'center',
            render: (bidItemDiv: string, record) => convertBidItemDiv(record),
            sorter: true,
        },
        {title: '현재 순위', dataIndex: 'nowRank', align: 'center',
            render:(nowRank: number, record)=>
                <>
                    {nowRank === 0 ? "-" : `${nowRank}/${record.bidSlog}`}
                </>,
            sorter: false,
        },
        {title: '목표 순위', dataIndex: 'hopeRank', align: 'center',
            sorter: true,
        },
        {title: '현재 입찰가', dataIndex: 'nowBidCost', align: 'center',
            render: (nowBidCost: number) => nowBidCost === 0 ? "-" : nowBidCost,
            sorter: false,

        },
        {title: '최대 입찰가', dataIndex: 'maxBidCost', align: 'center',
            sorter: true,
        },
        {title: '입찰 상태', dataIndex: 'bidStatus', align: 'center',
            render: (bidStatus: string, record) => {
                const { color, key, desc } = bidStatusFormatter(bidStatus, record.kwdId);
                return <Tag color={color} key={key}>{desc}</Tag>
            },
            sorter: false,
        },
        {title: '스케줄', dataIndex: 'scheduleUseYn', align: 'center',
            render: (text: number | undefined, record) => drawScheduleButton(text, record),
            sorter: true,
        },
        {title: '경쟁사', dataIndex: 'rivalDomain', align: 'center',
            render: (undefined, record) => drawRivalButton(record),
            sorter: true,
        },
        {title: '수동 입찰', dataIndex: 'manualBidButton', align: 'center',
            render: (text: string,record) => <Button className="pink" size="small" onClick={()=> {
                setSelectRow(record);
                setManualBidModalYn(true);
            }}>수동</Button>,
        },
        {title: '이력', dataIndex: 'historyButton', align: 'center',
            render: (text: string, record) =>
                <Flex justify="center">
                    <Button className="pink" size="small" onClick={()=> {
                        setSelectRow(record);
                        setBidHistoryModalYn(true);
                    }}>로그</Button>
                    <Button className="pink" size="small" onClick={()=> {
                        setSelectRow(record);
                        setConfigHistoryModalYn(true);
                    }}>변경</Button>
                </Flex>
        },
    ]
    /** 모달 플래그 모음 */
    const [ bidConfigModalYn, setBidConfigModalYn ]  = useState<boolean>(false);
    const [ scheduleRegModalYn, setScheduleRegModalYn ] = useState<boolean>(false);
    const [ rivalRegModalYn, setRivalRegModalYn ] = useState<boolean>(false);
    const [ manualBidModalYn, setManualBidModalYn ] = useState<boolean>(false);
    const [ bidHistoryModalYn, setBidHistoryModalYn ] = useState<boolean>(false);
    const [ configHistoryModalYn, setConfigHistoryModalYn ] = useState<boolean>(false);
    //다운로드의 경우 항목에 따라 후처리해야해서 이 컴포넌트에서 처리한다. ( 여기서 처리 안할꺼면 그냥 옮겨도 된다. )
    const [ keywordBidDownloadParams, setKeywordBidDownloadParams] = useState<KeywordBidDownloadParams>(
        {openYn: false, downloadType:"KEYWORD_BID_LIST", downloadDate: dayjs().subtract(1, "day").format("YYYYMMDD"), downloadYn: false}
    );

    /** 로우 선택 */
    const rowSelection = { selectedRows, onChange: (newSelectedRowKeys: React.Key[]) => {
            const selectRows = rows.filter((row)=>newSelectedRowKeys.includes(row.kwdId))
            setSelectedRows(selectRows);
        }};

    /** 스케쥴 사용 여부 체크하여 버튼 변환. 일단 데이터 있으면 그냥 있따고 가정했다..*/
    const drawScheduleButton = (scheduleId:number | undefined, record: KeywordBidManageGridProps) => {
        const keyId = record.kwdId + "_" + (record.scheduleId === null || record.scheduleId === undefined ? "" : record.scheduleId + "_button");
        if(record.scheduleId  === undefined) {
            return <Flex justify="center">
                <Tag color={"default"} key={keyId}>{"미사용"}</Tag>
                <Button type="primary" className="pink" size="small" onClick={() => {
                    setSelectRow(record);
                    setScheduleRegModalYn(true);
                }}>추가</Button>
            </Flex>;
        }
        else if (record.scheduleId  === null) {
            return <Flex justify="center">
                <Tag color={"default"} key={keyId}>{"미사용"}</Tag>
                <Button type="primary" className="pink" size="small" onClick={() => {
                    setSelectRow(record);
                    setScheduleRegModalYn(true);
                }}>추가</Button>
            </Flex>;
        } else {
            return <Flex justify="center">
                <Tag color={"blue"} key={keyId}>{"사용중"}</Tag>
                <Button className="pink" size="small" onClick={()=> {
                    setSelectRow(record);
                    setScheduleRegModalYn(true);
                }}>변경</Button>
            </Flex>;
        }
    }

    /** 경쟁사.. */
    const drawRivalButton = (record: KeywordBidManageGridProps) => {
        const keyId = record.kwdId + "_button";
        const offYn = record.rivalConfigDiv === "OFF";
        return <Flex justify="center">
            <Tag color={offYn ? "default" : "blue"} key={keyId}>{offYn ? "미사용" : "사용중"}</Tag>
            <Button type="primary" className="pink" size="small" onClick={() => {
                setSelectRow(record);
                setRivalRegModalYn(true);
            }}>설정</Button>
        </Flex>;
    }

    /** 입찰 사용 여부 변경 이벤트 **/
    const changeBidConfig = (event: React.MouseEvent<HTMLButtonElement>, record: KeywordBidManageGridProps) => {
        const msg = record.bidConfigYn ? "입찰 사용을 OFF로 변경하시겠습니까 ?" : "입찰 사용을 ON으로 변경하시겠습니까 ?";
        modal.confirm({
            icon: <ExclamationCircleFilled rev={undefined} />,
            title: msg,
            okText: '확인', cancelText: '취소',
            okButtonProps: { className: "pink ant-btn-lg" },
            onOk() {
                apiCall.post("/keywordBid/bidKwd/updateOnlyBidConfig", {bidKwdId : record.bidKwdId})
                .then(() => {
                    successAlert("입찰 사용이 변경되었습니다.");
                    setSelectedRows([])
                })
                .catch(error => {
                    if (error.response.data.message !== undefined) {
                        errorAlert(error.response.data.message, {error});
                    } else {
                        errorAlert("입찰 사용 변경이 실패되었습니다.");
                    }
                })
                .finally(() => setGridRefresh(true));
            },
            cancelButtonProps: { type: 'primary', className: "gray ant-btn-lg" },
        })
    }
    /** 입찰설정 변경 모달 오픈 이벤트 */
    const openBidConfigModal = () => {
        if(selectedRows.length === 0) {
            errorAlert("키워드를 선택해주세요.");
            return false;
        } else {
            setBidConfigModalYn(true);
        }
    }

    /** 키워드 삭제 이벤트 */
    const deleteKeywords = () => {
        if(selectedRows.length === 0) {
            errorAlert("키워드를 선택해주세요.");
            return false;
        } else {
            modal.confirm({
                icon: <ExclamationCircleFilled rev={undefined} />,
                title: '선택한 키워드를 삭제하시겠습니까?',
                content: '삭제한 키워드는 Nbidon에서 더이상 확인할 수 없습니다.',
                okText: '확인', cancelText: '취소',
                okButtonProps: { className: "pink ant-btn-lg" },
                cancelButtonProps: { type: 'primary', className: "gray ant-btn-lg" },
                onOk () {
                    apiCall.post("/keywordBid/bidKwd/deleteBidKwdList", selectedRows.map(value => value.bidKwdId))
                    .then(() => {
                        successAlert("선택한 키워드가 삭제되었습니다.");
                        setSelectedRows([])
                    })
                    .catch(() => errorAlert("키워드 삭제가 실패되었습니다."))
                    .finally(() => setGridRefresh(true));
                }
            })
        }
    }

    /** 테이블 변경(페이지/소팅) 이벤트 */
    const gridChange: TableProps<KeywordBidManageGridProps>['onChange'] =
        (pagination, filters, sorter) => {
            const sort = (sorter as SorterResult<KeywordBidManageGridProps>);
            setSearchParams({...searchParams,
                pageNumber: pagination.current,
                pageSize: pagination.pageSize,
                sortColumn: sort.field as string,
                sortOrder: sort.order as string,
                searchEventYn: true
            })
        }
    /** 다운로드 이벤트 체크 */
    useEffect(() => {
        if(keywordBidDownloadParams.downloadYn) {
            let params = {...searchParams,
                advId : searchParams.advId === 0 ? undefined : searchParams.advId,
                campId : searchParams.campId === 'ALL' ? undefined : searchParams.campId,
                groupId : searchParams.groupId === 'ALL' ? undefined : searchParams.groupId,
                bookmarkDiv : searchParams.bookmarkDiv === 'ALL' ? undefined : searchParams.bookmarkDiv,
                bidConfigYn: searchParams.bidConfigYn === 'ALL' ? undefined : searchParams.bidConfigYn,
                bidItemDiv : searchParams.bidItemDiv === 'ALL' ? undefined : searchParams.bidItemDiv,
                adAreaDiv : searchParams.adAreaDiv === 'ALL' ? undefined : searchParams.adAreaDiv,
                scheduleId : searchParams.scheduleId === 0 ? undefined : searchParams.scheduleId,
                pageNumber : searchParams.pageNumber === undefined ? 1 : searchParams.pageNumber,
                pageSize : searchParams.pageSize === undefined ? 10 : searchParams.pageSize,
                sortColumn : searchParams.sortColumn === undefined ? "kwdName" : searchParams.sortColumn,
                sortOrder : searchParams.sortOrder === undefined ? "ascend" : searchParams.sortOrder,
            };

            let url = keywordBidDownloadParams.downloadType === "KEYWORD_BID_LIST" ? "/keywordBid/bidKwd/downloadBidKwdList" : "/keywordBid/bidKwd/downloadBidKwdHistory";
            let bidKwdIds = selectedRows.length !== 0 ? selectedRows.map(value => value.bidKwdId) : rows.map(value => value.bidKwdId);
            let param = keywordBidDownloadParams.downloadType === "KEYWORD_BID_LIST" ? { ...params  } : { bidKwdIds: bidKwdIds, basicDate: keywordBidDownloadParams.downloadDate };

            //다운로드 요청 시 네트워크 시간이 오래 걸려서 로딩 바 추가
            setDownloadLoading(true)
            apiCall.post(url, param)
            .then(() => {
                //요청이 끝나면 로딩 바 해제
                setDownloadLoading(false)
                setKwdBidDownloadGridRefresh(true)
            })
            .catch(() => {
                errorAlert("결과 파일 다운로드 도중 에러가 발생했습니다.<br />관리자에게 문의해주세요.");
            });

            // 마지막에는 초기화
            setKeywordBidDownloadParams({...keywordBidDownloadParams, downloadType: "KEYWORD_BID_LIST", downloadYn: false});
        }
    }, [keywordBidDownloadParams])


    return (
        <>
            {/* <!-- Wrap-Datagrid : Start --> */}
            <section className="wrap-section wrap-datagrid">
                <div className="box-header">

                    <div className="box-left">
                        <Title level={3} className="fc-gray-700">키워드 입찰 목록 {!gridLoading && "( " + prettyNumber(totalCnt) + " 개 )"}</Title>
                        <span className="fz-15 fc-pink-500">* 키워드 입찰 목록은 실시간이 아닌 입찰 로그 이력 내 가장 최신 데이터 기준으로 표기됩니다.</span>
                    </div>
                    <div className="box-right">
                    <Button className="pink" onClick={()=> window.location.href="/kwdBid/keywordBidBulkManage"} >키워드 등록</Button>
                        <Button className="pink" onClick={openBidConfigModal}>입찰설정 변경</Button>
                        <Button className="gray" onClick={deleteKeywords}>키워드 삭제</Button>
                        <Button className="pink" onClick={() => {
                            //오픈할때마다 초기화
                            setKeywordBidDownloadParams(
                                {openYn: true, downloadType:"KEYWORD_BID_LIST", downloadDate: dayjs().subtract(1, "day").format("YYYYMMDD"), downloadYn: false}
                            )
                        }}><i className="ico ico-download" ></i>다운로드</Button>
                    </div>
                </div>
                <div className="box-body">
                    <Table
                        key={"keywordBidGrid"}
                        rowKey={record => record.kwdId}
                        rowSelection={rowSelection}
                        columns={gridColumns}
                        dataSource={rows}
                        onChange={gridChange}
                        showSorterTooltip={false}
                        locale={loadingEmptyTableLayout(gridLoading, ["등록된 키워드가 없습니다.", "[키워드 등록] 버튼을 클릭하여 입찰 키워드를 등록하세요."])}
                        bordered
                        scroll={{ x: 1700 }}
                        pagination={{
                            total: totalCnt,
                            showTotal : (total: number) => `Total ${total} items`,
                            showSizeChanger: true, // true로 설정 안하면 50부터 나타남.
                            current : searchParams.pageNumber,
                            pageSize : searchParams.pageSize
                        }}
                    />
                </div>
            </section>
            {/* <!-- Wrap-Datagrid : End --> */}

            {/* <!-- 다운로드 시 로딩 표시 --> */}
            {downloadLoading && (
                <div className="fullscreen-loader">
                    <Spin size="large" tip="Loading">Loading</Spin>
                </div>
            )}

            {/* <!-- 입찰설정 변경 모달 --> */}
            {bidConfigModalYn &&
                <BidConfigModal selectedRows={selectedRows} isOpen={bidConfigModalYn} setOpen={setBidConfigModalYn} setGridRefresh={setGridRefresh} />
            }
            {/* <!-- 스케쥴 등록 및 변경 모달 --> */}
            {scheduleRegModalYn && selectRow !== undefined &&
                <ScheduleRegModal selectRow={selectRow} isOpen={scheduleRegModalYn} setOpen={setScheduleRegModalYn} setGridRefresh={setGridRefresh} />
            }
            {/* <!-- 경쟁사 등록 및 변경 모달 --> */}
            {rivalRegModalYn && selectRow !== undefined &&
                <RivalRegModal selectRow={selectRow} isOpen={rivalRegModalYn} setOpen={setRivalRegModalYn} setGridRefresh={setGridRefresh} />
            }
            {/* <!-- 수동 입찰 모달 --> */}
            {manualBidModalYn && selectRow !== undefined &&
                <ManualBidModal selectRow={selectRow} isOpen={manualBidModalYn} setOpen={setManualBidModalYn} setGridRefresh={setGridRefresh} />
            }
            {/* <!-- 입찰 이력 모달 --> */}
            {bidHistoryModalYn && selectRow !== undefined &&
                <BidHistoryModal selectRow={selectRow} isOpen={bidHistoryModalYn} setOpen={setBidHistoryModalYn} />
            }
            {/* <!-- 설정 이력 모달 --> */}
            {configHistoryModalYn && selectRow !== undefined &&
                <ConfigHistoryModal selectRow={selectRow} isOpen={configHistoryModalYn} setOpen={setConfigHistoryModalYn} />
            }
            {/* <!-- 다운로드 모달 --> */}
            {keywordBidDownloadParams.openYn &&
                <KeywordBidDownloadModal params={keywordBidDownloadParams} setParams={setKeywordBidDownloadParams} kwdBidDownloadGridRefresh={kwdBidDownloadGridRefresh} setKwdBidDownloadGridRefresh={setKwdBidDownloadGridRefresh} />
            }
        </>
    )
};

export default React.memo(KeywordBidManageGridComponent);