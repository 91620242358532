import dayjs from "dayjs";

/* undefined / null / trim() 체크(3가지 조건 중 1개라도 true일 경우 true 리턴)
* isNullOrEmpty(text);
* */
export const isNullOrEmpty = (text: string | undefined | null) => {
    return (text === undefined || text === null || text.trim() === "");
};

/*날짜 비교
* 날짜 기간 사이 벨리데이션 체크할때 씀(요청한 기간보다 날짜 차이가 긴 경우 true 리턴)
* isBetween(startDate, endDate, interval)
* */
export const isBetween = (startDate: string, endDate: string, interval: number) => {
    const convertStartDate = dayjs(startDate);
    const convertEndDate = dayjs(endDate);

    const diff = convertEndDate.diff(convertStartDate, "day", true);
    const diffDays = Math.floor(diff);
    return interval < diffDays
}

/** 숫자만 입력 체크 & 70원 이상 입력 체크 & 10단위 입력 체크 */
export const nbsBidCostInputCheck = (bidCost: string | number) => {
    const numberRegex = /^[0-9]+$/;
    let onlyNumberInputYn = numberRegex.test(bidCost as string);
    if(!onlyNumberInputYn){
        return false;
    }
    const numberBidCost = typeof bidCost === "string" ? parseInt(bidCost) : bidCost;
    //입찰가 입력 정책 체크(70이상 입력 / 10단위 입력)
    let bidCostPolicyYn = numberBidCost <= 100000 && numberBidCost >= 70 && numberBidCost % 10 === 0;
    return bidCostPolicyYn;
}


/** 희망 순위 1 ~ 25위 까지만 입력 가능 체크 */
export const nbsHopeRankInputCheck = (hopeRank: string | number) => {
    const numberRegex = /^[0-9]+$/;
    let onlyNumberInputYn = numberRegex.test(hopeRank as string);
    if(!onlyNumberInputYn){
        return false;
    }
    const numberHopeRank = typeof hopeRank === "string" ? parseInt(hopeRank) : hopeRank;
    // 희망순위 입력 정책 체크(1 ~ 25 까지 입력)
    let hopeRankPolicyYn = numberHopeRank >= 1 && numberHopeRank <= 25;
    return hopeRankPolicyYn;
}