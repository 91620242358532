import {createContext, useEffect, useState} from "react";
import apiCall from "../functions/apiCall";
import {jwtDecode} from "jwt-decode";
import {AxiosResponse} from "axios/index";

interface AuthContextProps {
    accessToken: string | null;
    isLoggedIn: boolean;
    roleGroups: string | null;
    login: (response: AxiosResponse) => void;
    logout: () => void;
    shadowLogin: (response: AxiosResponse) => void;
    shadowLogout: (response: AxiosResponse) => void;
}
export const AuthContext = createContext<AuthContextProps>({
    accessToken: null,
    isLoggedIn: false,
    roleGroups: null,
    login: function (response: AxiosResponse): void {
      throw new Error("Function not implemented.");
    },
    logout: function (): void {
      throw new Error("Function not implemented.");
    },
    shadowLogin: function (response: AxiosResponse): void {
        throw new Error("Function not implemented.");
    },
    shadowLogout: function (response): void {
        throw new Error("Function not implemented.");
    }
});

export const AuthContextProvider = (props: { children: any; }) => {
    const { children } = props;

    /** 세션 스토리지 데이터 모음(모두 String 형태로 들어가야 함..) */
    // 액세스 토큰
    const [accessToken, setAccessToken] = useState< string | null >(sessionStorage.getItem('accessToken'));
    // 권한. 사이드 바 메뉴 그릴 때 roleGroup은 이걸로 확인
    const [roleGroups, setRoleGroups] = useState< string | null >(sessionStorage.getItem("roleGroups"));
    // Nbidon 로그인 한 회원 ID 및 공통 헤더 이름
    const [loginMemberId, setLoginMemberId] = useState< string | null>(sessionStorage.getItem("loginMemberId"));
    const [loginMemberName, setLoginMemberName] = useState< string | null>(sessionStorage.getItem("loginMemberName"))
    // 쉐도우 로그인 한 회원 ID 및 쉐도우 로그인 회원 명
    const [shadowLoginMemberInfo, setShadowLoginMemberInfo] = useState< string | null>(sessionStorage.getItem("shadowLoginMemberInfo"));
    //각 메뉴 라우팅 시에 login 여부 체크는 이걸로 진행
    const userIsLoggedIn = !!accessToken;

    //로그인 성공 시 이 핸들러 호출. 로그인 처리 및 권한 처리도 같이 진행한다.
    const loginHandler = (response: AxiosResponse<any, any>) => {
        const accessToken = response.data.accessToken;
        const decode: any = jwtDecode(accessToken);

        /** 로그인 항목 처리 */
        setLoginMemberId(response.data.loginMemberId);
        setLoginMemberName(response.data.loginMemberName);
        setAccessToken(accessToken);
        setRoleGroups(decode.auth);

    };
    //로그아웃 진행 시 해당 핸들러 불러오면 된다. 로컬 스토리지에 존재하던 값 제거.
    const logoutHandler = () => {
        setLoginMemberId(null);
        setLoginMemberName(null);
        setAccessToken(null);
        setRoleGroups(null);
        setShadowLoginMemberInfo(null);
        sessionStorage.clear();
    };

    /* 쉐도우 로그인 핸들러. 쉐도우 로그인 한 아이디는 AccessToken에도 있고, jwtToken에도 존재함. */
    const shadowLoginHandler = (response: AxiosResponse) => {
        const decode: any = jwtDecode(response.data.accessToken);

        //쉐도우 로그인 ID 값 설정 및 새로운 롤그룹으로 값 변경
        setRoleGroups(decode.auth);
        setAccessToken(response.data.accessToken); // apiCall 시 헤더 교체도 겸함.
        //화면 표시용
        setShadowLoginMemberInfo(response.data.shadowLoginMemberId+"||"+response.data.shadowLoginMemberName);
        //쉐도우 로그인 이후 페이지 리다이렉트
        window.location.href= "/";
    }
    /** 쉐도우 로그아웃 핸들러. 일반 로그아웃과는 다르게 토큰을 재생성하기 떄문에 별도 처리가 필요하다. */
    const shadowLogoutHandler = (response: AxiosResponse) => {
        const decode: any = jwtDecode(response.data.accessToken);
        //쉐도우 로그인 ID 값 설정 및 새로운 롤그룹으로 값 변경
        setRoleGroups(decode.auth);
        setAccessToken(response.data.accessToken);
        setShadowLoginMemberInfo(null);
        //쉐도우 로그아웃 이후 페이지 리다이렉트
        window.location.href= "/";
    }

    useEffect(() => {
        if(accessToken === null){
            sessionStorage.clear();
        } else {
            sessionStorage.setItem('accessToken', accessToken);
            //API 호출 시 헤더에 ACCESS 토큰 추가
            apiCall.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        }
    }, [accessToken])

    useEffect(() => {
      roleGroups === null ? sessionStorage.clear() : sessionStorage.setItem('roleGroups', roleGroups)
    }, [roleGroups])
    useEffect(() => {
        loginMemberId === null ? sessionStorage.clear() : sessionStorage.setItem('loginMemberId', loginMemberId)
    }, [loginMemberId])
    useEffect(() => {
        if(loginMemberName !== null) sessionStorage.setItem('loginMemberName', loginMemberName);
    }, [loginMemberName])

    //쉐도우 로그인의 경우 없을 수 있음.
    useEffect(() => {
        shadowLoginMemberInfo !== null ?
            sessionStorage.setItem('shadowLoginMemberInfo', shadowLoginMemberInfo) :
            sessionStorage.removeItem('shadowLoginMemberInfo');
    }, [shadowLoginMemberInfo])

    const loginContext = {
    accessToken: accessToken,
    isLoggedIn: userIsLoggedIn,
    roleGroups: roleGroups,
    login: loginHandler,
    logout: logoutHandler,
    shadowLogin: shadowLoginHandler,
    shadowLogout: shadowLogoutHandler
  };

  return (
    <AuthContext.Provider value={loginContext}>{children}</AuthContext.Provider>
  );
};