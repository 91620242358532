import React, {useEffect, useState} from "react";
import ContentHeader from "../../components/layout/contentHeader";
import {Button, Card, Flex, Form, Input, Select, Space, Table, Typography} from "antd";
import {emptyTableLayout, paginationOptions} from "../../functions/tableFormatter";
import {useNavigate} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {errorAlert, successAlert} from "../../functions/alertFn";
import modal from "antd/es/modal";
import {ExclamationCircleFilled} from "@ant-design/icons";
import {BidScheduleManageGridProps, BidScheduleManageSearchProps} from "./kwdBidTypes";
import KeywordInfoListModal from "../../components/kwdBid/bidScheduleManage/keywordInfoListModal";
import apiCall from "../../functions/apiCall";
import SkeletonTable, {SkeletonTableColumnsType} from "../../components/table/skeletonTable";
import ScheduleCheckModal from "../../components/kwdBid/bidScheduleManage/scheduleCheckModal";

const BidScheduleManage = () => {
    /** 광고주 리스트 */
    const [ searchAdvList, setSearchAdvList] = useState<{advId: number, advName: string}[]>([]);
    /** 조회 필터 */
    const [ searchParams, setSearchParams ] = useState<BidScheduleManageSearchProps>({advId: '', bidScheduleName: ''});
    /** 그리드 */
    const [ gridLoading, setGridLoading ] = useState<boolean>(true);
    const [ gridRefresh, setGridRefresh ] = useState<boolean>(false);
    const [ rows, setRows ] = useState<BidScheduleManageGridProps[]>([]);
    const [ selectedRows, setSelectedRows] = useState<React.Key[]>([]);

    /** 모달 */
    const [ selectedUserRow, setSelectedUserRow ] = useState<BidScheduleManageGridProps>();
    const [ keywordInfoListOpenYn, setKeywordInfoListOpenYn ] = useState<boolean>(false);
    const [ scheduleModalYn, setScheduleModalYn ] = useState<boolean>(false);
    /** 테이블 초기화*/
    const [tableKey, setTableKey] = useState<number>(0);

    const columnsOptions: ColumnsType<BidScheduleManageGridProps> = [
        {title: '스케줄 ID', dataIndex: 'skdId', align: 'center' },
        {title: '광고주', dataIndex: 'advName', align: 'center' },
        {title: '스케줄 명', dataIndex: 'bidScheduleName', align: 'center', render: (scheduleName, record) => {
                return <div className="cell-divide">
                            <div className="box-left">
                                <span>{scheduleName}</span>
                            </div>
                            <div className="box-right">
                                <Button className="pink" size="small" onClick={() => {
                                    setSelectedUserRow(record);
                                    setScheduleModalYn(true);
                                    }
                                }>스케줄 확인</Button>
                            </div>
                        </div>
        }},
        {title: '적용 키워드 개수', dataIndex: ['keywords', 'kwdId'], align: 'center', render: (_, record) =>{
                const bidKwdCount = record.bidKwdCount;
                return <Button type="primary" className="pink" size="small" disabled={bidKwdCount === 0} onClick={() => {
                    setSelectedUserRow(record);
                    setKeywordInfoListOpenYn(true);
                }}>{bidKwdCount} 개</Button>
            }
        },
        {title: '최종 수정 시간', dataIndex: 'updateTime', align: 'center'},
        {title: '수정', dataIndex: 'modifyPwdBtn', align: 'center', render: (_, record) => {
                return <Button className="pink" size="small" onClick={() => { moveBidScheduleStatePage(record) }}>수정</Button>
            }
        },
    ];

    const rowSelection = { selectedRows, onChange: (newSelectedRowKeys: React.Key[]) => setSelectedRows(newSelectedRowKeys) };

    let navigate = useNavigate();
    /** 조회 필터 광고주 리스트 로드 **/
    const loadAdvs = () => {
        apiCall.get("/keywordBid/bidSchedule/findAdvListFilter")
        .then(resp => {
            setSearchAdvList(resp.data);
        })
        .catch()
        .finally()
    }

    const loadScheduleList = () => {
        apiCall.post("/keywordBid/bidSchedule/findBidScheduleList", searchParams)
        .then(resp => {
            setGridLoading(false);
            setRows(resp.data);
            // 초기화
            setSelectedRows([]);
            setTableKey(prevKey => prevKey + 1); // 키값을 변경시켜서 내부 상태를 초기화 해서 재 렌더링을 시킨다.
        })
        .catch(error => {
            errorAlert("스케줄 목록 데이터가 정상적으로 로드 되지 않습니다.<br />관리자에게 문의 바랍니다.",{error})
        })
        .finally(() => {});

    }

    /** 스케줄 등록 / 수정할 때 페이지 이동. record가 있을 경우 값을 설정해준다. */
    const moveBidScheduleStatePage = (record?: BidScheduleManageGridProps) => {
        let moveBidScheduleStatePage = "/kwdBid/bidScheduleState";
        navigate(moveBidScheduleStatePage, { state: {
                selectedRow : record === undefined ? undefined : record
            }});
    }

    const copyScheduleEvent = () => {
        if(selectedRows.length === 0 || selectedRows.length > 1) {
            errorAlert("복제하실 스케줄을 하나만 선택 후 다시 클릭해주세요.");
        } else {
            const selectedRowData = rows.find(row => row.bidScheduleId === selectedRows[0]);
            apiCall.post("/keywordBid/bidSchedule/copyBidSchedule", selectedRowData)
            .then(resp => {
                successAlert("스케줄 복제가 완료되었습니다.");

            })
            .catch(error => {
                errorAlert("스케줄 복제시 오류가 발생했습니다.<br />관리자에게 문의바랍니다.", {error});
            })
            .finally(() => {
                setSelectedRows([]);
                setGridRefresh(true);
                setTableKey(prevKey => prevKey + 1); // 키값을 변경시켜서 내부 상태를 초기화 해서 재 렌더링을 시킨다.
            });

        }
    }
    const deleteScheduleEvent = () => {
        if(selectedRows.length === 0) {
            errorAlert("삭제하실 스케줄을 선택 후 다시 클릭해주세요.");
        } else {
            modal.confirm({
                icon: <ExclamationCircleFilled rev={undefined} />,
                title: '선택한 입찰 스케줄을 삭제하시겠습니까?',
                content: '해당 스케줄을 사용 중인 키워드 스케줄 정보도 함께 변경됩니다.',
                okText: '확인', cancelText: '취소',
                okButtonProps: { className: "pink ant-btn-lg" },
                onOk() {
                    apiCall.put("/keywordBid/bidSchedule/deleteBidSchedule", selectedRows)
                        .then(resp => {
                            successAlert("스케줄 삭제가 완료되었습니다.");
                            setSelectedRows([]);
                            setGridRefresh(true);
                            setTableKey(prevKey => prevKey + 1); // 키값을 변경시켜서 내부 상태를 초기화 해서 재 렌더링을 시킨다.
                        })
                        .catch(error => {
                            errorAlert("API 삭제 시 오류가 발생했습니다.<br />관리자에게 문의바랍니다.", {error});
                        }).finally(() => {});
                        
                },
                cancelButtonProps: { type: 'primary', className: "gray ant-btn-lg" },
            })
        }
    }

    useEffect(() => {
        loadAdvs(); // 조회필터 광고주 리스트 로드
        loadScheduleList(); // 조회필터 기준 스케줄 리스트 로드 ( 없을 경우 전체 광고주 대상으로 로드 )
        document.title = "입찰 스케줄 관리";
    }, []);

    //화면 리프래시
    useEffect(() => {
        if(gridRefresh) {
            loadScheduleList();
            setGridRefresh(false);
        }
    }, [gridRefresh]);

    return (
        <>
            <ContentHeader pageTitle={"입찰 스케줄 관리"} navigation={["키워드 입찰", "입찰 스케줄 관리"]} />

            {/* <!-- Wrap-Filter : Start --> */}
            <section className="wrap-section wrap-filter">
                {/*<div className="box-header">
                    <div className="box-left">
                        <Typography.Title level={3} className="fc-gray-700">조회 필터</Typography.Title>
                    </div>
                </div>*/}
                <div className="box-body">
                    <Card>
                        <Flex gap={24}>
                            <div className="box-left">
                                <i className="ico ico-filter"></i>
                            </div>
                            <div className="box-center">
                                <Form>
                                    <Space size={32} wrap>
                                        <Form.Item label="광고주" colon={false}>
                                            <Select
                                                style={{ width: 250 }}
                                                placeholder={'광고주를 선택하세요.'}
                                                getPopupContainer = {triggerNode => triggerNode.parentElement}
                                                onChange={(advId: string) => {
                                                    setSearchParams({ ...searchParams, advId : advId});
                                                }}
                                                dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }}
                                                listHeight={200}
                                            >
                                                {searchAdvList.length > 0 && <Select.Option key="" value="">전체</Select.Option>}
                                                {searchAdvList.map((item) => {
                                                    return <Select.Option key={item.advId} value={item.advId}>{item.advName}</Select.Option>
                                                })}

                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="스케줄 명" colon={false}>
                                            <Input placeholder="스케줄 명을 입력해주세요" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const {value} = e.target;
                                                setSearchParams({ ...searchParams, bidScheduleName: value});
                                            }} onPressEnter={loadScheduleList}
                                            />
                                        </Form.Item>
                                    </Space>
                                </Form>
                            </div>
                            <div className="box-right">
                                <Button type="primary" className="pink" onClick={loadScheduleList}>조회하기</Button>
                            </div>
                        </Flex>
                    </Card>
                </div>
            </section>
            {/* <!-- Wrap-Filter : End --> */}

            {/* <!-- Wrap-Datagrid : Start --> */}
            <section className="wrap-section wrap-datagrid">
                <div className="box-header">
                    <div className="box-left">
                        <Typography.Title level={3} className="fc-gray-700">스케줄 목록</Typography.Title>
                    </div>
                    <div className="box-right">
                        <Button type="primary" className="pink" onClick={() => moveBidScheduleStatePage()}>스케줄 등록</Button>
                        <Button type="primary" className="pink" onClick={copyScheduleEvent}>선택 스케줄 복제</Button>
                        <Button type="primary" className="gray" onClick={deleteScheduleEvent}>삭제</Button>
                    </div>
                </div>
                <div className="box-body">
                    <SkeletonTable loading={gridLoading} columns={columnsOptions as SkeletonTableColumnsType[]}>
                        <Table
                            key={tableKey}
                            locale={emptyTableLayout(["등록된 스케줄이 없습니다","[스케줄 등록] 버튼을 클릭하여 입찰 스케줄을 등록하세요"])}
                            bordered
                            rowKey={(record) => record.bidScheduleId}
                            rowSelection={rowSelection}
                            columns={columnsOptions}
                            dataSource={rows}
                            pagination={paginationOptions(rows)}
                        />
                    </SkeletonTable>
                </div>
            </section>
            {/* <!-- Wrap-Datagrid : End --> */}

            {/* 적용 키워드 리스트 모달 */}
            { keywordInfoListOpenYn &&
                <KeywordInfoListModal
                    selectRow={selectedUserRow!!}
                    isOpen={keywordInfoListOpenYn}
                    setOpen={setKeywordInfoListOpenYn}
                    setGridRefresh={setGridRefresh}
                />
            }
            {/* <!-- 스케쥴 확인 모달 --> */}
            {scheduleModalYn && selectedUserRow !== undefined &&
                <ScheduleCheckModal bidScheduleId={selectedUserRow.bidScheduleId} isOpen={scheduleModalYn} setOpen={setScheduleModalYn} />
            }
        </>
    )
};

export default BidScheduleManage;