import {Button, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {paintScheduleCubes} from "../../../functions/bidScheduleFn";
import apiCall from "../../../functions/apiCall";
import ScheduleDrawComponent, {ScheduleProps} from "../../common/scheduleDrawComponent";

/** 단순 스케줄 확인용 모달. ScheduleModal과는 나오는 양식이 상이하여 별도로 나눔 **/
interface ScheduleCheckModalProps {
    bidScheduleId : number,
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}
const ScheduleCheckModal = ({bidScheduleId, isOpen, setOpen}: ScheduleCheckModalProps) => {
    const [ schedule, setSchedule ] = useState<ScheduleProps>();

    useEffect(() => {
        apiCall.get(`/keywordBid/bidKwd/loadSchedule?scheduleId=${bidScheduleId}`)
            .then(resp => setSchedule(resp.data))
    }, [])

    useEffect(() => {
        if(schedule !== undefined){
            paintScheduleCubes(schedule.bidScheduleDetList);
        }
    }, [schedule])
    return (
        <>
            <Modal
                style={{overflowX: 'scroll'}}
                title="스케줄 확인"
                open={isOpen}
                onCancel={() => {setOpen(false); }}
                afterClose={() => { setOpen(false); }}
                width={1500}
                footer={[
                    <Button type="primary" className="pink" size="large" key="footerChargeBtn"
                            onClick={() => setOpen(false)}>확인
                    </Button>,
                ]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">스케줄명</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <span className="comp-txt">
                                            <span className="table">
                                                <span className="table-cell">
                                                    <b className="fz-14 fc-gray-400">{schedule?.bidScheduleName}</b>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}

                {/* <!-- Wrap-Schedule : Start --> */}
                <ScheduleDrawComponent schedule={schedule} />
                {/* <!-- Wrap-Schedule : End --> */}
            </Modal>
        </>
    )
};
export default React.memo(ScheduleCheckModal);
