import React from 'react';
import {Button, Modal, Table, Tag, Typography} from "antd";
import {NbsUserManageGridProps} from "../../../pages/admin/adminTypes";
import {paginationOptions} from "../../../functions/tableFormatter";

const { Text } = Typography;

interface AdvInfoListModalProps {
    selectRow: NbsUserManageGridProps,
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,

}

const AdvInfoListModal = ({selectRow, isOpen, setOpen }: AdvInfoListModalProps) => {
    const dataSource = selectRow.advs === undefined ? [] : selectRow.advs;
    return (
        <>
            <Modal
                title="등록 광고주 목록"
                open={isOpen}
                onCancel={() => {setOpen(false); }}
                afterClose={() => { setOpen(false); }}
                width={1200}
                footer={[
                    <Button type="primary" className="pink" size="large" key="footerChargeBtn" onClick={() => setOpen(false)}>
                        확인
                    </Button>,
                ]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">사용자</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Text className="fz-15 fw-exbold fc-gray-500">{selectRow.memberId}({selectRow.memberName})</Text>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}

                {/* <!-- Wrap-Datagrid : Start --> */}
                <section className="wrap-section wrap-datagrid">
                    <div className="box-body">
                        <Table
                            key={"advListGrid"}
                            rowKey={"advId"}
                            //columns={columnsOptions}
                            columns={[
                                {title: 'No', dataIndex: 'index', align: 'center', render: (_, record, index) => index+1},
                                {title: '광고주 로그인 ID', dataIndex: 'nvLoginId', align: 'center' },
                                {title: '광고주 명', dataIndex: 'customAdvName', align: 'center' },
                                {title: '사용 가능 여부', dataIndex: 'actYn', align: 'center', render: (value, record) =>{
                                    if(value) return <Tag color={"blue"} key={"blue"}>{"사용 가능"}</Tag>
                                        else return <Tag color={"default"} key={"default"}>{"사용 불가"}</Tag>
                                    }
                                }
                            ]}
                            dataSource={dataSource}
                            bordered
                            pagination={paginationOptions(dataSource)}
                        />
                    </div>
                </section>
                {/* <!-- Wrap-Datagrid : End --> */}
            </Modal>
        </>
    );
};
export default React.memo(AdvInfoListModal);