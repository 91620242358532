import {Button, Flex, Modal, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {BidScheduleStateConfigProps, KeywordBidManageGridProps} from "../../../../pages/kwdBid/kwdBidTypes";
import {nameTreeFormatter} from "../../../../functions/nbsFormatter";
import {makeScheduleCubes, paintScheduleCubes, scheduleCubeHeader} from "../../../../functions/bidScheduleFn";
import apiCall from "../../../../functions/apiCall";
import ScheduleDrawComponent, {ScheduleProps} from "../../../common/scheduleDrawComponent";

interface ScheduleModalProps {
    selectRow: KeywordBidManageGridProps,
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}
const ScheduleModal = ({selectRow, isOpen, setOpen}: ScheduleModalProps) => {

    const [ schedule, setSchedule ] = useState<ScheduleProps>();

    const pageInit = () => {
        apiCall.get(`/keywordBid/bidKwd/loadSchedule?scheduleId=${selectRow.scheduleId}`)
            .then(resp => setSchedule(resp.data))
    }

    useEffect(() => {
        pageInit()
    }, [])

    useEffect(() => {
        if(schedule !== undefined){
            paintScheduleCubes(schedule.bidScheduleDetList);
        }
    }, [schedule])
    return (
        <>
            <Modal
                style={{overflowX: 'scroll'}}
                title="스케줄 확인"
                open={isOpen}
                onCancel={() => {setOpen(false); }}
                afterClose={() => { setOpen(false); }}
                width={1500}
                footer={[
                    <Button type="primary" className="pink" size="large" key="footerChargeBtn"
                            onClick={() => setOpen(false)}>확인
                    </Button>,
                ]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">키워드</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Typography.Text className="fz-15 fw-exbold fc-gray-500">{selectRow.kwdName}</Typography.Text>
                                    </div>
                                    <div className="form-group">
                                        {nameTreeFormatter([selectRow.advName, selectRow.campName, selectRow.groupName])}
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">스케줄명</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <span className="comp-txt">
                                            <span className="table">
                                                <span className="table-cell">
                                                    <b className="fz-14 fc-gray-400">{schedule?.bidScheduleName}</b>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}

                {/* <!-- Wrap-Schedule : Start --> */}
                <ScheduleDrawComponent schedule={schedule} />
                {/* <!-- Wrap-Schedule : End --> */}
            </Modal>
        </>
    )
};
export default React.memo(ScheduleModal);
