import {ApexOptions} from "apexcharts";
import React from "react";

// 시간대 배열 생성 (00시 ~ 23시)
const hours = Array.from({length: 24}, (_, i) => `${String(i).padStart(2, '0')}시`);

//chartContext를 가져가야 하는 경우 아래 옵션 사용
export const chartOptionsWithContext = (setChartContext:  React.Dispatch<any>, showYaxis: boolean) => {
    const chartOptions: ApexOptions = {
        chart: {
            id: 'timeRankChart', // 차트 ID 명시적으로 설정
            height: 350,
            type: 'line',
            zoom: { enabled: false },
            toolbar: { show: false }, // 다운로드 안 보이도록
            events: {
                mounted: function(chartContext, _) {
                    setChartContext(chartContext);
                }
            }
        },
        stroke: { curve: 'smooth' }, // 그래프 선 부드럽게
        grid: { strokeDashArray: 1 }, // 배경 그리드 라인에 ㅡ ㅡ ㅡ 스타일 지정
        xaxis: { categories: hours },
        yaxis: { // 배경 y축 지정. 1위 ~ 15위 고정에 line 갯수는 14개
            show: showYaxis,
            min: 0,
            max: 25,
            tickAmount: 24,
            reversed: true, // Y축 반대
            labels: {
                formatter(val: number, opts?: any): string | string[] {
                    return val !== undefined ? val.toFixed() : val; // y축 소수점 없애기
                }
            }
        },
        legend: {
            position: "right"
        }
    }
    return chartOptions;

};