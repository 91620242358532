import {Button, Modal, Select, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {KeywordBidManageGridProps} from "../../../../pages/kwdBid/kwdBidTypes";
import {useNavigate} from "react-router-dom";
import ScheduleModal from "./scheduleModal";
import {errorAlert, successAlert} from "../../../../functions/alertFn";
import {nameTreeFormatter} from "../../../../functions/nbsFormatter";
import apiCall from "../../../../functions/apiCall";
import {bidScheduleList} from "../keywordBidManageFilterFixList";

interface ScheduleRegModalProps {
    selectRow: KeywordBidManageGridProps,
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setGridRefresh: React.Dispatch<React.SetStateAction<boolean>>
}
const ScheduleRegModal = ({selectRow, isOpen, setOpen, setGridRefresh}: ScheduleRegModalProps) => {
    const navigate = useNavigate();

    const [ rowConfig, setRowConfig ] = useState<KeywordBidManageGridProps>(selectRow);
    const [ scheduleList, setScheduleList ] = useState<{scheduleId : number, scheduleName: string}[]>([]);

    const [ scheduleModalYn, setScheduleModalYn ] = useState<boolean>(false);



    const checkScheduleModal = () => {
        if(rowConfig.scheduleId === undefined) {
            errorAlert("스케줄을 선택하고 다시 클릭해주세요.");
        } else {
            setScheduleModalYn(true);
        }
    }
    function moveBidScheduleStatePage() {
        let moveBidScheduleStatePage = "/kwdBid/bidScheduleState";
        navigate(moveBidScheduleStatePage, { state: { selectedRow : undefined }});
    }

    /** 스케줄 등록 이벤트 */
    const regScheduleEvent = () => {
        if (rowConfig.scheduleId === undefined) {
            errorAlert("스케줄을 지정해주세요.");
            return;
        }

        apiCall.post(`/keywordBid/bidKwd/addSchedule?bidKwdId=${rowConfig.bidKwdId}&scheduleId=${rowConfig.scheduleId}`)
            .then(() => successAlert("스케줄 등록에 성공했습니다."))
            .catch(error => errorAlert(error.response.data.message))
            .finally(() => {
                setOpen(false);
                setGridRefresh(true);
            });
    }

    useEffect(() => {
        const pageInit = () => {
            /** 광고주별 스케쥴 로드. 그냥 관리단에서 스케쥴까지 같이 로드하는 걸 추천.... 여기서는 더미성 데이터라 그냥 처리함. */
            apiCall.get(`/keywordBid/bidKwd/loadScheduleList?advId=${selectRow.advId}`)
                .then(resp => setScheduleList(resp.data));
        }
        
        pageInit()
    },[]);
    return (
        <>
            <Modal
                title={rowConfig.scheduleId === undefined || rowConfig.scheduleId === null ? '스케줄 추가' : '스케줄 변경'}
                open={isOpen}
                onCancel={() => {setOpen(false); }}
                afterClose={() => {setOpen(false); }}
                width={1200}
                footer={[
                    <Button type="primary" className="gray" size="large" key="footerCancelBtn" onClick={() => {setOpen(false); }}>취소</Button>,
                    <Button type="primary" className="pink" size="large" key="footerCloseBtn" onClick={regScheduleEvent}>저장</Button>,
                ]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">키워드</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Typography.Text className="fz-15 fw-exbold fc-gray-500">{selectRow.kwdName}</Typography.Text>
                                    </div>
                                    <div className="form-group">
                                        {nameTreeFormatter([selectRow.advName, selectRow.campName, selectRow.groupName])}
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">스케줄</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Select
                                            style={{ width: 400 }}
                                            value={rowConfig.scheduleId}
                                            // defaultValue={rowConfig.scheduleId === undefined || rowConfig.scheduleId === null ? undefined : selectRow.scheduleId}
                                            onChange={(scheduleId)=> {
                                                setRowConfig({...rowConfig, scheduleId : scheduleId})
                                            }}
                                            placeholder={"스케줄을 선택해주세요"}
                                            options={bidScheduleList(scheduleList, true, '사용안함')}
                                            dropdownRender={menu => (
                                                <div style={{ maxHeight: rowConfig.scheduleId === undefined ? 400 : 200, overflowY: 'auto' }}>
                                                    {menu}
                                                </div>
                                            )}
                                        />
                                        <Button className="pink" onClick={() => checkScheduleModal()}>스케줄 확인</Button>
                                        <Button type="primary" className="pink" icon={<i className="ico ico-add"></i>} onClick={() => moveBidScheduleStatePage()}>신규 스케줄 등록</Button>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}
            </Modal>

            {/* <!-- 스케쥴 확인 모달 --> */}
            {scheduleModalYn &&
                <ScheduleModal selectRow={rowConfig} isOpen={scheduleModalYn} setOpen={setScheduleModalYn} />
            }

        </>
    )
};

export default ScheduleRegModal;