import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "antd";

import {errorAlert, successAlert} from "../../../functions/alertFn";

import {RightCircleOutlined} from '@ant-design/icons';
import apiCall from '../../../functions/apiCall';

interface RegApiModalProps {
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setGridRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const RegApiModal = ({ isOpen, setOpen, setGridRefresh }: RegApiModalProps) => {

    /** 등록 폼(초기값 지정때문에 customerId string으로 설정함.) */
    const initRegForm ={loginId : '', customerId : '', accessLicense: '', secretKey: ''};
    const [ regForm, setRegForm ] = useState<{loginId: string, customerId: string, accessLicense: string, secretKey: string}>(initRegForm);
    const [form] = Form.useForm();

    //모달 초기화
    const modalReset = () => {
        form.resetFields();
        setRegForm(initRegForm);
    }
    //API 등록 벨리데이션
    const regFormValidation = () => {
        if(regForm.loginId === undefined || regForm.loginId === '' ) {
            errorAlert("로그인 ID를 입력해주세요.");
            return false;
        } else if(regForm.customerId === undefined || regForm.customerId === '' ) {
            errorAlert("Customer Id를 입력해주세요.");
            return false;
        } else if(regForm.accessLicense === undefined || regForm.accessLicense === '' ) {
            errorAlert("AccessLicense를 입력해주세요.");
            return false;
        } else if(regForm.secretKey === undefined || regForm.secretKey === '' ) {
            errorAlert("SecretKey를 입력해주세요.");
            return false;
        }
        return true;
    }

    const onChangeRegFormEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRegForm({ ...regForm, [name]: value});
    }

    //Api 등록 이벤트
    const regApiEvent = () => {
        if(regFormValidation()) {
            apiCall.post("/connMng/apiMng/regApiLicenseInfo", regForm)
            .then(resp => {
                successAlert("API 저장 완료되었습니다.");
                modalReset();
                setOpen(false);
                setGridRefresh(true);
            })
            .catch(error => {
                const code = error.response.data.code;
                errorAlert(
                    code === "INVALID_PARAMETER" ? "중복된 API는 등록 할 수 없습니다." : "API 정보를 확인해 주세요",
                    {error}
                );
            })
            .finally(() => {

            });
            
        }
    }

    return (
        <>
            <Modal
                title="신규 API 등록"
                open={isOpen}
                onCancel={() => {modalReset(); setOpen(false); }}
                afterClose={() => { modalReset(); setOpen(false); }}
                width={900}
                footer={[
                    <Button type="primary" className="gray" size="large" key="footerCancelBtn" onClick={() => {modalReset(); setOpen(false); }}>취소</Button>,
                    <Button type="primary" className="pink" size="large" key="footerCloseBtn" onClick={regApiEvent}>등록</Button>,
                ]}
            >
                {/* <!-- Wrap-Help : Start --> */}
                <section className="wrap-section wrap-help">
                    <ul className="box-body">
                        <li className="item-help">
                            <RightCircleOutlined rev={undefined}/>
                            <span className="fz-14 fc-gray-300">{`네이버 API 확인 경로 : 네이버 광고 시스템 > 도구 > API 사용 관리`}</span>
                        </li>
                        <li className="item-help">
                            <RightCircleOutlined rev={undefined}/>
                            <span className="fz-14 fc-gray-300">쉐도우 로그인이 되지 않은 상태의 API 정보로 입력해야 합니다.</span>
                        </li>
                    </ul>
                </section>
                {/* <!-- Wrap-Help : End --> */}

                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                   {/* <div className="box-header">
                        <div className="box-left">
                            <Title level={4} className="fc-gray-500">사용자 수정</Title>
                        </div>
                    </div>*/}
                    <div className="box-body">
                        <Form form={form} name="dependencies" autoComplete="off" layout="vertical">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">로그인 ID</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Input className="w-500" name="loginId" value={regForm.loginId} onChange={onChangeRegFormEvent} />
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">Customer Id</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Input className="w-500" name="customerId" value={regForm.customerId} onChange={onChangeRegFormEvent} />
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">AccessLicence</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Input className="w-500" name="accessLicense" value={regForm.accessLicense} onChange={onChangeRegFormEvent} />
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">SecretKey</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Input className="w-500" name="secretKey" value={regForm.secretKey} onChange={onChangeRegFormEvent} />
                                    </div>
                                </dd>
                            </dl>
                        </div>
                        </Form>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}
            </Modal>
        </>
    );
};
export default React.memo(RegApiModal);