import {Button} from "antd";
import React from "react";
import DOMPurify from "dompurify";

const HealthCheck = () => {
    return (
        <>
            <div className="wrap error">
                <div className="box-inner">
                    <div className="box-left">
                        <div className="box-top">
                            <div className="fz-16 fw-bold fc-pink-400"
                                 dangerouslySetInnerHTML={{__html: DOMPurify.sanitize("프론트 엔드 헬스 체크 페이지입니다.")}}
                            />
                        </div>
                        <div className="box-middle">
                            <p className="fz-24 fw-bold fc-gray-600"
                               dangerouslySetInnerHTML={{__html: DOMPurify.sanitize("프론트 엔드 헬스 체크 페이지입니다.")}}
                            />
                            <p className="fz-15 fw-bold fc-gray-300"
                               dangerouslySetInnerHTML={{__html: DOMPurify.sanitize("프론트 엔드 헬스 체크 페이지입니다.")}}
                            />
                        </div>
                        <div className="box-bottom">
                            <Button type="primary" className="pink" onClick={()=> {
                                window.location.href="/"
                            }}>홈 화면으로 이동</Button>
                        </div>
                    </div>
                    <div className="box-right">
                        <img src={require('../../images/img-error-object.png')} alt={"에러 페이지 이미지"} />
                    </div>
                </div>
            </div>
        </>
    )
};

export default HealthCheck;