/* 헤더부분의 메뉴. 추후 길어질 경우 별도의 컴포넌트나 json 스타일로 빼자 */
import {MenuProps} from "antd";
import {Link} from "react-router-dom";
import React from "react";

/** 관리자 + 쉐도우 로그인했을 경우 용도로 사용될 메뉴.*/
export const AllMenu: MenuProps['items'] = [
    // 1.0 주석 처리
    // {label: '입찰 설정', key: 'bidSetting', icon: <i className="ico ico-menu-01"></i>},
    // {label: '데이터 다운로드', key: 'taskManager', icon: <i className="ico ico-menu-02"></i>},

    { label: <Link to="/kwdBid/keywordBidManage">키워드 입찰</Link>, key: 'kwdBid', icon: <i className="ico ico-menu-04"></i>,
        children: [{type: 'group', label: '', children: [
                { label: <Link to="/kwdBid/keywordBidManage">키워드 입찰 관리</Link>, key: 'keywordBidManage'},
                { label: <Link to="/kwdBid/keywordBidBulkManage">키워드 입찰 등록</Link>, key: 'keywordBidBulkManage' },
                { label: <Link to="/kwdBid/bidScheduleManage">입찰 스케줄 관리</Link>, key: 'bidScheduleManage' },
                { label: <Link to="/kwdBid/bidProductState">입찰 상품 현황</Link>, key: 'bidProductState' },
            ]}
        ],
    }, { label: <Link to="/dataMng/competitorsRankHistory">데이터 관리</Link>, key: 'dataMng', icon: <i className="ico ico-menu-02"></i>,
        children: [{ type: 'group', label: '', children: [
                { label: <Link to="/dataMng/competitorsRankHistory">경쟁사 순위 조회</Link>, key: 'competitorsRankHistory' },
            ]}
        ],
    }, { label: <Link to="/connectionMng/apiManage">연동 관리</Link>, key: 'connectionMng', icon: <i className="ico ico-menu-05"></i>,
        children: [{ type: 'group', label: '', children: [
                { label: <Link to="/connectionMng/apiManage">API 관리</Link>, key: 'apiManage', },
                { label: <Link to="/connectionMng/advManage">광고주 관리</Link>, key: 'advManage' }
            ]}
        ],
    }, { label: <Link to="/admin/nbsUserManage">관리자</Link>, key: 'admin', icon: <i className="ico ico-menu-06"></i>,
        children: [{ type: 'group', label: '', children: [
                { label: <Link to="/admin/nbsUserManage">사용자 관리</Link>, key: 'nbsUserManage' },
                { label: <Link to="/admin/prodChargeManage">상품 충전 관리</Link>, key: 'prodChargeManage' },
            ]}
        ],
    }
];

/** 사용자 전용 메뉴 */
export const AdvMenu: MenuProps['items'] = [
    { label: <Link to="/kwdBid/keywordBidManage">키워드 입찰</Link>, key: 'kwdBid', icon: <i className="ico ico-menu-04"></i>,
        children: [{type: 'group', label: '', children: [
                { label: <Link to="/kwdBid/keywordBidManage">키워드 입찰 관리</Link>, key: 'keywordBidManage'},
                { label: <Link to="/kwdBid/keywordBidBulkManage">키워드 입찰 등록</Link>, key: 'keywordBidBulkManage' },
                { label: <Link to="/kwdBid/bidScheduleManage">입찰 스케줄 관리</Link>, key: 'bidScheduleManage' },
                { label: <Link to="/kwdBid/bidProductState">입찰 상품 현황</Link>, key: 'bidProductState' },
            ],
        }],
    }, { label: <Link to="/dataMng/competitorsRankHistory">데이터 관리</Link>, key: 'dataMng', icon: <i className="ico ico-menu-02"></i>,
        children: [{ type: 'group', label: '', children: [
                { label: <Link to="/dataMng/competitorsRankHistory">경쟁사 순위 조회</Link>, key: 'competitorsRankHistory' },
            ],
        }]
    }, { label: <Link to="/connectionMng/apiManage">연동 관리</Link>, key: 'connectionMng', icon: <i className="ico ico-menu-05"></i>,
        children: [{ type: 'group', label: '', children: [
                { label: <Link to="/connectionMng/apiManage">API 관리</Link>, key: 'apiManage', },
                { label: <Link to="/connectionMng/advManage">광고주 관리</Link>, key: 'advManage' }
            ],
        }],
    }
];

/** 관리자 전용 메뉴 */
export const AdminMenu: MenuProps['items'] = [
    { label: '관리자', key: 'admin', icon: <i className="ico ico-menu-06"></i>,
        children: [{ type: 'group', label: '', children: [
                { label: <Link to="/admin/nbsUserManage">사용자 관리</Link>, key: 'nbsUserManage' },
                { label: <Link to="/admin/prodChargeManage">상품 충전 관리</Link>, key: 'prodChargeManage' },
            ],
        }],
    }
];
