import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Button, Divider, Dropdown, Input, List, Menu, MenuProps, Space, theme, Typography} from "antd";
import {DownOutlined, SearchOutlined} from '@ant-design/icons';
import {AdminMenu, AdvMenu, AllMenu} from "../menu/menus";
import {emptyTableLayout} from "../../functions/tableFormatter";
import {AuthContext} from "../../auth/loginAuthContext";
import apiCall from "../../functions/apiCall";
import {errorAlert} from "../../functions/alertFn";
// Typography
const { Text } = Typography;

const { useToken } = theme;

const CommonHeader = () => {
    const rootSubmenuKeys = ['kwdBid']; // 최초 페이지 관련 정보
    const [current, setCurrent] = useState('kwdBid');
    const location = useLocation();
    const onClick: MenuProps['onClick'] = (e) => setCurrent(e.key);

    const [openKeys, setOpenKeys] = useState(['kwdBid']);

    // 현재 접속중인 권한 체크
    const authContext = useContext(AuthContext);
    const { roleGroups } = authContext;
    // 권한 별 메뉴 리스트 로드
    const findMenu = () => {
        //어드민 권한일 경우
        if(roleGroups!!.includes("ROLE_ATTR_ADMIN")) {
            if(roleGroups!!.includes("ROLE_ATTR_SHADOW_LOGIN")) return AdvMenu; //쉐도우 로그인 했을 경우 관리자 메뉴 안 보이도록 처리
            else return roleGroups!!.includes("ROLE_ATTR_USER") ? AllMenu : AdminMenu; // 유저 권한을 가지고 있을 경우 모두 오픈
        } else { // 그 외의 권한은 모두 광고주 메뉴만 보여준다.
            return AdvMenu;
        }
    }
    const menuList = findMenu();

    // 어드민 권한일 경우 쉐도우 로그인 메뉴 표시
    const shadowLoginMenuOpenYn = roleGroups === undefined ? false : roleGroups!!.includes("ROLE_ATTR_SHADOW");

    /** 메뉴 변경시마다 현재 메뉴를 체크해서 값 적재 **/
    const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    /** 로그아웃 */
    const logoutEvent = () => {
        sessionStorage.clear();
        window.location.href="/";
    }

    //주소가 변경 또는 새로고침 되었을 때 메뉴에 selected 설정
    useEffect(() => {
        const splitPath = location.pathname.split("/")[2];
        //입찰 스케줄 등록 페이지만 메뉴가 없어 상위 메뉴인 입찰 스케줄 관리로 지정
        setCurrent(splitPath === "bidScheduleState" ? "bidScheduleManage" : splitPath);
    }, [location]);


    /**** 쉐도우 로그인 관련 모음 ****/
    const [ shadowLoginUserList, setShadowLoginUserList] = useState<{memberId: string, memberName: string}[]>([]);
    /* 쉐도우 로그인 검색용 리스트 */
    const [ shadowLoginUserAllList, setShadowLoginUserAllList] = useState<{memberId: string, memberName: string}[]>([]);
    const { token } = useToken();
    /* 쉐도우 로그인 CSS */
    const contentStyle = { backgroundColor: token.colorBgElevated, borderRadius: token.borderRadiusLG, boxShadow: token.boxShadowSecondary };

    /** 쉐도우 로그인 가능한 유저 리스트 로드 */
    const loadShadowLoginUserList = () => {
        if(shadowLoginMenuOpenYn) {
            apiCall.post("/auth/findListShadowLoginAdvs").then(res=> {
                setShadowLoginUserList(res.data);
                //검색용으로 사용
                setShadowLoginUserAllList(res.data);
            })
        }
    }
    /** 쉐도우 로그인 유저 검색 시 필터 처리 */
    const filterShadowLoginUserList = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setShadowLoginUserList(shadowLoginUserAllList.filter(item =>
            (item.memberId.includes(value) || item.memberName.includes(value)))
        );
    }

    /** 쉐도우 로그인 이벤트 */
    const shadowLoginEvent = (e: any) => {
        const { id } = e.target.dataset;
        apiCall.post("/auth/shadowLogin", { memberId : id})
            .then(res => {
                authContext.shadowLogin(res);
            })
            .catch(error => {
                errorAlert("쉐도우 로그인이 실패되었습니다. 관리자에게 문의해주세요.");
            });
    }

    /** 쉐도우 로그아웃 이벤트 */
    const shadowLogoutEvent = () => {
        const shadowLoginMemberInfo = sessionStorage.getItem("shadowLoginMemberInfo");
        if(shadowLoginMemberInfo !== null){ // 쉐도우 로그인 상태일 경우에만 동작
            apiCall.post("/auth/shadowLogout").then(res => {
                authContext.shadowLogout(res);
            }).catch(error => {
                errorAlert("쉐도우 로그아웃에 실패하였습니다. 관리자에게 문의해주세요.");
            })
        }
    }
    //관리자 로그인 시 쉐도우 로그인 리스트 대상 로드
    useEffect(() => {
        loadShadowLoginUserList();
    }, []);

    return (
    <>
        <a className="logo"><span>N</span>bid<span>on</span></a>

        <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={menuList} onOpenChange={onOpenChange} />

        <div className="user-info">
            {/* Util Shadow-Login : Start */}
            {shadowLoginMenuOpenYn &&
            <Dropdown
                className="util dropdown shadow-login"
                // menu={{items}}
                trigger={['click']}
                placement="bottomRight"
                arrow
                dropdownRender={(menu) => (
                    <Space.Compact block direction="vertical" className="shadow-login dropdown-menu" style={contentStyle}>

                        <Space.Compact block className="own-account">
                            <a className="fz-16 fw-bold fc-gray-500" onClick={shadowLogoutEvent}>{sessionStorage.getItem('loginMemberName')}({sessionStorage.getItem('loginMemberId')})</a>
                        </Space.Compact>
                        <Divider />
                        <Space direction="vertical" className="box-adv-search" size={4}>
                            <Input
                                prefix={<SearchOutlined rev={undefined} className="site-form-item-icon" />}
                                placeholder="사용자명 혹은 ID를 검색해주세요."
                                allowClear style={{ width: 300 }}
                                onChange={filterShadowLoginUserList}
                            />
                            <List
                                className="adv-list"
                                itemLayout="horizontal"
                                split={false}
                                locale={emptyTableLayout(["검색된 사용자나 ID가 없습니다.", "다른 검색어로 조회해보세요."])}
                            >
                                {shadowLoginUserList.length > 0 && shadowLoginUserList.map((user)=> {
                                    return (
                                        <List.Item key={user.memberId}>
                                            <a className="adv-list-item" data-id={user.memberId} data-name={user.memberName} onClick={shadowLoginEvent}>{user.memberName} ({user.memberId})</a>
                                        </List.Item>
                                    )
                                })
                                }
                            </List>
                        </Space>

                    </Space.Compact>
                )}
            >
                <a onClick={(e) => e.preventDefault()}>
                    <Space size={8}>
                        <Space direction="vertical" size={0}>
                            <Text className="fz-14">
                                {sessionStorage.getItem("shadowLoginMemberInfo") === null ?
                                    "쉐도우 로그인" :
                                    `쉐도우 로그인 (${sessionStorage.getItem("shadowLoginMemberInfo")!!.split("||")[1]})`
                                }
                            </Text>
                        </Space>
                        <DownOutlined rev={undefined} />
                    </Space>
                </a>
            </Dropdown>
            }
            {/* Util Shadow-Login : End */}

            {/* Util Login-info : Start */}
            <Space split={<Divider type="vertical" />}>
                <Space>
                    <i className="ico ico-user-info"></i>
                    <span className="fz-16 fc-gray-300">
                      {sessionStorage.getItem('loginMemberId') === null ? "비 로그인" : `${sessionStorage.getItem('loginMemberId')}(${sessionStorage.getItem('loginMemberName')})`}
                    </span>
                </Space>
                <Button className="gray" size="small" onClick={logoutEvent}>로그아웃</Button>
            </Space>
            {/* Util Login-info : End */}

        </div>
    </>
    );
}

export default CommonHeader;