/* API 호출 시 에러 관련 알럿 처리. MSG에는 ERROR에 메시지가 없을 경우 표시된다.*/
import {isNullOrEmpty} from "./validator";
import {Dispatch, SetStateAction} from "react";
import modal from "antd/es/modal";

function makeAlertParams(content: string, options?: alertOptionTypes){
    let title = "알림";
    let refreshFn: Dispatch<SetStateAction<boolean>> | undefined = undefined;
    if(options !== undefined){
        if(options.refreshFn !== undefined) refreshFn = options.refreshFn;
    }
    const convertMsg = content.split("<br />").map((line, index) => { return (<span key={index+line}>{line}<br/></span>)});
    return { title, content: convertMsg, refreshFn};
}
type alertOptionTypes = {
    error?: any,
    refreshFn?: Dispatch<SetStateAction<boolean>>
}
//API 성공 및 실패에서 사용하는 알럿 모음. 추가 항목이 필요할 경우 수정할 것
export const successAlert = (content: string, options?: alertOptionTypes) => {
    const alertParams = makeAlertParams(content, options);
    modal.success({
        title: alertParams.title,
        content: alertParams.content,
        okText: '확인',
        afterClose: () => alertParams.refreshFn !== undefined ? alertParams.refreshFn(true) : null,
        okButtonProps: { className: "pink ant-btn-lg" },
    })
}
export const errorAlert = (content: string, options?: alertOptionTypes ) => {
    let msg = content;
    if(options !== undefined && options.error !== undefined && options.error.response !== undefined) {
        msg = !isNullOrEmpty(options.error.response.data.message) ? content : options.error.response.data.message;
    }
    const alertParams = makeAlertParams(msg, options);
    modal.error({
        title: alertParams.title,
        content: alertParams.content,
        okText: '확인',
        afterClose: () => alertParams.refreshFn !== undefined ? alertParams.refreshFn(true) : null,
        okButtonProps: { className: "pink ant-btn-lg" },
    })

}

//알럿 메시지 공통화.
//refreshYn일 때 setRefresh 관련 함수 필수임.
// function convertContent(content: string){
//     return content.split("<br />").map((line, index) => { return (<span key={index+line}>{line}<br/></span>)})
// }
// interface alertProps {
//     title?: string,
//     content: string,
//     refreshYn?: boolean,
//     refreshKey?: Dispatch<SetStateAction<boolean>>
// }
// export const errorAlert = ({title, content, refreshYn, refreshKey}: alertProps) => {
//     const alertTitle = title === undefined ? "알림" : title;
//     const alertRefreshYn = refreshYn === undefined ? false : refreshYn;
//     return alertRefreshYn ?
//         modal.error({
//             title: alertTitle,
//             content: convertContent(content),
//             okText: '확인',
//             afterClose: () => refreshKey!(true),
//             okButtonProps: { className: "pink ant-btn-lg" },
//         })
//         :
//         modal.error({
//             title: alertTitle,
//             content: convertContent(content),
//             okText: '확인',
//             okButtonProps: { className: "pink ant-btn-lg" },
//         })
// }
// // export const successAlert = ({title, content, refreshYn, refreshKey}: alertProps) => {
// //     const alertTitle = title === undefined ? "알림" : title
// //     const alertRefreshYn = refreshYn === undefined ? false : refreshYn;
// //     return alertRefreshYn ?
// //         modal.success({
// //             title: alertTitle,
// //             content: convertContent(content),
// //             okText: '확인',
// //             afterClose: () => refreshKey!(true),
// //             okButtonProps: { className: "pink ant-btn-lg" },
// //         })
// //         :
// //         modal.success({
// //             title: alertTitle,
// //             content: convertContent(content),
// //             okText: '확인',
// //             okButtonProps: { className: "pink ant-btn-lg" },
// //         })
// // }
// //


