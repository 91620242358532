import Layout, { Content, Footer, Header } from "antd/es/layout/layout";
import { Outlet } from "react-router-dom";
import CommonFooter from "./commonFooter";
import CommonHeader from "./commonHeader";


const CommonLayout = () => {
    return (
    <>
        <Layout className="layout">
            {/* 헤더 */}
            <Header>
                <CommonHeader />
            </Header>
            {/* 페이지 컴포넌트 */}
            <Content>
                <div className="site-layout-content">
                    <div className={"content-body"}>
                        <Outlet />
                    </div>
                </div>
            </Content>
            {/* 푸터 */}
            <Footer>
                <CommonFooter />
            </Footer>
        </Layout>
    </>
    );
}

export default CommonLayout;