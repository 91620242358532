import React, {useEffect, useState} from "react";
import {Button, Card, DatePicker, Flex, Form, Modal, Radio, Space, Table, Typography} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import dayjs from "dayjs";
import SkeletonTable, {SkeletonTableColumnsType} from "../../../table/skeletonTable";
import {emptyTableLayout, paginationOptions} from "../../../../functions/tableFormatter";
import {ColumnsType} from "antd/es/table";
import apiCall from "../../../../functions/apiCall";
import {AxiosResponse} from "axios";

export type KeywordBidDownloadParams = {
    openYn: boolean,
    downloadType: string,
    downloadDate: string,
    downloadYn: boolean
}

interface KeywordBidDownloadModalProps {
    params: KeywordBidDownloadParams
    setParams: React.Dispatch<KeywordBidDownloadParams>
    kwdBidDownloadGridRefresh: boolean
    setKwdBidDownloadGridRefresh: React.Dispatch<boolean>
}

interface TaskReqRowProps {
    taskId: number,
    taskName: string,
    taskDiv: string,
    taskStatus: string,
    taskAllRowCnt: number,
    taskSuccessRowCnt: number,
    taskReqMemberId: string,
    taskReqTime: string,
    taskResultFile: any
}

const KeywordBidDownloadModal = ({
                                     params,
                                     setParams,
                                     kwdBidDownloadGridRefresh,
                                     setKwdBidDownloadGridRefresh
                                 }: KeywordBidDownloadModalProps) => {
    const [taskReqRows, setTaskReqRows] = useState<TaskReqRowProps[]>([]);
    const [gridLoading, setGridLoading] = useState<boolean>(true)


    const gridColumns: ColumnsType<TaskReqRowProps> = [
        {title: 'No', dataIndex: 'index', align: 'center', render: (_, record, index) => index + 1},
        {title: '구분', dataIndex: 'taskName', align: 'center'},
        {title: '요청 시간', dataIndex: 'taskReqTime', align: 'center'},
        {title: '상태', dataIndex: 'taskStatus', align: 'center'},
        {
            title: '결과 파일', dataIndex: 'taskResultFile', align: 'center', render: (_, record) => {
                if (record.taskStatus === '진행') {
                    return <></>
                } else if (record.taskStatus === '실패') {
                    return <></>
                } else {
                    return (<Button className="pink" icon={<i className="ico ico-download"></i>} size="small"
                                    onClick={() => taskFileDownloadEvent(record.taskResultFile)}
                                    disabled={record.taskResultFile === undefined}/>
                    );
                }
            }
        },
    ]

    /** 작업 내역 => 최근 1주일간 내역 조회 */
    const loadTaskReqList = () => {
        setGridLoading(true)
        apiCall.get("/keywordBid/bidKwd/taskReqList")
            .then(resp => setTaskReqRows(resp.data))
            .finally(() => setGridLoading(false));
    }

    /** 작업 내역 => 결과 파일 다운로드 */
    const taskFileDownloadEvent = (filePath: string) => {
        apiCall.post("/keywordBid/bidKwd/downloadFile", filePath, {
            responseType: 'blob'
        })
            .then(resp => {
                // 다운로드 파일 이름을 추출하는 함수
                const extractDownloadFilename = (resp: AxiosResponse<any, any>) => {
                    const disposition = resp.headers["content-disposition"];
                    return decodeURI(
                        disposition
                            .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
                            .replace(/['"]/g, "")
                    );
                };
                const blob = new Blob([resp.data]);

                const fileObjectUrl = window.URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = fileObjectUrl;
                link.style.display = "none";
                link.download = extractDownloadFilename(resp);
                document.body.appendChild(link);
                link.click();
                link.remove();

                window.URL.revokeObjectURL(fileObjectUrl);
            })
            .catch(error => {
                error("결과 파일 다운로드 도중 에러가 발생했습니다.<br />관리자에게 문의해주세요.", {error});
            });
    }

    /** 페이지 진입 시 실행 */
    useEffect(() => {
        loadTaskReqList();
    }, []);

    /** 다운로드 버튼 클릭시 테이블 그리드 리프레쉬를 위한 UseEffect */
    useEffect(() => {
        if (kwdBidDownloadGridRefresh) {
            loadTaskReqList();
            setKwdBidDownloadGridRefresh(false)
        }
    }, [kwdBidDownloadGridRefresh]);

    return (
        <>
            <Modal
                title="입찰 관리 다운로드"
                open={params.openYn}
                onCancel={() => setParams({...params, openYn: false})}
                width={1200}
                footer={[
                    <Button type="primary" className="gray" size="large" key="closeBtn"
                            onClick={() => setParams({...params, openYn: false})}>확인</Button>
                ]}
            >
                {/* <!-- Wrap-Datagrid : Start --> */}
                <section className="wrap-section wrap-datagrid">
                    <div className="box-body">
                        {/* <!-- Wrap-Filter : Start --> */}
                        <section className="wrap-section wrap-filter">
                            <div className="box-body">
                                <Card>
                                    <Flex gap={24}>
                                        <div className="box-center">
                                            <Form>
                                                <Space size={[32, 24]} wrap>
                                                    <Form.Item label={"파일선택"} colon={false}>
                                                        <Radio.Group
                                                            onChange={(e) => setParams({
                                                                ...params,
                                                                downloadType: e.target.value
                                                            })}
                                                            value={params.downloadType}
                                                        >
                                                            <Radio value={"KEYWORD_BID_LIST"}>키워드 입찰 목록</Radio>
                                                            <Radio value={"BID_LOG_HISTORY"}>입찰 로그 이력</Radio>
                                                            {params.downloadType === "BID_LOG_HISTORY" ?
                                                                <DatePicker
                                                                    locale={locale}
                                                                    format="YYYY-MM-DD"
                                                                    value={dayjs(params.downloadDate)}
                                                                    allowClear={false}
                                                                    disabledDate={(current) => current > dayjs().endOf('day')}
                                                                    onChange={(date) => {
                                                                        setParams({
                                                                            ...params,
                                                                            downloadDate: dayjs(date).format('YYYYMMDD')
                                                                        })
                                                                    }}
                                                                />
                                                                : null
                                                            }
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Space>
                                            </Form>
                                        </div>
                                        <div className="box-right">
                                            <Button type="primary" className="pink" onClick={() => {
                                                setParams({
                                                    ...params,
                                                    openYn: true,
                                                    downloadYn: true
                                                })
                                            }}>다운로드</Button>
                                        </div>
                                    </Flex>
                                </Card>
                            </div>
                        </section>
                        <div className="box-header">
                            <Typography.Text className="fz-14 fc-pink-300">* 최근 1주일간 다운로드 내역을 제공합니다.</Typography.Text>
                        </div>
                        <SkeletonTable loading={gridLoading} columns={gridColumns as SkeletonTableColumnsType[]}>
                            <Table
                                key={"bidKwdMngTaskReq"}
                                rowKey={"taskReqId"}
                                columns={gridColumns}
                                dataSource={taskReqRows}
                                locale={emptyTableLayout(["최근 1주일간 다운로드 내역이 없습니다."])}
                                bordered
                                pagination={paginationOptions(taskReqRows)}
                            />
                        </SkeletonTable>
                    </div>
                </section>
            </Modal>
        </>
    )
};

export default KeywordBidDownloadModal;