import React, {SetStateAction, useEffect, useState} from "react";
import {Button, Card, Col, Flex, Form, Row} from "antd";
import {KeywordBidManageSearchProps} from "../../../pages/kwdBid/kwdBidTypes";
// Import - Icon
import KeywordBidManageDefaultFilter from "./keywordBidManageDefaultFilter";
import KeywordBidManageDetailFilter from "./keywordBidManageDetailFilter";
import KeywordBidManageContentHeader from "./keywordBidManageContentHeader";
import apiCall from "../../../functions/apiCall";

/** 조회 필터 => 컴포넌트 타입 */
interface KeywordBidManageFilterProps {
    searchParams: KeywordBidManageSearchProps,
    setSearchParams: React.Dispatch<SetStateAction<KeywordBidManageSearchProps>>
}

const KeywordBidManageFilterComponent = ({searchParams, setSearchParams}: KeywordBidManageFilterProps) => {
    //광고주 리스트 로드
    const [ advList, setAdvList] = useState<{advId: number, advName: string}[]>([{advId: 0, advName: "전체"}]);

    const allSearchFilterReset = () => {
        setSearchParams({
            advId : 0, campId : 'ALL', groupId : 'ALL',
            kwdName : '', kwdSearchDiv : 'EXIST',
            bookmarkDiv: 'ALL', bidConfigYn : 'ALL', bidItemDiv : 'ALL', adAreaDiv : 'ALL',
            scheduleId : 0,
            pageNumber : 1, pageSize : 10,
            sortColumn : "kwdName", sortOrder : 'ascend',
            searchEventYn: false,  })
    }
    useEffect(() => {
        apiCall.get("/keywordBid/bidKwd/loadAdvList")
            .then(resp => {
                //전체 추가
                let copyAdvList = [...resp.data];
                copyAdvList.unshift({advId: 0, advName: "전체"});
                setAdvList(copyAdvList);
            })
    }, [])
    return (
        <>
            <KeywordBidManageContentHeader
                pageTitle={"키워드 입찰 관리"} navigation={["키워드 입찰", "키워드 입찰 관리"]}
                advList={advList}
                searchParams={searchParams} setSearchParams={setSearchParams}
            />

            {/* <!-- Wrap-Filter : Start --> */}
            <section className="wrap-section wrap-filter">
                <div className="box-body">
                    <Card>
                        <Flex gap={24}>
                            <div className="box-left">
                                <i className="ico ico-filter"></i>
                            </div>
                            <div className="box-center">
                                <Form>
                                    {/* 기본 조회 필터 항목 : Start */}
                                    <KeywordBidManageDefaultFilter
                                        advList={advList} searchParams={searchParams} setSearchParams={setSearchParams}
                                    />
                                    {/* 기본 조회 필터 항목 : End */}

                                    {/* 상세 조회 필터 항목 : Start */}
                                    <KeywordBidManageDetailFilter
                                        searchParams={searchParams} setSearchParams={setSearchParams}
                                    />
                                    {/* 상세 조회 필터 항목 : End */}
                                    {/*<Row>
                                        <Col span={24}>
                                            <Button type="primary" className="pink expand" onClick={()=> setSearchParams({...searchParams, searchEventYn: true})}>조회하기</Button>
                                        </Col>
                                    </Row>
                                     나중에 필터 초기화 작업할 때 주석 풀자.*/}
                                    <Row className="box-btn">
                                        <Col span={24}>
                                            <Button className="gray" onClick={allSearchFilterReset}>필터 초기화</Button>
                                            <Button type="primary" className="pink" onClick={()=> setSearchParams({...searchParams, pageNumber: 1, searchEventYn: true})}>조회하기</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Flex>
                    </Card>
                </div>
            </section>
            {/* <!-- Wrap-Filter : End --> */}
        </>
    )
};

export default React.memo(KeywordBidManageFilterComponent);