import {Button, Input, Modal, Segmented} from "antd";
import React, {useEffect, useState} from "react";
import {KeywordBidManageGridProps} from "../../../../pages/kwdBid/kwdBidTypes";
import {errorAlert, successAlert} from "../../../../functions/alertFn";
import apiCall from "../../../../functions/apiCall";

/** 키워드 입찰 관리 - 경쟁사 대응 입찰 설정 모달 */
interface RivalRegModalProps {
    selectRow: KeywordBidManageGridProps,
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setGridRefresh: React.Dispatch<React.SetStateAction<boolean>>
}
const RivalRegModal = ({selectRow, isOpen, setOpen, setGridRefresh}: RivalRegModalProps) => {

    const [ rival, setRival ] = useState<{rivalConfigDiv : string, rivalDomain: string}>({rivalConfigDiv : "OFF", rivalDomain: ""});

    /** 경쟁사 등록 이벤트 */
    const regRivalDomainEvent = () => {
        if (rival.rivalConfigDiv === "") {
            errorAlert("설정값을 선택해 주세요.");
            return;
        }

        if(rival.rivalDomain === "") {
            errorAlert("도메인을 입력해 주세요.")
            return;
        }

        apiCall.post(`/keywordBid/bidKwd/${selectRow.bidKwdId}/rival`, rival)
            .then(() => successAlert("경쟁사 대응 입찰 설정 완료했습니다."))
            .catch(error => errorAlert(error.response.data.message))
            .finally(() => {
                setOpen(false);
                setGridRefresh(true);
            });
    }

    useEffect(() => {
        const pageInit = () => {
            setRival({rivalConfigDiv: selectRow.rivalConfigDiv ?? "OFF", rivalDomain: selectRow.rivalDomain ?? ""})
        }
        pageInit()
    },[]);
    return (
        <>
            <Modal
                title={'경쟁사 대응 입찰 설정'}
                focusTriggerAfterClose={true}
                open={isOpen}
                maskClosable={false} // 모달 외부 클릭 시 닫히지 않게 설정
                onCancel={() => {setOpen(false); }}
                afterClose={() => {setOpen(false); }}
                width={1200}
                footer={[
                    <Button type="primary" className="gray" size="large" key="footerCancelBtn" onClick={() => {setOpen(false); }}>취소</Button>,
                    <Button type="primary" className="pink" size="large" key="footerCloseBtn" onClick={regRivalDomainEvent}>저장</Button>,
                ]}
            >
                {/* <!-- Wrap-Help : Start --> */}
                <section className="wrap-section wrap-help">
                    <ul className="box-body">
                        <li className="item-help">
                            {/*<RightCircleOutlined />*/}
                            <span className="fz-14 fc-pink-500"> 도메인은 네이버 표시 URL 기준으로 입력해주세요.</span>
                        </li>
                        <li className="item-help">
                            <span className="fz-14 fc-pink-500">도메인을 정확하게 입력하지 않을 시 경쟁사 대응 입찰이 진행되지 않습니다.</span>
                        </li>
                    </ul>
                </section>
                {/* <!-- Wrap-Help : End --> */}

                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">설정</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Segmented
                                            defaultValue={"OFF"}
                                            value={rival.rivalConfigDiv}
                                            options={[
                                                {label: '위', value: 'UP'},
                                                {label: '아래', value: 'DOWN'},
                                                {label: 'OFF', value: 'OFF'},
                                            ]}
                                            onChange={(value) => {
                                                setRival({...rival, rivalConfigDiv: value.toString()})
                                            }}
                                        />
                                    </div>

                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">도메인</span>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Input className="w-300" name="rivalDomain" placeholder="nbidon.com"
                                               value={rival.rivalDomain}
                                               onChange={(e) => {
                                            const { value } = e.target;
                                            const domain = value.replace(/https?:\/\/|www\./g, "");
                                            setRival({...rival, rivalDomain: domain})
                                        }}/>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}
            </Modal>
        </>
    )
};

export default RivalRegModal;