const CommonFooter = () => {
    return (
    <>
        <div className="box-left">
            <div className="company-info">
                <span className="fz-14 fw-exbold fc-gray-400">NHNAD</span>
                <span className="fz-14 fw-reg fc-gray-300">사업자등록번호: 144-81-27690</span>
                <span className="fz-14 fw-reg fc-gray-300">대표: 심도섭</span>
                <span className="fz-14 fw-reg fc-gray-300">전화번호: 1800-0704</span>
                <span className="fz-14 fw-reg fc-gray-300">주소: 경기도 성남시 분당구 대왕판교로 645번길 16 플레이뮤지엄 5층</span>
            </div>
        </div>
        <div className="box-right">
            <span className="fz-14 fw-reg fc-gray-300">Copyright NHN AD. All right reserved.</span>
        </div>
    </>
    );
}

export default CommonFooter;