import ContentHeader from "../../components/layout/contentHeader";
import React, {useEffect, useState} from "react";
import {Button, Card, Flex, Form, Input, Popover, Space, Table, Tag, Typography} from "antd";
import {AdvManageGridProps, AdvManageSearchProps} from "./connectionMngTypes";
import SkeletonTable, {SkeletonTableColumnsType} from "../../components/table/skeletonTable";
import {emptyTableLayout, paginationOptions} from "../../functions/tableFormatter";
import {errorAlert, successAlert} from "../../functions/alertFn";
import modal from "antd/es/modal";
import {ExclamationCircleFilled} from "@ant-design/icons";
import RegAdvModal from "../../components/connectionMng/advManage/regAdvModal";
import apiCall from "../../functions/apiCall";
import {ColumnsType} from "antd/es/table";
// Typography
const { Title, Text } = Typography;

const AdvManage = () => {
    /** 공통 */
    const [ loading, setLoading ] = useState<boolean>(false);
    /** 조회 필터 */
    const [ searchParams, setSearchParams ] = useState<AdvManageSearchProps>({advLoginId:'', advName:''});
    /** 그리드 */
    const [ gridLoading, setGridLoading ] = useState<boolean>(true);
    const [ rows, setRows] = useState<any[]>([]);
    const [ gridRefresh, setGridRefresh ] = useState<boolean>(false);
    const [ deleteRowKeys, setDeleteRowKeys ] = useState<React.Key[]>([]); // 그리드 외부에서 삭제 시 사용
    const [ modifyAdvName, setModifyAdvName] = useState<string>();
    const [ popoverTargetRow, setPopoverTargetRow ] = useState<AdvManageGridProps>();
    const [ popoverOpenYn, setPopoverOpenYn ] = useState<boolean>(false);
    const rowSelection = { selectedRowKeys: deleteRowKeys, onChange: (key: React.Key[]) => setDeleteRowKeys(key) };

    /** 모달 */
    const [ regAdvModalOpenYn, setRegAdvModalOpenYn ] = useState<boolean>(false);


    const popoverOpenEvent = (record: AdvManageGridProps) => {
        return popoverTargetRow !== undefined && (popoverTargetRow.advId === record.advId) && popoverOpenYn;
    }
    /** 광고주명 변경 */
    const modifyAdvNameEvent = (record: AdvManageGridProps) => {
        const params = {advId:record.advId,  advLoginId : record.advLoginId, advName : modifyAdvName};
        if(modifyFormValidation()){
            apiCall.put("/connMng/advMng/updateAdvName", params)
            .then(resp => {
                successAlert("광고주 명이 변경되었습니다.")
                setModifyAdvName(undefined);
                setPopoverTargetRow(undefined);
                setPopoverOpenYn(false);
                setGridRefresh(true);
            })
            .catch(error => {
                errorAlert("광고주 명 변경시 오류가 발생했습니다.<br />관리자에게 문의바랍니다.", {error});
            })
            .finally();
        }
    }
    /** 광고주 삭제 */
    const deleteAdvEvent = (type: string, options?:{advId : number[]}) => {
        //그리드 내부와 외부 클릭 시 바라보는 대상이 다름.
        const deleteAdvIds = type === "DIRECT" ? options!!.advId : deleteRowKeys;
        if(deleteAdvIds.length === 0) {
            errorAlert("삭제할 광고주를 선택 후 다시 클릭해주세요.");
        } else {
            modal.confirm({
                icon: <ExclamationCircleFilled rev={undefined} />,
                title: '광고주를 삭제하시겠습니까?',
                content: '삭제된 광고주는 Nbidon에서 더이상 사용 불가합니다.',
                okText: '확인', cancelText: '취소',
                okButtonProps: { className: "pink ant-btn-lg" },
                onOk() {
                    apiCall.put("/connMng/advMng/deleteAdvList", deleteAdvIds)
                    .then(resp => {
                        successAlert("광고주 삭제 완료되었습니다.");
                        setGridRefresh(true);
                        setDeleteRowKeys([]);
                    })
                    .catch(error => {
                        errorAlert("API 삭제 시 오류가 발생했습니다.<br />관리자에게 문의바랍니다.", {error});
                    })
                    .finally();
                },
                cancelButtonProps: { type: 'primary', className: "gray ant-btn-lg" },
            })
        }
    }

    /** 데이터 그리드 로드 */
    const pageInit = () => {
        setGridLoading(true)
        apiCall.post("/connMng/advMng/findAdvList",searchParams)
        .then(resp => {
            setLoading(false);
            setDeleteRowKeys([]);
            setGridLoading(false);
            setRows(resp.data);
        })
        .catch(error => {
            errorAlert("광고주 목록 데이터가 정상적으로 로드 되지 않습니다.<br />관리자에게 문의바랍니다.", {error});
        })
        .finally(() => {})

    }

    //페이지 로드
    useEffect(() => {
        setLoading(true);
        pageInit();
        document.title = "광고주 관리";
    }, []);
    //화면 리프래시
    useEffect(() => {
        if(gridRefresh) {
            setLoading(true);
            pageInit();
            setGridRefresh(false);
        }
    }, [gridRefresh]);

    //광고주명 수정 벨리데이션
    const modifyFormValidation = () => {
        if(modifyAdvName === undefined || modifyAdvName === '' ) {
            errorAlert("광고주 명을 입력 해주세요.");
            return  false;
        }
        return true;
    }

    // 그리드 칼럼
    const gridColumns: ColumnsType<any> = [
        {title: 'No', dataIndex: 'index', align: 'center', render: (_, record, index) => index+1},
        {title: '광고주 로그인 ID', dataIndex: 'advLoginId', align: 'center' },
        {title: 'Customer Id', dataIndex: 'customerId', align: 'center' },
        {title: '광고주 명', dataIndex: 'advName', align: 'center',
            render: (text, record, index) => (
                <>
                    <div className="cell-divide" key={index}>
                        <div className="box-left">
                            <Text className="fc-gray-500">{text}</Text>
                        </div>
                        <div className="box-right">
                            <Popover
                                content={<>
                                    <Input placeholder="변경할 광고주명을 입력하세요." style={{ width: 250 }}
                                           value={modifyAdvName}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                                               setModifyAdvName(e.target.value);
                                           }} />
                                    <Button type="primary" className="gray" htmlType="reset" onClick={(e) => {
                                        setPopoverTargetRow(undefined);
                                        setModifyAdvName(undefined);
                                    }}>취소</Button>
                                    <Button type="primary" className="pink" htmlType="submit" onClick={()=> {
                                        modifyAdvNameEvent(record);
                                    }}>적용</Button>
                                </>}
                                trigger="click"
                                placement="right"
                                key={"advNamePopover_"+record.advId}
                                open={popoverOpenEvent(record)}
                                onOpenChange={()=> setModifyAdvName(undefined)}
                            >
                                <Button className="pink" icon={<i className="ico ico-modify"></i>} size="small"
                                        onClick={()=> {setPopoverTargetRow(record); setPopoverOpenYn(true); }}
                                />
                            </Popover>
                        </div>
                    </div>
                </>
            )
        },
        {title: '연동 상태', dataIndex: 'linkPossYn', align: 'center', render: (value:boolean) => {
                return value ?
                    <Tag color={"blue"} key={"blue"}>{"원활"}</Tag> : <Tag color={"red"} key={"red"}>{"에러"}</Tag>;
            }
        },
        {title: '삭제', dataIndex: 'modifyApiBtn', align: 'center', render: (_, record) => {
                return <Button className="pink" size="small" onClick={() => {
                    deleteAdvEvent("DIRECT", { advId : [record.advId]});
                }}>삭제</Button>
            }
        },
    ]
    return (
        <>
            <ContentHeader pageTitle={"광고주 관리"} navigation={["연동 관리", "광고주 관리"]}/>
            {/* <!-- Wrap-Filter : Start --> */}
            <section className="wrap-section wrap-filter">
                {/*<div className="box-header">
                    <div className="box-left">
                        <Title level={3} className="fc-gray-700">조회 필터</Title>
                    </div>
                </div>*/}
                <div className="box-body">
                    <Card>
                        <Flex gap={24}>
                            <div className="box-left">
                                <i className="ico ico-filter"></i>
                            </div>
                            <div className="box-center">
                                <Form>
                                    <Space size={32} wrap>
                                        <Form.Item label="광고주 로그인 ID" colon={false}>
                                            <Input placeholder="광고주 로그인 ID를 입력해주세요"
                                                   className="w-300"
                                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const {value} = e.target;
                                                    setSearchParams({ ...searchParams, advLoginId: value});
                                                }} onPressEnter={pageInit}
                                            />
                                        </Form.Item>
                                        <Form.Item label="광고주 명" colon={false}>
                                            <Input placeholder="광고주 명을 입력해주세요"
                                                   className="w-300"
                                                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                       const {value} = e.target;
                                                       setSearchParams({ ...searchParams, advName: value});
                                               }} onPressEnter={pageInit}
                                            />
                                        </Form.Item>
                                    </Space>
                                </Form>
                            </div>
                            <div className="box-right">
                                <Button type="primary" className="pink" onClick={pageInit}>조회하기</Button>
                            </div>
                        </Flex>
                    </Card>
                </div>
            </section>
            {/* <!-- Wrap-Filter : End --> */}

            {/* <!-- Wrap-Datagrid : Start --> */}
            <section className="wrap-section wrap-datagrid">
                <div className="box-header">
                    <div className="box-left">
                        <Title level={3} className="fc-gray-700">광고주 목록</Title>
                    </div>
                    <div className="box-right">
                        <Button type="primary" className="pink" onClick={() => setRegAdvModalOpenYn(true)}>광고주 등록</Button>
                        <Button type="primary" className="gray" onClick={() => deleteAdvEvent("MULTI")}>삭제</Button>
                    </div>
                </div>
                <div className="box-body">
                    <SkeletonTable loading={gridLoading} columns={gridColumns as SkeletonTableColumnsType[]}>
                        <Table
                            key={"advGrid"}
                            columns={gridColumns}
                            bordered
                            loading={loading}
                            dataSource={rows}
                            rowKey={(record) => record.advId}
                            rowSelection={rowSelection}
                            locale={emptyTableLayout(["등록된 광고주가 없습니다.","[광고주 등록] 버튼을 클릭하여 광고주를 등록하세요."])}
                            pagination={paginationOptions(rows)}
                        />
                    </SkeletonTable>
                </div>
            </section>
            {/* <!-- Wrap-Datagrid : End --> */}

            {/* 광고주 등록 모달 */}
            { regAdvModalOpenYn &&
                <RegAdvModal
                    isOpen={regAdvModalOpenYn}
                    setOpen={setRegAdvModalOpenYn}
                    setGridRefresh={setGridRefresh}
                />
            }
        </>
    )
};

export default AdvManage;