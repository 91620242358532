import React, {useState} from 'react';
import {Button, DatePicker, Input, Modal, Select, Table} from "antd";
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from "dayjs";
import {errorAlert, successAlert} from "../../../functions/alertFn";
import apiCall from "../../../functions/apiCall";
// DatePicker - Range
const { RangePicker } = DatePicker;
const { Option } = Select;

interface ProdChargeModalProps {
    record: any,
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setGridRefresh: React.Dispatch<React.SetStateAction<boolean>>

}

const ProductChargeModal = ({record, isOpen, setOpen, setGridRefresh }: ProdChargeModalProps) => {
    //선택한 사용자
    const [ memberId, setMemberId ] = useState<string>();
    //지정한 날짜
    const [ useRangeDate, setUseRangeDate ] = useState<{startDate : string | undefined, endDate : string | undefined}>();

    const [ productList, setProductList ] = useState<{productId: string, productName: string, productCount: string}[]>([
        {productId: "TARGET", productName: "타겟", productCount: ''},
        {productId: "UNIQUE", productName: "유니크", productCount: ''},
        {productId: "PREMIUM", productName: "프리미엄", productCount: ''},
        {productId: "BASIC", productName: "베이직", productCount: ''},
    ]);

    //모달 초기화
    const modalReset = () => {
        setMemberId(undefined);
        setUseRangeDate(undefined);
        setGridRefresh(true);
    }

    // 현재 날짜보다 이전인 경우 true 반환하여 비활성화
    const currentDate = dayjs();
    const disabledDate = (current:any) => {
        return current && current < currentDate.startOf('day');
    };

    /** 상품별 개수 입력 */
    const onChangeProdCountEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setProductList(productList.map((item) => {
            return item.productId === name ? {...item, productCount: value} : item
        }));
    }
    //상품 충전 이벤트 벨리데이션
    const chargeValidation = () => {
        if(memberId === undefined || memberId === '') {
            errorAlert("사용자를 선택해주세요.");
            return false;
        }
        if(useRangeDate === undefined || useRangeDate.startDate === ('' || undefined) || useRangeDate.endDate === ('' || undefined)) {
            errorAlert("사용 기간을 선택해주세요.");
            return false;
        }
        const numberTypeRegex = /^[0-9]+$/;
        const notNumberTypeCnt = productList.filter((item)=> !numberTypeRegex.test(item.productCount) && item.productCount !== '').length;
        if(notNumberTypeCnt > 0) {
            errorAlert("상품 충전 갯수는 숫자만 입력 가능합니다.");
            return false;
        }
        return true;
    }

    //상품 충전 이벤트
    const prodChargeEvent = () => {
        if(chargeValidation()) {
            //상품별로 값 가져옴.
            const params = {
                memberId : memberId,
                startDate : useRangeDate?.startDate,
                endDate: useRangeDate?.endDate,
                chargeProdInfo: productList,
            };

            const allProductCountsEmpty = params.chargeProdInfo.every(item => item.productCount === '');
            if(allProductCountsEmpty) {
                errorAlert("상품 개수를 입력해 주세요.")
                return;
            }

            apiCall.post("/item/charge/save", params)
                .then(resp => {
                    successAlert("상품 충전이 완료되었습니다.")
                    modalReset();
                    setGridRefresh(true);
                    setOpen(false);
                    // setAllTimeConfigList(convertAllTimeConfigListData(resp.data));
                    // setCreateConfigEvent(true); //이벤트 발생 여부 체크
                })
                .catch(error => {
                    errorAlert("상품 충전 정보를 확인해 주세요.", {error});
                    // errorAlert("상품 충전 중 에러가 발생했습니다..<br />관리자에게 문의해주세요.", {error});

                })
                .finally(() => {
                    ////시작시간, 종료시간 초기화
                    // setStartTime(null);
                    // setEndTime(null);
                    // 목표설정, 목표순위 초기화
                    //// setHopeRankType(null);
                    // setHopeRank(null);
                })
        }
    }

    return (
        <>
            <Modal
                title="상품 충전"
                open={isOpen}
                onCancel={() => {modalReset(); setOpen(false); }}
                afterClose={() => modalReset()}
                width={900}
                footer={[
                    <Button type="primary" className="gray" size="large" key="footerCancelBtn" onClick={() => {modalReset(); setOpen(false); }}>취소</Button>,
                    <Button type="primary" className="pink" size="large" key="footerChargeBtn" onClick={prodChargeEvent}>충전</Button>,
                ]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    <div className="box-body">
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">사용자</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Select placeholder="사용자 ID 선택하세요." style={{ width: 400 }} value={memberId}
                                                onChange={(memberId: string) => setMemberId(memberId)}>
                                            {record.map((item: any) => {
                                                return <Option key={item.memberId} value={item.memberId} label={item.memberId}>{item.memberId}</Option>
                                            })}
                                        </Select>
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">사용 기간</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <RangePicker locale={locale}
                                                     value={
                                                         [useRangeDate !== undefined && useRangeDate.startDate !== undefined ?
                                                             dayjs(useRangeDate.startDate) : null,
                                                             useRangeDate !== undefined && useRangeDate.endDate !== undefined ?
                                                                 dayjs(useRangeDate.endDate) : null
                                                         ]
                                                     }
                                                     onChange={(dateValues:any) => {
                                                         const startDate = dayjs(dateValues[0]).format("YYYYMMDD");
                                                         const endDate = dayjs(dateValues[1]).format("YYYYMMDD");
                                                         setUseRangeDate({startDate, endDate});
                                                     }}
                                                     disabledDate={disabledDate}
                                        />
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">충전 상품</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        {/* <!-- Wrap-Datagrid : Start --> */}
                                        <section className="wrap-section wrap-datagrid">
                                            <div className="box-body">
                                                <Table
                                                    id={"prodChargeGrid"}
                                                    rowKey={"productId"}
                                                    columns={[
                                                        {title: 'No', dataIndex: 'index', align: 'center', render: (_, record, index) => index+1},
                                                        {title: '상품', dataIndex: 'productName', align: 'center' },
                                                        {title: '개수', dataIndex: 'productCount', align: 'center', render: (text: string, record) =>
                                                                <Input className="w-100" suffix="개" name={record.productId} onChange={onChangeProdCountEvent}/>
                                                        }
                                                    ]}
                                                    dataSource={productList}
                                                    bordered
                                                    pagination={false}
                                                />
                                            </div>
                                        </section>
                                        {/* <!-- Wrap-Datagrid : End --> */}
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}
            </Modal>
        </>
    );
};
export default React.memo(ProductChargeModal);