import { Skeleton, SkeletonProps, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

/* 어디선가 떠돌던 소스 복붙.
*  로딩 값(true/false) 에 따라 각 행마다 스켈레톤 형태로 보여주는 테이블이다.
*  로딩 완료 후에는 Table 컴포넌트가 표시되면서 NO DATA / 데이터 리스트 를 보여주는 형태로 사용.
*  사용방법
*  <SkeletonTable loading={로딩중여부} columns={실제보여질테이블헤더 as SkeletonTableColumnsType[]}>
       <Table rowKey={(record) => record.유니크키값}
              columns={tableColumns}
              dataSource={로드된데이터}
              bordered
              ...
       />
   </SkeletonTable>
* */
export type SkeletonTableColumnsType = {
    key: string;
};

type SkeletonTableProps = SkeletonProps & {
    columns: ColumnsType<SkeletonTableColumnsType>;
    rowCount?: number;
};

export default function SkeletonTable({loading = false, active = true, rowCount = 5, columns, children, className}:
                                          SkeletonTableProps): JSX.Element {
    return loading ? (
        <Table
            rowKey="key"
            pagination={false}
            bordered={true}
            dataSource={[...Array(rowCount)].map((_, index) => ({
                key: `key${index}`,
            }))}
            columns={columns.map((column) => {
                return {
                    ...column,
                    render: function renderPlaceholder() {
                        return (
                            <Skeleton key={column.key} title active={active} paragraph={false} className={className}
                            />
                        );
                    },
                };
            })}
        />
    ) : (
        <>{children}</>
    );
}