import React, {useEffect, useState} from 'react';
import {errorAlert, successAlert} from "../../../functions/alertFn";
import SkeletonTable, {SkeletonTableColumnsType} from "../../table/skeletonTable";

import {Button, Card, Flex, Form, Input, Modal, Select, Space, Table, Typography} from "antd";
import {RightCircleOutlined} from '@ant-design/icons';
import {paginationOptions} from "../../../functions/tableFormatter";
import apiCall from '../../../functions/apiCall';
import {ColumnsType} from "antd/es/table";

const { Title, Text } = Typography;
const { Option } = Select;

interface RegAdvModalProps {
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setGridRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

interface RegAdvModalGridProps {
    apiLicenseId:number, advLoginId: string, customerId: number, advName: string
}

const RegAdvModal = ({ isOpen, setOpen, setGridRefresh }: RegAdvModalProps) => {
    const [ connAdvList, setConnAdvList ] = useState<{apiLoginId: string, apiLicenseId:string}[]>([]); // API 로그인 ID 리스트
    //const [ selectedApiLoginId, setSelectedApiLoginId] = useState<string | undefined>(); // 최초 로드 시 || 선택한 API 로그인 ID
    const [ selectedApiLicenseId, setSelectedApiLicenseId ] = useState<string | undefined>();

    /** 그리드 */
    const [ gridLoading, setGridLoading ] = useState<boolean>(true);
    const [ rows, setRows ] = useState<RegAdvModalGridProps[]>([]); // 연동 광고주 목록 리스트
    const [ editRows, setEditRows ] = useState<RegAdvModalGridProps[]>([]); // 광고주 명 입력 시 적재될 광고주 목록 리스트

    const [ selectedRowKeys, setSelectedRowKeys ] = useState<React.Key[]>([]); // 등록 시도할 광고주 키값 리스트(checkbox)
    const rowSelection = { selectedRowKeys: selectedRowKeys, onChange: (keys: React.Key[]) => setSelectedRowKeys(keys) };

    /** 광고주 등록 모달 초기화 */
    const modalReset = () => {
        setConnAdvList([]);
        //setSelectedApiLoginId(undefined);
        setRows([]);
        setEditRows([]);
        setSelectedRowKeys([]);
    }
    /** api_license 테이블의 login_id값 */
    const loadApiLoginIds = () => {
        apiCall.get("/connMng/advMng/findApiLoginIdList")
        .then(resp => {
          // 데이터가 빈 배열인 경우 처리
            if (resp.data.length === 0) {
                setGridLoading(false)
            } else {
                setConnAdvList(resp.data);
                //setSelectedApiLoginId(resp.data[0].apiLoginId);
                setSelectedApiLicenseId(resp.data[0].apiLicenseId);
                loadAdvListByApiLoginId(resp.data[0].apiLicenseId);
            }
        })
        .catch(error => {
            errorAlert("API 로그인 목록 데이터가 정상적으로 로드 되지 않습니다.<br />관리자에게 문의바랍니다.", {error});
        })
        .finally(() => {});
       
    }

    /** API 로그인 ID 변경 시 연동 가능한 광고주 리스트 로드 */
    const loadAdvListByApiLoginId = (apiLicenseId?:string) => {
        const param = apiLicenseId === undefined ? selectedApiLicenseId : apiLicenseId;
        if(param === undefined) {
            errorAlert("조회할 수 있는 API 로그인 ID가 존재하지 않습니다.");
            return false;
        }
        setGridLoading(true)
        apiCall.post("/connMng/advMng/findConnAdvListByApiLoginId", param)
        .then(resp => {
            setRows(resp.data);
            setEditRows(resp.data);
            setSelectedRowKeys([]);
            setGridLoading(false);
        })
        .catch(error => {
            errorAlert("API 로그인 목록 데이터가 정상적으로 로드 되지 않습니다.<br />관리자에게 문의바랍니다.", {error});
        }).finally(() => {});
       
    }

    /** 광고주 명 변경. 각각의 로우 단위로 처리한다. */
    const onChangeAdvNameEvent = (record: {advLoginId: string, customerId: number, advName: string}, e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setEditRows(editRows.map((item) => {
            return item.advLoginId === record.advLoginId ? {...item, advName: value} : item
        }));

    }

    /** 광고주 등록. */
    const regAdvEvent = () => {
        //데이터 그리드에서 선택한 광고주 데이터만 추림
        const filters = editRows.filter((row)=> selectedRowKeys.includes(row.advLoginId));
        if(regFormValidation(filters)) {
            apiCall.post("/connMng/advMng/regAdvList", filters)
            .then(resp => {
                    successAlert("광고주 등록이 완료되었습니다.")
                    modalReset();
                    setGridRefresh(true);
                    setOpen(false);
                }
            )
            .catch(error => {
                errorAlert("광고주 등록 중 에러가 발생했습니다..<br />관리자에게 문의해주세요.", {error});
            })
            .finally()
        }
    }

    //광고주 등록 벨리데이션
    const regFormValidation = (regDataList: RegAdvModalGridProps[]) => {
        if(selectedRowKeys.length === 0 || regDataList.length === 0) { // 광고주 선택 0일 경우
            errorAlert("광고주를 선택해주세요.");
            return false;
        }
        if(regDataList.filter((row)=> row.advName === '' || row.advName === null).length > 0) {
             // 광고주명 미입력한 로우가 1개 이상일 경우
            errorAlert("선택 광고주의 광고주 명을 입력해주세요.");
            return false;
        }
        const advNameList = regDataList.map(v=> v.advName);
        const advNameSet = new Set(advNameList);
        if(advNameList.length !== advNameSet.size) { // 광고주명을 중복으로 입력한 경우
            errorAlert("광고주 명 중복입니다.");
            return false;
        }
        return true;
    }

    useEffect(() => {
        loadApiLoginIds();
    }, []);

    // 그리드 칼럼
    const gridColumns: ColumnsType<any> = [
        {title: '광고주 로그인 ID', dataIndex: 'advLoginId', align: 'center' },
        {title: 'Customer Id', dataIndex: 'customerId', align: 'center' },
        {title: '광고주 명', dataIndex: 'advName', align: 'center', render: (advName: string, record) =>
                <Input className="w-300"
                       placeholder="광고주명을 입력하세요."
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeAdvNameEvent(record, e)}/>
        },
    ]

    return (
        <>
            <Modal
                title="광고주 등록"
                open={isOpen}
                onCancel={() => {modalReset(); setOpen(false); }}
                afterClose={() => { modalReset(); setOpen(false); }}
                width={900}
                footer={[
                    <Button type="primary" className="gray" size="large" key="footerCancelBtn"
                            onClick={() => {modalReset(); setOpen(false); }}>취소</Button>,
                    <Button type="primary" className="pink" size="large" key="footerRegBtn"
                            onClick={regAdvEvent}>등록</Button>,
                ]}
            >
                {/* <!-- Wrap-Help : Start --> */}
                <section className="wrap-section wrap-help">
                    <ul className="box-body">
                        <li className="item-help">
                            <RightCircleOutlined rev={undefined}/>
                            <span className="fz-14 fc-gray-300">API 관리 페이지에서 등록된 API를 통해 광고주를 등록할 수 있습니다.</span>
                        </li>
                        <li className="item-help">
                            <RightCircleOutlined rev={undefined}/>
                            <span className="fz-14 fc-gray-300">API 로그인 ID 선택 후, 해당 API 내 연동된 광고주 목록 중 Nbidon에서 사용 할 광고주를 선택 및 광고주명을 입력합니다. <br/> <span className="fc-pink-300">(광고주 미등록 시, 사용 불가)</span> </span>
                        </li>
                        <li className="item-help">
                            <RightCircleOutlined rev={undefined}/>
                            <span className="fz-14 fc-gray-300">광고주 선택 후, [등록] 버튼 클릭 시 광고주 등록이 완료 됩니다.</span>
                        </li>
                    </ul>
                </section>
                {/* <!-- Wrap-Help : End --> */}

                {/* <!-- Wrap-Datagrid : Start --> */}
                <section className="wrap-section wrap-datagrid">
                    <div className="box-header">
                        <div className="box-left">
                            <Title level={4} className="fc-gray-700">연동 광고주 목록</Title>
                            <Text className="fz-14 fc-gray-300">Nbidon에서 사용할 광고주를 선택하여 등록합니다.</Text>
                        </div>
                    </div>
                    <div className="box-body">
                        {/* <!-- Wrap-Filter : Start --> */}
                        <section className="wrap-section wrap-filter">
                            <div className="box-body">
                                <Card>
                                    <Flex gap={24}>
                                        <div className="box-left">
                                            <i className="ico ico-filter"></i>
                                        </div>
                                        <div className="box-center">
                                            <Form>
                                                <Space size={[32, 24]} wrap>
                                                    <Form.Item label="API 로그인 ID" colon={false}>
                                                        <Select
                                                            style={{ width: 250 }}
                                                            placeholder={'API 로그인 ID를 선택하세요.'}
                                                            value={selectedApiLicenseId}
                                                            onChange={(apiLicenseId: string) => { setSelectedApiLicenseId(apiLicenseId); }}
                                                        >
                                                            {connAdvList.map((data) => {
                                                                return <Option key={data.apiLicenseId} value={data.apiLicenseId}>{data.apiLoginId}</Option>
                                                            })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Space>
                                            </Form>
                                        </div>
                                        <div className="box-right">
                                            <Button type="primary" className="pink" onClick={()=>loadAdvListByApiLoginId()}>조회하기</Button>
                                        </div>
                                    </Flex>
                                </Card>
                            </div>
                        </section>
                        {/* <!-- Wrap-Filter : End --> */}
                        <SkeletonTable loading={gridLoading} columns={gridColumns as SkeletonTableColumnsType[]}>
                            <Table
                                id={"regAdvGrid"}
                                rowKey={"advLoginId"}
                                rowSelection={rowSelection}
                                columns={gridColumns}
                                dataSource={rows}
                                bordered
                                pagination={paginationOptions(rows)}
                            />
                        </SkeletonTable>
                    </div>
                </section>
                {/* <!-- Wrap-Datagrid : End --> */}
            </Modal>
        </>
    );
};
export default React.memo(RegAdvModal);