import React, {useContext} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {AuthContext} from "./auth/loginAuthContext";
import CommonLayout from "./components/layout/commonLayout";

import "./css/fonts/NanumSquareNeo/fonts.css";
import "./css/common.css";
import "./css/layout.css";
import "./css/plugin.css";
import ProductChargeManage from "./pages/admin/productChargeManage";
import NbsUserManage from "./pages/admin/nbsUserManage";
import ApiManage from "./pages/connectionMng/apiManage";
import AdvManage from "./pages/connectionMng/advManage";
import BidProductState from "./pages/kwdBid/bidProductState";
import KeywordBidBulkManage from "./pages/kwdBid/keywordBidBulkManage";
import BidScheduleManage from "./pages/kwdBid/bidScheduleManage";
import BidScheduleState from "./pages/kwdBid/bidScheduleState";
import KeywordBidManage from "./pages/kwdBid/keywordBidManage";
import Login from "./pages/common/login";
import HealthCheck from "./pages/common/healthCheck";
import ErrorPage from "./pages/common/errorPage";
import CompetitorsRankHistory from "./pages/dataMng/competitorsRankHistory";

function App() {
    const authContext = useContext(AuthContext);
    const { isLoggedIn, roleGroups } = authContext;

    const allRoutesYn = roleGroups?.includes("ROLE_ATTR_ADMIN") && roleGroups?.includes("ROLE_ATTR_USER");
    /** 어드민일 경우 사용되는 라우트 **/
    const allRoutes = () => {
        return (
            <Routes>
                <Route element={<CommonLayout />}>
                    {/* Nbidon 페이지 : Start */}

                    {/* Nbidon 기본 경로 : 일단은 상품 충전 관리로 이동 */}
                    <Route path="/" element={<Navigate replace to="/kwdBid/keywordBidManage" />}/>

                    {/*  Nbidon 키워드 입찰 메뉴 : Start */}
                    <Route path="/kwdBid/keywordBidManage" element={<KeywordBidManage />} />
                    <Route path="/kwdBid/keywordBidBulkManage" element={<KeywordBidBulkManage />} />
                    <Route path="/kwdBid/bidScheduleManage" element={<BidScheduleManage />} />
                    <Route path="/kwdBid/bidScheduleState" element={<BidScheduleState />} />* 입찰 스케줄 등록(메뉴 없음)
                    <Route path="/kwdBid/bidProductState" element={<BidProductState />} />

                    {/*  Nbidon 데이터 관리 메뉴 : Start */}
                    <Route path="/dataMng/competitorsRankHistory" element={<CompetitorsRankHistory />} />

                    {/*  Nbidon 연동 관리 메뉴 : Start */}
                    <Route path="/connectionMng/apiManage" element={<ApiManage />} />
                    <Route path="/connectionMng/advManage" element={<AdvManage />} />

                    {/*  Nbidon 관리자 메뉴 : Start */}
                    <Route path="/admin/nbsUserManage" element={<NbsUserManage />} />
                    <Route path="/admin/prodChargeManage" element={<ProductChargeManage />} />

                    <Route path="*" element={<Navigate replace to="/" />}/>
                    {/* Nbidon 페이지 : End */}
                </Route>
            </Routes>
        )
    }
    /** 일반 사용자로 로그인한 경우 사용되는 라우트 */
    const userRoutes = () => {
        return (
            <Routes>
                <Route element={<CommonLayout />}>
                    {/* Nbidon 페이지 : Start */}

                    {/* Nbidon 기본 경로 : 일단은 상품 충전 관리로 이동 */}
                    <Route path="/" element={<Navigate replace to="/kwdBid/keywordBidManage" />}/>

                    {/*  Nbidon 키워드 입찰 메뉴 : Start */}
                    <Route path="/kwdBid/keywordBidManage" element={<KeywordBidManage />} />
                    <Route path="/kwdBid/keywordBidBulkManage" element={<KeywordBidBulkManage />} />
                    <Route path="/kwdBid/bidScheduleManage" element={<BidScheduleManage />} />
                    <Route path="/kwdBid/bidScheduleState" element={<BidScheduleState />} />* 입찰 스케줄 등록(메뉴 없음)
                    <Route path="/kwdBid/bidProductState" element={<BidProductState />} />

                    {/*  Nbidon 연동 관리 메뉴 : Start */}
                    <Route path="/connectionMng/apiManage" element={<ApiManage />} />
                    <Route path="/connectionMng/advManage" element={<AdvManage />} />

                    {/*  Nbidon 데이터 관리 메뉴 : Start */}
                    <Route path="/dataMng/competitorsRankHistory" element={<CompetitorsRankHistory />} />

                    <Route path="*" element={<Navigate replace to="/" />}/>
                    {/* Nbidon 페이지 : End */}
                </Route>
            </Routes>
        )
    }
    const loginRoutes = () => {
        return (
            <Routes>
                <Route path="/" element={<Navigate replace to="/common/login" />}/>
                <Route path="/common/login" element={<Login />} />
                <Route path="/common/healthCheck" element={<HealthCheck />} />
                <Route path="/common/errorPage" element={<ErrorPage />} />
                {/* 이상한 거 입력하면 그냥 로그인 페이지로 돌린다. */}
                <Route path="*" element={<Navigate replace to="/common/login" />}/>
            </Routes>
        )
    }


    return (
        <BrowserRouter>
            {/* 로그인이 되었을 때와 되어 있지 않았을 때의 경로 접근 시 움직이는 방식이 다름. */}
            { !isLoggedIn ? (<>{loginRoutes()}</>) : // 로그인 안했으면 로그인 라우트 사용
                // 로그인 결과 관리자로 로그인 / 일반 사용자로 로그인 했을 경우
                allRoutesYn ? (<>{allRoutes()}</>) : (<>{userRoutes()}</>)
            }
        </BrowserRouter>
    );
}

export default App;
