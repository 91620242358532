import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './auth/loginAuthContext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const testCode = process.env.REACT_APP_TEST_CODE;

console.log("Starting Mode : " + testCode);

root.render(
    <AuthContextProvider>
        <App />
        {/*<React.StrictMode>*/}
        {/*    <App />*/}
        {/*</React.StrictMode>*/}
    </AuthContextProvider>
);