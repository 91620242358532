import {Col, Collapse, Form, Input, Row, Select, Space, Typography} from "antd";
import {adAreaDivList, bidItemDivList, bookmarkList, onOffList} from "./keywordBidManageFilterFixList";
import React, {SetStateAction, useEffect, useState} from "react";
import {KeywordBidManageSearchProps} from "../../../pages/kwdBid/kwdBidTypes";
import apiCall from "../../../functions/apiCall";

interface DetailFilterProps {
    searchParams: KeywordBidManageSearchProps,
    setSearchParams: React.Dispatch<SetStateAction<KeywordBidManageSearchProps>>
}
interface AdvScheduleProps { scheduleId: number, scheduleName: string}
const KeywordBidManageDetailFilter = ({searchParams, setSearchParams }: DetailFilterProps) => {
    const initalAdvScheduleList:AdvScheduleProps[] = [{ scheduleId: 0, scheduleName: "전체" }];
    const [ advScheduleList, setAdvScheduleList ] = useState<AdvScheduleProps[]>(initalAdvScheduleList);
    const [ scheduleId, setScheduleId ] = useState<string | number | null>(null);
    const reloadScheduleEvent = () => {
        apiCall.get(`/keywordBid/bidKwd/loadScheduleList?advId=${searchParams.advId}`)
            .then(resp => {
                let scheduleList: AdvScheduleProps[] = [];
                resp.data.map((v: any)=> scheduleList.push(v))
                scheduleList.unshift({ scheduleId: -1, scheduleName: "미사용"});
                scheduleList.unshift({ scheduleId: 0, scheduleName: "전체"});
                setAdvScheduleList(scheduleList);
                setScheduleId(null);
            })
    }
    const onChangeDetailInputEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSearchParams({ ...searchParams, [name]: value});
    }

    useEffect(() => {
        reloadScheduleEvent();
    }, [searchParams.advId])
    return (
        <>
            <Row>
                <Col span={24}>
                    <Collapse ghost items={[
                        { key: '1', label: '상세 필터 설정', children:
                                <>
                                    <Space size={[32, 24]} wrap>
                                        <Form.Item label="북마크" colon={false}>
                                            <Select style={{ width: 250}}
                                                    value={searchParams.bookmarkDiv}
                                                    defaultValue={null}
                                                    onChange={(bookmarkDiv: string)=> setSearchParams({...searchParams, bookmarkDiv: bookmarkDiv})}
                                                    options={bookmarkList(true)}
                                                    dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }}
                                                    listHeight={200}
                                            />
                                        </Form.Item>

                                        {/*<Form.Item label="광고 상태" colon={false}>
                                            <Select style={{ width: 250 }}
                                                    getPopupContainer = {triggerNode => triggerNode.parentElement}
                                                    value={searchParams.adConfigYn}
                                                    defaultValue={null}
                                                    onChange={(adConfigYn:string|boolean|undefined)=> setSearchParams({...searchParams, adConfigYn: adConfigYn})}
                                                    options={onOffList(true)}
                                            />
                                        </Form.Item>*/}

                                        <Form.Item label="입찰 사용" colon={false}>
                                            <Select
                                                style={{ width: 250 }}
                                                getPopupContainer = {triggerNode => triggerNode.parentElement}
                                                value={searchParams.bidConfigYn}
                                                defaultValue={null}
                                                onChange={(bidUseYn:string|number)=> setSearchParams({...searchParams, bidConfigYn: bidUseYn})}
                                                options={onOffList(true)}
                                            />
                                        </Form.Item>

                                        {/*<Form.Item label="입찰 상태" colon={false}>
                                            <Select
                                                style={{ width: 250 }}
                                                getPopupContainer = {triggerNode => triggerNode.parentElement}
                                                value={searchParams.bidStatus}
                                                defaultValue={null}
                                                onChange={(bidStatus:string)=> setSearchParams({...searchParams, bidStatus: bidStatus})}
                                                options={bidStatusList()}
                                            />
                                        </Form.Item>*/}

                                        <Form.Item label="상품" colon={false}>
                                            <Select
                                                style={{ width: 250 }}
                                                getPopupContainer = {triggerNode => triggerNode.parentElement}
                                                value={searchParams.bidItemDiv}
                                                defaultValue={null}
                                                onChange={(itemType:string)=> setSearchParams({...searchParams, bidItemDiv: itemType})}
                                                options={bidItemDivList(true)}
                                            />
                                        </Form.Item>

                                        <Form.Item label="광고 영역" colon={false}>
                                            <Select
                                                style={{ width: 250 }}
                                                getPopupContainer = {triggerNode => triggerNode.parentElement}
                                                value={searchParams.adAreaDiv}
                                                defaultValue={null}
                                                onChange={(adAreaDiv:string)=> setSearchParams({...searchParams, adAreaDiv: adAreaDiv})}
                                                options={adAreaDivList(true)}
                                            />
                                        </Form.Item>

                                        <Form.Item label="현재 순위" colon={false}>
                                            <Space>
                                                <Input className="w-100" name={"startNowRank"} suffix="위"
                                                       value={searchParams.startNowRank} onChange={onChangeDetailInputEvent}
                                                       onKeyPress={(event) => { if(event.key === 'Enter') setSearchParams({...searchParams, pageNumber: 1, searchEventYn: true}) }}/>
                                                <Typography.Text className="fz-14 fc-gray-300">~</Typography.Text>
                                                <Input className="w-100" name={"endNowRank"} suffix="위"
                                                       value={searchParams.endNowRank} onChange={onChangeDetailInputEvent}
                                                       onKeyPress={(event) => { if(event.key === 'Enter') setSearchParams({...searchParams, pageNumber: 1, searchEventYn: true}) }}/>
                                            </Space>
                                        </Form.Item>

                                        <Form.Item label="목표 순위" colon={false}>
                                            <Space>
                                                <Input className="w-100" name={"startHopeRank"} suffix="위"
                                                       value={searchParams.startHopeRank} onChange={onChangeDetailInputEvent}
                                                       onKeyPress={(event) => { if(event.key === 'Enter') setSearchParams({...searchParams, pageNumber: 1, searchEventYn: true}) }}/>
                                                <Typography.Text className="fz-14 fc-gray-300">~</Typography.Text>
                                                <Input className="w-100" name={"endHopeRank"} suffix="위"
                                                       value={searchParams.endHopeRank} onChange={onChangeDetailInputEvent}
                                                       onKeyPress={(event) => { if(event.key === 'Enter') setSearchParams({...searchParams, pageNumber: 1, searchEventYn: true}) }}/>
                                            </Space>
                                        </Form.Item>

                                        {/*<Form.Item label="현재 입찰가" colon={false}>
                                            <Space>
                                                <Input className="w-100" name={"startNowBidCost"} suffix="원"
                                                       value={searchParams.startNowBidCost} onChange={onChangeDetailInputEvent}
                                                       onKeyPress={(event) => { if(event.key === 'Enter') setSearchParams({...searchParams, searchEventYn: true}) }}/>
                                                <Typography.Text className="fz-14 fc-gray-300">~</Typography.Text>
                                                <Input className="w-100" name={"endNowBidCost"} suffix="원"
                                                       value={searchParams.endNowBidCost} onChange={onChangeDetailInputEvent}
                                                       onKeyPress={(event) => { if(event.key === 'Enter') setSearchParams({...searchParams, searchEventYn: true}) }}/>
                                            </Space>
                                        </Form.Item>*/}

                                        <Form.Item label="최대 입찰가" colon={false}>
                                            <Space>
                                                <Input className="w-100" name={"startMaxBidCost"} suffix="원"
                                                       value={searchParams.startMaxBidCost} onChange={onChangeDetailInputEvent}
                                                       onKeyPress={(event) => { if(event.key === 'Enter') setSearchParams({...searchParams, pageNumber: 1, searchEventYn: true}) }}/>
                                                <Typography.Text className="fz-14 fc-gray-300">~</Typography.Text>
                                                <Input className="w-100" name={"endMaxBidCost"} suffix="원"
                                                       value={searchParams.endMaxBidCost} onChange={onChangeDetailInputEvent}
                                                       onKeyPress={(event) => { if(event.key === 'Enter') setSearchParams({...searchParams, pageNumber: 1, searchEventYn: true}) }}/>
                                            </Space>
                                        </Form.Item>

                                        <Form.Item label="스케줄" colon={false}>
                                            <Select
                                                style={{ width: 250 }}
                                                getPopupContainer = {triggerNode => triggerNode.parentElement}
                                                value={searchParams.scheduleId}
                                                onChange={(scheduleId: number)=>{
                                                    setSearchParams({...searchParams, scheduleId: scheduleId})
                                                    setScheduleId(scheduleId)
                                                }}
                                                dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }}
                                                listHeight={200}
                                            >
                                                {advScheduleList.map((schedule: AdvScheduleProps) => {
                                                    return <Select.Option key={schedule.scheduleId} value={schedule.scheduleId} label={schedule.scheduleName}>
                                                        {schedule.scheduleName}
                                                    </Select.Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Space>
                                </>
                        }
                    ]} />
                </Col>
            </Row>
        </>
    )
};

export default React.memo(KeywordBidManageDetailFilter);