import {Form, Input, Select, Space} from "antd";
import React, {SetStateAction, useEffect, useState} from "react";
import {KeywordBidManageSearchProps} from "../../../pages/kwdBid/kwdBidTypes";
import apiCall from "../../../functions/apiCall";


/** 조회 필터(기본) 타입 */
interface DefaultFilterProps {
    advList: {advId: number, advName: string}[],
    searchParams: KeywordBidManageSearchProps,
    setSearchParams: React.Dispatch<SetStateAction<KeywordBidManageSearchProps>>
}
/** 조회 필터(기본) => 광고주 / 캠페인 / 광고그룹 / 키워드 */
interface AdvFilterProps {
    advs : {advId: number, advName: string}[] | []
    camps : {campId: string, campName: string}[] | []
    groups : {groupId: string, groupName: string}[] | []
    kwdName : string | undefined
    kwdSearchDiv: string
}

const KeywordBidManageDefaultFilter = ({advList, searchParams, setSearchParams}: DefaultFilterProps) => {
    /** 광고주 / 캠페인 / 광고 그룹를 담는 변수용 **/
    const [ advFilter, setAdvFilter ] = useState<AdvFilterProps>({
        advs: [{advId: 0, advName: '전체'}],
        camps: [{campId: 'ALL', campName: '전체'}],
        groups: [{groupId: 'ALL', groupName: '전체'}],
        kwdName:undefined,
        kwdSearchDiv:"EXIST"}
    );

    /** 광고주를 변경했을 때 캠페인 데이터 로드 */
    useEffect(() => {
        if(searchParams.advId !== 0) {
            apiCall.get(`/keywordBid/bidKwd/loadCampList?advId=${searchParams.advId}`)
                .then(resp => {
                    let copyCampList = [...resp.data];
                    copyCampList.unshift({campId : 'ALL', campName : '전체'});
                    setAdvFilter({...advFilter, camps : copyCampList, groups: [{groupId: 'ALL', groupName: '전체'}]})
                });
        } else {
            setAdvFilter({...advFilter, camps: [{campId: 'ALL', campName: '전체'}], groups: [{groupId: 'ALL', groupName: '전체'}]});
        }
        setSearchParams({...searchParams, advId: searchParams.advId, campId: 'ALL', groupId : 'ALL', searchEventYn : true})
    }, [searchParams.advId])

    /** 캠페인을 변경했을 때 광고그룹 데이터 로드 */
    useEffect(() => {
        if(searchParams.campId === "ALL" || searchParams.advId === 0) {
            setAdvFilter({...advFilter, groups: [{groupId: 'ALL', groupName: '전체'}]})
        } else {
            apiCall.get(`/keywordBid/bidKwd/loadGroupList?advId=${searchParams.advId}&campId=${searchParams.campId}`)
                .then(resp => {
                    let copyGroupList = [...resp.data];
                    copyGroupList.unshift({groupId : 'ALL', groupName : '전체'});
                    setAdvFilter({...advFilter, groups: copyGroupList})
            });
        }
        setSearchParams({...searchParams, groupId: 'ALL', searchEventYn: true});
    }, [searchParams.campId])

    return (
        <>
            {/* 기본 조회 필터 항목 : Start */}
            <Space size={32} wrap>
            {advList.length > 0 &&
                <Form.Item label="광고주" colon={false}>
                    <Select style={{ width: 250 }} placeholder={'광고주를 선택하세요.'}
                            getPopupContainer = {triggerNode => triggerNode.parentElement}
                            value={searchParams.advId}
                            onChange={(advId: number) => {
                                setSearchParams({...searchParams, advId : advId});
                            }}
                            dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }}
                            listHeight={200}
                    >
                        {advList.map((adv: {advId:number, advName: string}) => {
                            return <Select.Option key={adv.advId} value={adv.advId}>{adv.advName}</Select.Option>
                        })}
                    </Select>
                </Form.Item>
            }
                <Form.Item label="캠페인" colon={false}>
                    <Select style={{ width: 250 }} placeholder={'캠페인을 선택하세요.'}
                            getPopupContainer = {triggerNode => triggerNode.parentElement}
                            value={searchParams.campId}
                            onChange={(campId: string) => setSearchParams({...searchParams, campId: campId, searchEventYn : true})}
                            dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }}
                            listHeight={200}
                    >
                        {advFilter.camps.map((camp: {campId:string, campName: string}) => {
                            return <Select.Option key={camp.campId} value={camp.campId}>{camp.campName}</Select.Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="광고그룹" colon={false}>
                    <Select style={{ width: 250 }} placeholder={'광고 그룹을 선택하세요.'}
                            getPopupContainer = {triggerNode => triggerNode.parentElement}
                            value={searchParams.groupId}
                            onChange={(groupId: string) => {
                                setSearchParams({...searchParams, groupId: groupId, searchEventYn : true})
                            }}
                            dropdownStyle={{ maxHeight: 200, overflowY: 'auto' }}
                            listHeight={200}
                    >
                        {advFilter.groups.map((group: {groupId:string, groupName: string}) => {
                            return <Select.Option key={group.groupId} value={group.groupId}>{group.groupName}</Select.Option>
                        })}
                    </Select>
                </Form.Item>

                <Form.Item label="키워드" colon={false}>
                    <Input placeholder="키워드를 입력하세요." value={advFilter.kwdName}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>)=> setSearchParams({...searchParams, kwdName : e.target.value})}
                           onKeyPress={(event) => { if(event.key === 'Enter') setSearchParams({...searchParams, pageNumber: 1, searchEventYn: true}) }}
                    />
                </Form.Item>

                <Form.Item>
                    <Select
                        style={{ width: 100 }}
                        getPopupContainer = {triggerNode => triggerNode.parentElement}
                        value={searchParams.kwdSearchDiv}
                        onChange={(kwdSearchDiv: string) => setSearchParams({...searchParams, kwdSearchDiv: kwdSearchDiv})}
                        options={[
                            { value: 'EXIST', label: '포함' },
                            { value: 'EQUAL', label: '일치' },
                            { value: 'START', label: '시작' },
                        ]}
                    />
                </Form.Item>
            </Space>
            {/* 기본 조회 필터 항목 : End */}
        </>
    )
};

export default React.memo(KeywordBidManageDefaultFilter);