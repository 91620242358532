import React, {useEffect, useState} from "react";
/** Dummy Data */
/** Dummy Data */
import {Button, Card, Flex, Form, Input, Space, Table, Typography,} from "antd";

// Import - DatePicker - Range
import 'dayjs/locale/ko';

import {NbsUserManageGridProps, NbsUserManageSearchProps} from "./adminTypes";
import ContentHeader from "../../components/layout/contentHeader";
import {paginationOptions} from "../../functions/tableFormatter";
import ModifyUserModal from "../../components/admin/nbsUserManage/modifyUserModal";
import RegUserModal from "../../components/admin/nbsUserManage/regUserModal";
import AdvInfoListModal from "../../components/admin/nbsUserManage/advInfoListModal";
import {errorAlert, successAlert} from "../../functions/alertFn";
import modal from "antd/es/modal";
// Import - Icon
import {ExclamationCircleFilled} from '@ant-design/icons';
import apiCall from "../../functions/apiCall";
import SkeletonTable, {SkeletonTableColumnsType} from "../../components/table/skeletonTable";
import {ColumnsType} from "antd/es/table";

// Typography
const { Title } = Typography;

const NbsUserManage = () => {
    /** 조회 필터 */
    const [ searchParams, setSearchParams] = useState<NbsUserManageSearchProps>({keyword: ''});

    /** 그리드 */
    const [ gridLoading, setGridLoading ] = useState<boolean>(true);
    const [ gridRefresh, setGridRefresh ] = useState<boolean>(false);
    const [ rows, setRows ] = useState<any[]>([]);
    const [deleteUserKeys, setDeleteUserKeys] = useState<React.Key[]>([]); // 삭제 시 사용
    
    /** 모달 */
    const [ selectedUserRow, setSelectedUserRow ] = useState<NbsUserManageGridProps>();
    const [ advInfoListModalOpenYn, setAdvInfoListModalOpenYn ] = useState<boolean>(false);
    const [ regUserModalOpenYn, setRegUserModalOpenYn] = useState<boolean>(false);
    const [ modifyUserModalOpenYn, setModifyUserModalOpenYn] = useState<boolean>(false);

    /** 테이블 초기화*/
    const [tableKey, setTableKey] = useState<number>(0);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setDeleteUserKeys(newSelectedRowKeys);
    };

    const rowSelection = { deleteUserKeys, onChange: onSelectChange };

    const columnsOptions: ColumnsType<NbsUserManageGridProps> = [
        {title: 'No', dataIndex: 'index', align: 'center', render: (_, record, index) => index+1},
        {title: '사용자', dataIndex: 'memberId', align: 'center' },
        {title: '사용자 명', dataIndex: 'memberName', align: 'center' },
        {title: '등록 광고주', dataIndex: 'advCtn', align: 'center', render: (_, record) =>{
                const advLength = record.advs === undefined ? 0 : Object.keys(record.advs).length;
                const buttonColorClass = advLength === 0 ? "gray" : "pink";
                return <Button type="primary" className={buttonColorClass} size="small" onClick={() => {
                    setSelectedUserRow(record);
                    if(advLength > 0) {
                        setAdvInfoListModalOpenYn(true);
                    }
                }}>{advLength} 개</Button>
            }
        },
        {title: '등록 날짜', dataIndex: 'regTime', align: 'center'},
        {title: '수정', dataIndex: 'modifyPwdBtn', align: 'center', render: (_, record) => {
                return <Button className="pink" size="small" onClick={() => {
                    setSelectedUserRow(record);
                    setModifyUserModalOpenYn(true);
                }}>수정</Button>
            }
        },
    ];

    /** 데이터 그리드 로드 */
    const pageInit = () => {
        setGridLoading(true);
        setDeleteUserKeys([]);
        apiCall.post("member/mng/search", searchParams)
            .then(resp => {
                setRows(resp.data);
                // 초기화
                setDeleteUserKeys([]);
                setTableKey(prevKey => prevKey + 1); // 키값을 변경시켜서 내부 상태를 초기화 해서 재 렌더링을 시킨다.
                setGridLoading(false);
            })
            .catch(error => {
                errorAlert("사용자 목록 데이터가 정상적으로 로드 되지 않습니다.<br />관리자에게 문의바랍니다.", {error});
            }).finally(() => {});

    }

    /** 사용자 삭제 이벤트 */
    const deleteUserEvent = () => {
        if (deleteUserKeys.length === 0) {
            errorAlert("삭제하실 사용자를 선택 후 다시 클릭해주세요.");
        } else {
            modal.confirm({
                icon: <ExclamationCircleFilled rev={undefined}/>,
                title: '사용자를 삭제하시겠습니까?',
                content: '삭제한 사용자 내 연동 정보는 모두 해제되며, 해당 사용자 정보를 Nbidon에서 더이상 확인할 수 없습니다.',
                okText: '확인', cancelText: '취소',
                okButtonProps: {className: "pink ant-btn-lg"},
                cancelButtonProps: {type: 'primary', className: "gray ant-btn-lg"},
                onOk: () => {
                    apiCall.post("/member/mng/delete", {'memberIdList': deleteUserKeys})
                        .then(resp => {
                            successAlert("사용자 삭제가 완료되었습니다.")
                            setGridRefresh(true);
                        })
                        .catch(error => {
                            errorAlert("사용자 삭제 시 오류가 발생했습니다.<br />관리자에게 문의바랍니다.", {error});
                        }).finally(() => {
                    });
                },
                onCancel: () => {
                    return;
                }
            })
        }
    }
    //최초 페이지 진입
    useEffect(() => {
        pageInit()
        document.title = "사용자 관리";
    }, []);

    //화면 리프래시
    useEffect(() => {
        if(gridRefresh) {
            pageInit();
            setGridRefresh(false);
        }
    }, [gridRefresh])

    return (
        <>
            <ContentHeader pageTitle={"사용자 관리"} navigation={["관리자", "사용자 관리"]} />
            {/* <!-- Wrap-Filter : Start --> */}
            <section className="wrap-section wrap-filter">
                {/*<div className="box-header">
                    <div className="box-left">
                        <Title level={3} className="fc-gray-700">조회 필터</Title>
                    </div>
                </div>*/}
                <div className="box-body">
                    <Card>
                        <Flex gap={24}>
                            <div className="box-left">
                                <i className="ico ico-filter"></i>
                            </div>
                            <div className="box-center">
                                <Form>
                                    <Space size={32} wrap>
                                        <Form.Item label="사용자정보" colon={false} rules={[{ required: true, message: '사용자 명 또는 사용자 아이디를 입력해주세요.' }]}>
                                            <Input className="w-400" placeholder="사용자명 또는 사용자 아이디를 입력해주세요." onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const {value} = e.target;
                                                setSearchParams({ ...searchParams, keyword: value});
                                            }} onPressEnter={pageInit}
                                            />
                                        </Form.Item>
                                    </Space>
                                </Form>
                            </div>
                            <div className="box-right">
                                <Button type="primary" className="pink" onClick={pageInit}>조회하기</Button>
                            </div>
                        </Flex>
                    </Card>
                </div>
            </section>
            {/* <!-- Wrap-Filter : End --> */}

            {/* <!-- Wrap-Datagrid : Start --> */}
            <section className="wrap-section wrap-datagrid">
                <div className="box-header">
                    <div className="box-left">
                        <Title level={3} className="fc-gray-700">사용자 목록</Title>
                    </div>
                    <div className="box-right">
                        <Button type="primary" className="pink" onClick={() => setRegUserModalOpenYn(true)}>사용자 등록</Button>
                        <Button type="primary" className="gray" onClick={() => deleteUserEvent()}>삭제</Button>
                    </div>
                </div>
                <div className="box-body">
                    <SkeletonTable loading={gridLoading} columns={columnsOptions as SkeletonTableColumnsType[]}>
                        <Table
                            key={tableKey} bordered
                            // key={"userGrid"} bordered
                            columns={columnsOptions} dataSource={rows} rowKey="memberId"
                            rowSelection={rowSelection}
                            pagination={paginationOptions(rows)}
                        />
                    </SkeletonTable>
                </div>
            </section>
            {/* <!-- Wrap-Datagrid : End --> */}
            {/* 광고주 리스트 모달 */}
            { advInfoListModalOpenYn &&
                <AdvInfoListModal
                    selectRow={selectedUserRow!!}
                    isOpen={advInfoListModalOpenYn}
                    setOpen={setAdvInfoListModalOpenYn}
                />
            }

            {/* 사용자 등록 모달 */}
            { regUserModalOpenYn &&
                <RegUserModal
                    isOpen={regUserModalOpenYn}
                    setOpen={setRegUserModalOpenYn}
                    setGridRefresh={setGridRefresh}
                />
            }
            {/* 사용자 비밀번호 수정 모달 */}
            { modifyUserModalOpenYn &&
                <ModifyUserModal
                    record={selectedUserRow}
                    isOpen={modifyUserModalOpenYn}
                    setOpen={setModifyUserModalOpenYn}
                    setGridRefresh={setGridRefresh}
                />
            }
        </>
    )
}

export default NbsUserManage;