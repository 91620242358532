import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "antd";

import {errorAlert, successAlert} from "../../../functions/alertFn";
import apiCall from "../../../functions/apiCall";

interface RegUserModalProps {
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setGridRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const RegUserModal = ({ isOpen, setOpen, setGridRefresh }: RegUserModalProps) => {

    /** 등록 폼 */
    const [ regForm, setRegForm ] = useState<{memberId: string, memberName: string, pwd: string, pwdConfirm: string}>({memberId : '', memberName : '', pwd: '', pwdConfirm : ''});
    const [ accordPwdYn, setAccordPwdYn] = useState<boolean>(false);
    const [form] = Form.useForm();

    //모달 초기화
    const modalReset = () => {
        form.resetFields();
        setRegForm({memberId : '', memberName : '', pwd: '', pwdConfirm : ''});
        setAccordPwdYn(false);
    }

    //사용자 등록 벨리데이션
    const regFormValidation = () => {
        if(regForm.memberId === undefined || regForm.memberId === '' ) {
            errorAlert("ID를 입력해주세요.");
            return false;
        }
        const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;
        if(!emailRegEx.test(regForm.memberId)){
            errorAlert("ID는 이메일 양식으로 입력해주세요.");
            return false;
        }
        if(regForm.memberName === undefined || regForm.memberName === '' ) {
            errorAlert("사용자 명을 입력해주세요.");
            return false;
        }
        if(regForm.pwd === undefined || !accordPwdYn) {
            errorAlert("비밀번호를 확인해주세요.");
            return false;
        }
        return true;
    }

    const onChangeRegFormEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRegForm({ ...regForm, [name]: value});
    }

    //사용자 등록 이벤트
    const regUserEvent = () => {
        if (regFormValidation()) {
            apiCall.post("auth/signup", regForm)
                .then(resp => {
                    successAlert("사용자 등록이 완료되었습니다.");
                    modalReset();
                    setGridRefresh(true);
                    setOpen(false);
                })
                .catch(error => {
                    if (error.response.data.message !== undefined) {
                        errorAlert(error.response.data.message, {error});
                    } else {
                        errorAlert("올바른 정보를 입력해주세요.", {error});
                    }

                })
                .finally(() => {
                    // //시작시간, 종료시간 초기화
                    // setStartTime(null);
                    // setEndTime(null);
                    // //목표설정, 목표순위 초기화
                    // setHopeRankType(null);
                    // setHopeRank(null);
                })
        }
    }

    return (
        <>
            <Modal
                title="사용자 등록"
                open={isOpen}
                onCancel={() => {modalReset(); setOpen(false); }}
                afterClose={() => { modalReset(); setOpen(false); }}
                width={900}
                footer={[
                    <Button type="primary" className="gray" size="large" key="footerCancelBtn" onClick={() => {modalReset(); setOpen(false); }}>취소</Button>,
                    <Button type="primary" className="pink" size="large" key="footerChargeBtn" disabled={!accordPwdYn} onClick={regUserEvent}>등록</Button>,
                ]}
            >
                {/* <!-- Wrap-Tbl : Start --> */}
                <section className="wrap-section wrap-tbl">
                    {/*<div className="box-header">
                        <div className="box-left">
                            <Title level={4} className="fc-gray-500">사용자 수정</Title>
                        </div>
                    </div>*/}
                    <div className="box-body">
                        <Form form={form} name="dependencies" autoComplete="off" layout="vertical" >
                        <div className="tbl">
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">ID</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Input className="w-500" name="memberId" value={regForm.memberId} onChange={onChangeRegFormEvent} />
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">사용자 명</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Input className="w-500" name="memberName" value={regForm.memberName} onChange={onChangeRegFormEvent} />
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">비밀번호</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Form.Item name="password" rules={[{ required: true, message: "비밀번호를 입력해주세요." }]}>
                                            <Input.Password name="pwd" className="w-500" value={regForm.pwd} onChange={onChangeRegFormEvent} />
                                        </Form.Item>
                                    </div>
                                </dd>
                            </dl>
                            <dl>
                                <dt>
                                    <div className="dt-inner">
                                        <span className="fz-15 fc-gray-500">비밀번호 확인</span>
                                        <i className="txt-essential"></i>
                                    </div>
                                </dt>
                                <dd>
                                    <div className="form-group">
                                        <Form.Item
                                            name="password2"
                                            dependencies={['password']}
                                            rules={[{ required: true, },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            setAccordPwdYn(true);
                                                        } else {
                                                            setAccordPwdYn(false);
                                                            return Promise.reject(new Error('입력하신 비밀번호와 일치하지 않습니다.'));
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password className="w-500" name="pwdConfirm" value={regForm.pwdConfirm} onChange={onChangeRegFormEvent}/>
                                        </Form.Item>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                        </Form>
                    </div>
                </section>
                {/* <!-- Wrap-Tbl : End --> */}
            </Modal>
        </>
    );
};
export default React.memo(RegUserModal);