import React from "react";
import {Button, Modal, Table, Typography} from "antd";

// Import - Icon


interface BidStatusCaseModalProps {
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

const BidStatusCaseModal = ({isOpen, setOpen}: BidStatusCaseModalProps) => {
    //랜더링 시 별도 작업해야하는 설명은 | 를 사용한다.
    const bidStatusList = [
        {rowPk: 1, bidStatusName : "정상 입찰", bidStatusDesc : "목표 순위에 도달/유지하기 위해 자동 입찰이 진행되는 상태", bidIngYn : true},
        {rowPk: 2, bidStatusName : "MAX 입찰", bidStatusDesc : "목표 순위 도달 여부 상관없이 최대입찰가에 도달하여 더이상 입찰가 변동이 발생하지 않는 상태", bidIngYn : true},
        {rowPk: 3, bidStatusName : "MIN 입찰", bidStatusDesc : "목표순위에 도달하여 입찰가 최적화를 위해 하향 입찰 중, 최소입찰가에 도달하여 입찰가 변동이 발생하지 않는 상태", bidIngYn : true},
        {rowPk: 4, bidStatusName : "수동 입찰", bidStatusDesc : "사용자가 Nbidon 내 수동 입찰 사용 ‘ON’ 으로 설정한 상태", bidIngYn : true},

        {rowPk: 5, bidStatusName : "연동 오류", bidStatusDesc : "입찰 키워드 광고주 API에 접근이 불가능한 상태", bidIngYn : false},
        {rowPk: 6, bidStatusName : "API 오류", bidStatusDesc : "연동된 API 호출 시 일시적으로 오류가 발생하는 상태|(2분 입찰 주기 후 재입찰 진행)", bidIngYn : false},
        {rowPk: 7, bidStatusName : "캠페인 OFF", bidStatusDesc : "네이버 광고 시스템 내 캠페인이 OFF로 설정된 상태", bidIngYn : false},
        {rowPk: 8, bidStatusName : "광고그룹 OFF", bidStatusDesc : "네이버 광고 시스템 내 광고그룹이 OFF로 설정된 상태", bidIngYn : false},
        {rowPk: 9, bidStatusName : "키워드 OFF", bidStatusDesc : "네이버 광고 시스템 내 키워드가 OFF로 설정된 상태", bidIngYn : false},
        {rowPk: 10, bidStatusName : "캠페인 중지", bidStatusDesc : "네이버 광고 시스템 내 ‘캠페인 기간외’ or ‘캠페인 예산 도달’ 상태", bidIngYn : false},
        {rowPk: 11, bidStatusName : "광고그룹 중지", bidStatusDesc : "네이버 광고 시스템 내 ‘그룹 예산 도달’ 상태", bidIngYn : false},
        {rowPk: 12, bidStatusName : "광고그룹 미노출 시간", bidStatusDesc : "네이버 광고 시스템 내 광고그룹 미노출 시간 상태 (요일/시간대 타겟팅)", bidIngYn : false},
        {rowPk: 13, bidStatusName : "비즈채널 오류", bidStatusDesc : "네이버 광고 시스템 내 ‘비즈채널 검토중’ or ‘비즈채널 노출제한’ 상태", bidIngYn : false},
        {rowPk: 14, bidStatusName : "캠페인 오류", bidStatusDesc : "네이버 광고 시스템 내 캠페인이 삭제되어 접근이 불가한 상태", bidIngYn : false},
        {rowPk: 15, bidStatusName : "광고그룹 오류", bidStatusDesc : "네이버 광고 시스템 내 광고그룹이 삭제되어 접근이 불가한 상태", bidIngYn : false},
        {rowPk: 16, bidStatusName : "키워드 오류", bidStatusDesc : "네이버 광고 시스템 내 키워드가 삭제되어 접근이 불가하거나 ‘키워드 검토중’ or ‘키워드 노출제한’ or ‘키워드 사용제한’ 상태", bidIngYn : false},
        {rowPk: 17, bidStatusName : "노출광고 부족", bidStatusDesc : "셋팅한 목표 순위 보다 통합검색/더보기 영역의 노출 광고가 부족한 상태", bidIngYn : false},
        {rowPk: 18, bidStatusName : "광고영역 오류", bidStatusDesc : "네이버 광고 시스템 내 설정 노출 매체와 Nbidon 내 설정 광고 영역이 상이한 상태|- ex. 네이버 광고 시스템 매체: PC, Nbidon 광고 영역: MO", bidIngYn : false},

    ]


    return (
        <>
            <Modal
                title="입찰 상태 케이스"
                open={isOpen}
                onCancel={()=> setOpen(false)}
                width={1200}
                footer={[
                    <Button type="primary" className="pink" size="large" key={"footerCloseBtn"} onClick={()=> setOpen(false)}>확인</Button>,
                ]}
            >
                <section className="wrap-section wrap-datagrid">
                    <div className="box-header">
                        <div className="box-left">
                            <Typography.Text className="fc-pink-500">입찰 상태 케이스 관련 자세한 내용은 애드테크 팀으로 문의 바랍니다.</Typography.Text>
                        </div>
                    </div>
                    <div className="box-body">
                        <Table
                            key={"bidsStatusCaseGrid"}
                            rowKey={"rowPk"}
                            columns={[
                                {title: '입찰 상태', dataIndex: 'bidStatusName', align: 'center', },
                                {title: '설명', dataIndex: 'bidStatusDesc', align: 'center',
                                    render: (value: string, record) => {
                                        if (record.rowPk === 6) { // 특정 문자열 색상 변경
                                            const splitDesc = value.split("|");
                                            return <>{splitDesc[0]} <Typography.Text
                                                className="fc-pink-500">{splitDesc[1]}</Typography.Text></>
                                        } else if (record.rowPk === 18) { // 문단 나눔
                                            const splitDesc = value.split("|");
                                            return <> <p>{splitDesc[0]}</p> <p>{splitDesc[1]}</p> </>
                                        } else {
                                            return value;
                                        }
                                    }
                                },
                                {title: '입찰 진행 여부', dataIndex: 'bidIngYn', align: 'center',
                                    render: (value: boolean, _) =>
                                        value ?
                                            <Typography.Text className="fc-gray-500 c-green">입찰</Typography.Text> :
                                            <Typography.Text className="fc-gray-500 c-red">미입찰</Typography.Text>
                                }
                            ]}
                            dataSource={bidStatusList}
                            bordered
                            pagination={false}
                        />
                    </div>
                </section>
            </Modal>
        </>
    )
};

export default BidStatusCaseModal;