import {Button, Flex, Input, Select, Tooltip, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {
    BidScheduleManageGridProps,
    BidScheduleProps,
    BidScheduleStateConfigProps
} from "../../../pages/kwdBid/kwdBidTypes";
import {errorAlert} from "../../../functions/alertFn";
import {CloseOutlined} from '@ant-design/icons';
import BidScheduleStateTimeTable from "./BidScheduleStateTimeTable";
import {useNavigate} from "react-router-dom";
import {nbsHopeRankInputCheck} from "../../../functions/validator";
import {prettyNumber} from "../../../functions/tableFormatter";
import {onOffList} from "../keywordBidManage/keywordBidManageFilterFixList";

interface BidScheduleStateConfigComponentProps {
    selectedAdv: BidScheduleManageGridProps | undefined,
    scheduleParams: BidScheduleProps,
    setScheduleParams: React.Dispatch<React.SetStateAction<BidScheduleProps>>
    setRegEventCallYn: React.Dispatch<React.SetStateAction<boolean>>
    componentResetEventYn: boolean,
    setComponentResetEventYn: React.Dispatch<React.SetStateAction<boolean>>

}

/** 날짜 리스트 */
const dateList = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
/** 조건별 색상 */
const CONFIG_COLORS = ["RED", "YELLOW", "GREEN", "BLUE", "PURPLE"];

/** 현재 사용 중인 색상을 제외한 잔여 색상 중 하나 리턴 */
function findRemainColor(copy: BidScheduleStateConfigProps[]) {
    let existColors: string[] = [];
    //컬러 코드가 있는 조건들만 추출
    copy.map((config) => existColors.push(config.color));
    //이미 사용하고 있는 컬러를 제외한 나머지 컬러만 추출
    const remainColors = CONFIG_COLORS.filter((color) => !existColors.includes(color));
    return remainColors[0];
}

const BidScheduleStateConfig = ({
                                    selectedAdv,
                                    scheduleParams, setScheduleParams,
                                    setRegEventCallYn, componentResetEventYn, setComponentResetEventYn
                                }: BidScheduleStateConfigComponentProps) => {
    /** 중단 조건 파라미터 */
    const [scheduleConfigParams, setScheduleConfigParams] = useState<BidScheduleStateConfigProps[]>([]);
    /** 입력한 조건 */
    const [inputConfig, setInputConfig] = useState<{ bidScheduleDetId?: number, hopeRank: string, maxBidCost: string, minBidCost: string, bidConfigYn: boolean }>(
        {bidScheduleDetId: undefined, hopeRank: '', maxBidCost: '', minBidCost: '', bidConfigYn: true}
    );
    /** 선택(=클릭)한 조건 */
    const [selectedConfig, setSelectedConfig] = useState<BidScheduleStateConfigProps>();
    /** 추가하였을 때만 한정하여 조건 선택되도록 설정 */
    const [addConfigYn, setAddConfigYn] = useState<boolean>(false);
    /** 모든 처리 완료 후 등록 버튼 클릭 시 변경 */
    const [regBtnClickYn, setRegBtnClickYn] = useState<boolean>(false);

    /** 페이지 이동 */
    let navigate = useNavigate();

    /** 조건 추가 벨리데이션 */
    const addConfigValidation = () => {

        if (inputConfig.bidConfigYn) {
            // 1. 목표 순위 벨리데이션
            if (inputConfig.hopeRank !== '' && !nbsHopeRankInputCheck(inputConfig.hopeRank)) {
                errorAlert("목표 순위는 1 ~ 25 범위 내 숫자로 입력해주세요.");
                return false;
            }

            // 2. 최소 & 최대값 밸리데이션
            if (inputConfig.maxBidCost !== '' && inputConfig.minBidCost !== '') {
                const numberMaxBidCost = parseInt(inputConfig.maxBidCost);
                const numberMinBidCost = parseInt(inputConfig.minBidCost);

                // 최소 입찰가 숫자 이외일 경우
                if (isNaN(numberMinBidCost)) {
                    errorAlert("최소입찰가는 숫자만 입력가능합니다.");
                    return false;
                }
                // 최대 입찰가 숫자 이외일 경우
                if (isNaN(numberMaxBidCost)) {
                    errorAlert("최대입찰가는 숫자만 입력가능합니다.");
                    return false;
                }
                //최소 입찰가 > 최대 입찰가일 경우
                if (numberMinBidCost > numberMaxBidCost) {
                    errorAlert("최소입찰가를 최대입찰가 이하로 입력해주세요.");
                    return false;
                }
            }

            // //동일 조건이 있는지 체크 ( 삭제 시 타입이 달라서 그냥 별도로 체크함)
            const existConfig = scheduleConfigParams.filter((existConfig) => {

                return existConfig.hopeRank === inputConfig.hopeRank &&
                    existConfig.minBidCost === inputConfig.minBidCost &&
                    existConfig.maxBidCost === inputConfig.maxBidCost &&
                    existConfig.bidConfigYn === inputConfig.bidConfigYn
            })
            if (existConfig.length > 0) {
                errorAlert("중복된 조건은 추가 불가합니다.");
                return false;
            }
            return true;
        }
    }


    /** 조건 추가 이벤트 */
    const addConfigEvent = () => {
        const addConfig = (hopeRank: string, minBidCost: string, maxBidCost: string, bidConfigYn: boolean) => {
            let copy = [...scheduleConfigParams];

            const color = copy.length === 0 ? CONFIG_COLORS[0] : findRemainColor(copy);
            copy.push({
                bidScheduleDetId: undefined,
                hopeRank: hopeRank,
                minBidCost: minBidCost,
                maxBidCost: maxBidCost,
                bidConfigYn: bidConfigYn,
                monSchedule: '',
                tueSchedule: '',
                wedSchedule: '',
                thuSchedule: '',
                friSchedule: '',
                satSchedule: '',
                sunSchedule: '',
                color: color
            });
            setScheduleConfigParams(copy);
            //초기화 시켜준다
            setInputConfig({
                bidScheduleDetId: undefined,
                hopeRank: '',
                maxBidCost: '',
                minBidCost: '',
                bidConfigYn: true
            });
            setAddConfigYn(true);
        };

        // 입찰 조건 갯수 validation
        if (scheduleConfigParams.length === 5) {
            errorAlert("입찰 조건은 5개까지 추가 가능합니다.");
            return false;
        }

        if (addConfigValidation()) {
            addConfig(
                inputConfig.hopeRank,
                inputConfig.minBidCost,
                inputConfig.maxBidCost,
                inputConfig.bidConfigYn
            );
        } else if (!inputConfig.bidConfigYn) {
            // 입찰 사용이 'OFF'일 경우 기본값 '-'로 설정
            addConfig('-', '-', '-', inputConfig.bidConfigYn);
        } else {
            return false;
        }
    };

    /** 2개의 조건을 비교하여 true/false 리턴해준다. 삭제 시에 주로 사용 */
    const equalsConfigCheck = (existConfig: BidScheduleStateConfigProps, deleteConfig: BidScheduleStateConfigProps) => {
        return existConfig.hopeRank === deleteConfig.hopeRank &&
            existConfig.maxBidCost === deleteConfig.maxBidCost &&
            existConfig.minBidCost === deleteConfig.minBidCost &&
            existConfig.color === deleteConfig.color
    }
    /** 조건 삭제 이벤트 */
    const deleteConfigEvent = (deleteConfig: BidScheduleStateConfigProps) => {
        //스케줄러 영역에서 삭제 처리
        const classList = ".cube.selected-" + deleteConfig.color.toLowerCase();
        let findScheduleCubes = document.querySelectorAll(classList);
        findScheduleCubes.forEach((v) => {
            v.classList.remove(`selected-` + deleteConfig.color.toLowerCase());
        })

        //현재 선택중인 조건을 삭제했다면 선택중인 조건 해제
        if (selectedConfig !== undefined) { // 선택을 아무것도 하지 않았을 경우도 있음.
            if (equalsConfigCheck(selectedConfig, deleteConfig)) {
                setSelectedConfig(undefined);
            }
        }
        //전체 스케줄 파라미터에서 해당 조건 삭제
        let copy = [...scheduleConfigParams];
        setScheduleConfigParams(copy.filter((config) => !(equalsConfigCheck(config, deleteConfig))))
    }

    /** 전체 스케줄 데이터 만들기 이벤트 */
    const makeScheduleParamsEvent = () => {
        if (scheduleConfigParams.length === 0) {
            errorAlert("조건 설정을 확인해주세요.")
        } else {
            //생성되어 있는 조건별로 돌린다.
            scheduleConfigParams.map((scheduleConfig) => {
                //일자별로 전부 돌려야 한다..
                dateList.map((date) => {
                    const dateClassList = `cube ${date}`; // 날짜 체크용도 클래스

                    let elements = document.getElementsByClassName(dateClassList);
                    let dateTimeTable = "";
                    Array.from(elements).forEach((el) => {
                        dateTimeTable += el.classList.contains("selected-" + scheduleConfig.color.toLowerCase()) ? "1" : "0";
                    })
                    switch (date) {
                        case "mon" :
                            return scheduleConfig.monSchedule = dateTimeTable;
                        case "tue" :
                            return scheduleConfig.tueSchedule = dateTimeTable;
                        case "wed" :
                            return scheduleConfig.wedSchedule = dateTimeTable;
                        case "thu" :
                            return scheduleConfig.thuSchedule = dateTimeTable;
                        case "fri" :
                            return scheduleConfig.friSchedule = dateTimeTable;
                        case "sat" :
                            return scheduleConfig.satSchedule = dateTimeTable;
                        case "sun" :
                            return scheduleConfig.sunSchedule = dateTimeTable;
                    }
                })
                setScheduleParams({...scheduleParams, bidScheduleDetList: scheduleConfigParams});
                setRegBtnClickYn(true);
            })
        }
    }
    /** 컴포넌트 로드 시 데이터가 존재하는 경우 값 설정*/
    const configComponentInit = () => {
        if (selectedAdv !== undefined && selectedAdv.bidScheduleDetList !== undefined) {
            setScheduleConfigParams(selectedAdv.bidScheduleDetList);
        }
    }

    const moveBidScheduleManagePage = () => {
        navigate('/kwdBid/bidScheduleManage');
    }

    useEffect(() => {
        configComponentInit();
    }, [])

    useEffect(() => {
        if (regBtnClickYn) {
            setRegEventCallYn(true);
            setRegBtnClickYn(false);
        }
    }, [regBtnClickYn])

    useEffect(() => {
        if (componentResetEventYn) {
            //선택 중인 조건 초기화
            setSelectedConfig(undefined);
            //전체 조건 초기화
            setScheduleConfigParams([]);
        }
    }, [componentResetEventYn]);
//조건을 추가하였을 때 자동 선택 추가
    useEffect(() => {
        if (addConfigYn) {
            setSelectedConfig(scheduleConfigParams[scheduleConfigParams.length - 1]);
            setAddConfigYn(false);
        }
    }, [addConfigYn])

    return (
        <>
            {/* <!-- Wrap-Tbl : Start --> */}
            <section className="wrap-section wrap-tbl">
                <div className="box-header">
                    <div className="box-left">
                        <Typography.Title level={3} className="fc-gray-700">조건 설정</Typography.Title>
                        <Typography.Text className="fz-14 fc-gray-300">조건은 5개까지 추가 가능하며, 항목 미입력 시 키워드별 입찰 설정값으로 적용됩니다.</Typography.Text>
                    </div>
                </div>
                <div className="box-body">
                    <div className="tbl">
                        <dl className="col-two">
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-15 fc-gray-500">목표 순위</span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <Input className="w-500" value={inputConfig.hopeRank}
                                           onChange={(e) => {
                                               setInputConfig({
                                                   ...inputConfig,
                                                   hopeRank: e.target.value
                                               });
                                           }} placeholder="키워드별 설정값"/>
                                </div>
                            </dd>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-15 fc-gray-500">입찰 사용</span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <Select
                                        style={{ width: 150 }}
                                        value={inputConfig.bidConfigYn}
                                        onChange={(value) => {
                                            setInputConfig({
                                                ...inputConfig,
                                                bidConfigYn: value
                                            });
                                        }}
                                        options={onOffList(false)}
                                    />
                                </div>
                            </dd>
                        </dl>
                        <dl className="col-two">
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-15 fc-gray-500">최대 입찰가</span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <Input className="w-500" name="maxBidCost"
                                           placeholder="키워드별 설정값"
                                           value={inputConfig.maxBidCost}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                               setInputConfig({...inputConfig, maxBidCost: e.target.value})
                                        }}
                                    />
                                </div>
                            </dd>
                            <dt>
                                <div className="dt-inner">
                                    <span className="fz-15 fc-gray-500">최소 입찰가</span>
                                </div>
                            </dt>
                            <dd>
                                <div className="form-group">
                                    <Tooltip title="최소입찰가 미 입력 시 네이버 검색광고 최소 입찰가가 적용됩니다.">
                                        <Input className="w-500" name="minBidCost" placeholder="키워드별 설정값"
                                               value={inputConfig.minBidCost}
                                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    setInputConfig({...inputConfig, minBidCost: e.target.value})
                                               }}
                                        />
                                    </Tooltip>
                                </div>
                            </dd>
                        </dl>

                    </div>
                </div>
                <div className="box-footer">
                    <div className="box-center">
                        <Button type="primary" className="pink" size="large" icon={<i className="ico ico-add"></i>}
                                onClick={addConfigEvent}>조건 추가</Button>
                    </div>
                </div>
            </section>
            {/* <!-- Wrap-Tbl : End --> */}

            {/* <!-- Wrap-Schedule : Start --> */}
            <section className="wrap-section wrap-schedule">
                <div className="box-body">
                    {/*조건 필드 : Start */}
                    <Flex className="schedule-terms" align="center" justify="flex-start" gap={24}>
                        {scheduleConfigParams.map((config,index)=> {
                            let colorClass = "color-palette selected-"+config.color.toLowerCase();
                            let configClass = "box";
                            if(selectedConfig !== undefined){
                                //선택한 조건일 경우에 class 명을 바꿔줌.
                                const
                                    selectedConfigCheck = config.hopeRank === selectedConfig.hopeRank &&
                                    config.maxBidCost === selectedConfig.maxBidCost &&
                                    config.minBidCost === selectedConfig.minBidCost &&
                                    config.color === selectedConfig.color &&
                                    config.bidConfigYn === selectedConfig.bidConfigYn;

                                if(selectedConfigCheck) {
                                    configClass = "box selected-config";
                                }
                            }
                            return (
                                <React.Fragment key={"configField_"+index}>
                                    <div className="box-outer">
                                    <div className={configClass} onClick={()=> setSelectedConfig(config)}>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <div className="table">
                                                <div className="box-left">
                                                    <div className={colorClass}></div>
                                                </div>
                                                <div className="box-right">
                                                    <Flex>
                                                        <Typography.Text
                                                            className="fz-13 fc-gray-300">목표순위 :</Typography.Text>
                                                        <Typography.Text className="fz-13 fw-exbold fc-gray-500">&nbsp;{config.hopeRank === '' ? '키워드 설정값' : `${config.hopeRank}위`}</Typography.Text>
                                                        <i className="dot"></i>
                                                        <Typography.Text className="fz-13 fc-gray-300">입찰 사용 :</Typography.Text>
                                                        <Typography.Text className={`fz-13 fw-exbold ${config.bidConfigYn ? 'c-green' : 'fc-gray-500 c-red'}`}>&nbsp;{config.bidConfigYn ? 'ON' : 'OFF'}</Typography.Text>
                                                    </Flex>
                                                    <Flex>
                                                        <Typography.Text className="fz-13 fc-gray-300">MAX :</Typography.Text>
                                                        <Typography.Text className="fz-13 fw-exbold fc-gray-500">&nbsp;{config.maxBidCost === '' ? '키워드 설정값' : `${prettyNumber(config.maxBidCost)}원`}</Typography.Text>
                                                        <i className="dot"></i>
                                                        <Typography.Text className="fz-13 fc-gray-300">MIN :</Typography.Text>
                                                        <Typography.Text className="fz-13 fw-exbold fc-gray-500">&nbsp;{config.minBidCost === '' ? '키워드 설정값' : `${prettyNumber(config.minBidCost)}원`}</Typography.Text>
                                                    </Flex>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                        <Button type="primary" className="gray" icon={<CloseOutlined rev={undefined} />}
                                                onClick={()=> deleteConfigEvent(config)}
                                                size="small" />
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </Flex>
                    {/*조건 필드 : End */}

                    {/*스케줄 필드 : Start */}
                    <BidScheduleStateTimeTable
                        scheduleConfigParams={scheduleConfigParams}
                        selectedConfig={selectedConfig}
                        componentResetEventYn={componentResetEventYn}
                        setComponentResetEventYn={setComponentResetEventYn}
                    />
                </div>
                <div className="box-footer">
                    <div className="box-center">
                        <Button type="primary" className="gray" size="large" onClick={moveBidScheduleManagePage}>취소</Button>
                        <Button type="primary" className="pink" size="large" onClick={makeScheduleParamsEvent}>등록</Button>
                    </div>
                </div>
            </section>
        </>
    )
};

export default BidScheduleStateConfig;
