import ContentHeader from "../../components/layout/contentHeader";
import {Button, Card, Flex, Form, Input, Select, Space, Table, Tag, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {ApiManageGridProps, ApiManageSearchProps} from "./connectionMngTypes";
import {emptyTableLayout, paginationOptions} from "../../functions/tableFormatter";
import AdvInfoListModal from "../../components/connectionMng/apiManage/advInfoListModal";
import RegApiModal from "../../components/connectionMng/apiManage/regApiModal";
import {errorAlert, successAlert} from "../../functions/alertFn";
import modal from "antd/es/modal";
import {ExclamationCircleFilled} from "@ant-design/icons";
import ModifyApiModal from "../../components/connectionMng/apiManage/modifyApiModal";
import apiCall from "../../functions/apiCall";
import {useNavigate} from "react-router-dom";
import SkeletonTable, {SkeletonTableColumnsType} from "../../components/table/skeletonTable";
import {ColumnsType} from "antd/es/table";

// Typography
const { Title } = Typography;
// Selectbox - Option
const { Option } = Select;
const ApiManage = () => {
    /** 조회 필터 */
    const [ searchParams, setSearchParams ] = useState<ApiManageSearchProps>({loginId:'', linkPossType:'ALL'});

    /** 데이터 그리드 항목*/
    const [ gridLoading, setGridLoading ] = useState<boolean>(true);
    const [ rows, setRows] = useState<any[]>([]);
    const [ gridRefresh, setGridRefresh ] = useState<boolean>(false);
    const [ selectedRow, setSelectedRow ] = useState<ApiManageGridProps>(); // 모달 오픈 시 사용
    const [ deleteRows, setDeleteRows ] = useState<React.Key[]>([]); // 삭제 시 사용
    const rowSelection = { deleteRows, onChange: (newSelectedRowKeys: React.Key[]) => setDeleteRows(newSelectedRowKeys)};
    

    /** 모달 */
    const [ regApiModalOpenYn, setRegApiModalOpenYn ] = useState<boolean>(false);
    const [ advInfoListModalOpenYn, setAdvInfoListModalOpenYn] = useState<boolean>(false);
    const [ modifyApiModalOpenYn, setModifyApiModalOpenYn] = useState<boolean>(false);

    /** 테이블 초기화*/
    const [tableKey, setTableKey] = useState<number>(0);


    const deleteApiEvent = () => {
        if(deleteRows.length === 0) {
            errorAlert("삭제할 API를 선택 후 다시 클릭해주세요.");
        } else {
            modal.confirm({
                icon: <ExclamationCircleFilled rev={undefined} />,
                title: '선택한 API를 삭제하시겠습니까?',
                content: '삭제된 API는 더이상 Nbidon에서 수집할 수 없습니다.',
                okText: '확인', cancelText: '취소',
                okButtonProps: { className: "pink ant-btn-lg" },
                onOk() {
                    apiCall.put("/connMng/apiMng/deleteApiLicenseInfo",deleteRows)
                    .then(resp => {
                        successAlert("API 삭제가 완료되었습니다.");
                        setGridRefresh(true);
                        setDeleteRows([]);
                        setGridRefresh(true);
                    })
                    .catch(error => {
                        errorAlert("API 삭제 시 오류가 발생했습니다.<br />관리자에게 문의바랍니다.", {error});
                    })
                    .finally()
                },
                cancelButtonProps: { type: 'primary', className: "gray ant-btn-lg" },
            })
        }
    }
    /** 데이터 그리드 로드 */
    const pageInit = () => {
        setGridLoading(true)
        apiCall.post("/connMng/apiMng/findApiLicenseList",searchParams)
        .then(resp => {
            setRows(resp.data);
            setTableKey(prevKey => prevKey + 1);
            setGridLoading(false);
        })
        .catch(error => {
            errorAlert("API 데이터가 정상적으로 로드 되지 않습니다.<br />관리자에게 문의 바랍니다.",{error})
        })
        .finally();
    }

    //화면 리프래시
    useEffect(() => {
        if(gridRefresh) {
            pageInit();
            setGridRefresh(false);
        }
    }, [gridRefresh])
    //페이지 로드
    useEffect(() => {
        pageInit();
        document.title = "API 관리";
    }, [])

    // 광고주 관리 화면 이동
    let navigate = useNavigate();
    const moveAdvMngPage = () => {
        let moveAdvMngPage =  "/connectionMng/advManage";
        navigate(moveAdvMngPage);
    }
    
    // 그리드 칼럼
    const gridColumns: ColumnsType<any> = [
        {title: 'No', dataIndex: 'index', align: 'center', render: (_, record, index) => index+1},
        {title: '로그인 ID', dataIndex: 'loginId', align: 'center' },
        {title: 'Customer Id', dataIndex: 'customerId', align: 'center' },
        {title: '등록 광고주', dataIndex: ['advs', 'advId'], align: 'center', render: (_, record) =>{
                const advLength = record.advsCount;
                const buttonColorClass = advLength === 0 ? "gray" : "pink";
                return <Button type="primary" className={buttonColorClass} size="small" onClick={() => {
                    if(advLength !== 0) {
                        setSelectedRow(record);
                        setAdvInfoListModalOpenYn(true);
                    }else {
                        moveAdvMngPage();
                    }
                }}>{advLength} 개</Button>
            }
        },
        {title: '연동 상태', dataIndex: 'linkPossYn', align: 'center', render: (value:boolean) => {
                return value ?
                    <Tag color={"blue"} key={"blue"}>{"원활"}</Tag> : <Tag color={"red"} key={"red"}>{"에러"}</Tag>;
            }
        },
        {title: '수정', dataIndex: 'modifyApiBtn', align: 'center', render: (_, record) => {
                return <Button className="pink" size="small" onClick={() => {
                    setSelectedRow(record);
                    setModifyApiModalOpenYn(true);
                }}>수정</Button>
            }
        },
    ]
    return (
        <>
            <ContentHeader pageTitle={"API 관리"} navigation={["연동 관리", "API 관리"]}/>
            {/* <!-- Wrap-Filter : Start --> */}
            <section className="wrap-section wrap-filter">
                {/*<div className="box-header">
                    <div className="box-left">
                        <Title level={3} className="fc-gray-700">조회 필터</Title>
                    </div>
                </div>*/}
                <div className="box-body">
                    <Card>
                        <Flex gap={24}>
                            <div className="box-left">
                                <i className="ico ico-filter"></i>
                            </div>
                            <div className="box-center">
                                <Form>
                                    <Space size={32} wrap >
                                        <Form.Item label="로그인 ID" colon={false}>
                                            <Input placeholder="로그인 ID를 입력해주세요" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const {value} = e.target;
                                                setSearchParams({ ...searchParams, loginId: value});
                                            }} onPressEnter={pageInit}
                                            />
                                        </Form.Item>
                                        <Form.Item label="연동 상태" colon={false}>
                                            <Select
                                                style={{ width: 250 }}
                                                placeholder={'로그인 ID를 선택하세요.'}
                                                getPopupContainer = {triggerNode => triggerNode.parentElement}
                                                onChange={(linkPossType: string) => {
                                                    setSearchParams({ ...searchParams, linkPossType : linkPossType});
                                                }}
                                            >
                                                <Option value="ALL">전체</Option>
                                                <Option value="NORMAL">원활</Option>
                                                <Option value="ERROR">오류</Option>
                                            </Select>
                                        </Form.Item>
                                    </Space>
                                </Form>
                            </div>
                            <div className="box-right">
                                <Button type="primary" className="pink" onClick={pageInit}>조회하기</Button>
                            </div>
                        </Flex>
                    </Card>
                </div>
            </section>
            {/* <!-- Wrap-Filter : End --> */}

            {/* <!-- Wrap-Datagrid : Start --> */}
            <section className="wrap-section wrap-datagrid">
                <div className="box-header">
                    <div className="box-left">
                        <Title level={3} className="fc-gray-700">API 목록</Title>
                    </div>
                    <div className="box-right">
                        <Button type="primary" className="pink" onClick={() => setRegApiModalOpenYn(true)}>API 등록</Button>
                        <Button type="primary" className="gray" onClick={() => deleteApiEvent()}>삭제</Button>
                    </div>
                </div>
                <div className="box-body">
                    <SkeletonTable loading={gridLoading} columns={gridColumns as SkeletonTableColumnsType[]}>
                        <Table
                            key={tableKey}
                            bordered
                            columns={gridColumns}
                            dataSource={rows} rowKey="apiLicenseId"
                            rowSelection={rowSelection}
                            locale={emptyTableLayout(["등록된 API가 없습니다.","[API 등록] 버튼을 클릭하여 API를 등록하세요."])}
                            pagination={paginationOptions(rows)}
                        />
                </ SkeletonTable>
                </div>
            </section>
            {/* <!-- Wrap-Datagrid : End --> */}

            {/* 광고주 리스트 모달 */}
            { advInfoListModalOpenYn &&
                <AdvInfoListModal
                    selectRow={selectedRow!!}
                    isOpen={advInfoListModalOpenYn}
                    setOpen={setAdvInfoListModalOpenYn}
                />
            }
            {/* API 등록 모달 */}
            { regApiModalOpenYn &&
                <RegApiModal
                    isOpen={regApiModalOpenYn}
                    setOpen={setRegApiModalOpenYn}
                    setGridRefresh={setGridRefresh}
                />
            }
            {/* API 수정 모달 */}
            { modifyApiModalOpenYn &&
                <ModifyApiModal
                    record={selectedRow!!}
                    isOpen={modifyApiModalOpenYn}
                    setOpen={setModifyApiModalOpenYn}
                    setGridRefresh={setGridRefresh}
                />
            }
        </>
    )
};

export default ApiManage;