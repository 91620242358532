import {Breadcrumb, Flex, Select, Space} from "antd";
import React, {SetStateAction} from "react";
import {KeywordBidManageSearchProps} from "../../../pages/kwdBid/kwdBidTypes";


interface KeywordBidManageContentHeaderProps {
    pageTitle: string,
    navigation: string[]
    advList : {advId : number, advName: string}[]
    searchParams: KeywordBidManageSearchProps,
    setSearchParams : React.Dispatch<SetStateAction<KeywordBidManageSearchProps>>
}
const KeywordBidManageContentHeader = ({pageTitle, navigation, advList, searchParams, setSearchParams}: KeywordBidManageContentHeaderProps) => {
    let item: {title: string}[] = [];
    navigation.map((title) => item.push({title : title}));

    const changeAdvAndSearchEvent = (advId:number) => {
        setSearchParams({...searchParams, advId : advId});
    }
    return (
    <>
        <div className="content-header">
            <Flex justify="space-between" align="flex-end">
                <Space direction="vertical">
                    <Breadcrumb items={item} />
                    <h1 className="fz-32 fc-gray-900">{pageTitle}</h1>
                </Space>
                {advList.length > 0 &&
                    <Space>
                        <Select
                            placeholder="광고주를 선택하세요."
                            style={{ width: 200 }}
                            value={searchParams.advId}
                            onChange={(advId:number)=> {
                                //광고주 변경 및 검색 이벤트 시작
                                changeAdvAndSearchEvent(advId)
                            }}
                        >
                            {advList.map((adv: {advId:number, advName: string}) => {
                                return <Select.Option key={`${adv.advId}_CONTENT_SEARCH`} value={adv.advId}>{adv.advName}</Select.Option>
                            })}
                        </Select>
                    </Space>
                }
            </Flex>
        </div>
    </>
    );
}

export default React.memo(KeywordBidManageContentHeader);