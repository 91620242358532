import ContentHeader from "../../components/layout/contentHeader";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {BidScheduleProps} from "./kwdBidTypes";
import BidScheduleStateAdv from "../../components/kwdBid/bidScheduleState/BidScheduleStateAdv";
import BidScheduleStateConfig from "../../components/kwdBid/bidScheduleState/BidScheduleStateConfig";
import {errorAlert, successAlert} from "../../functions/alertFn";
import apiCall from "../../functions/apiCall";


const BidScheduleState = () => {
    /** 전달 받은 파라미터 정보 */
    window.scrollTo({ top: 0, behavior: 'auto'}); // 이거 공통으로 처리해야할 듯..?

    const location = useLocation();

    /** 광고주 리스트 */
    const [ advList, setAdvList] = useState<{advId: number, advName: string}[]>([]);
    const selectedAdv = location.state.selectedRow; // 수정에 한해 값이 설정되는 선택된 광고주 정보
    const documentTitle = selectedAdv === undefined ? "입찰 스케줄 등록" : "입찰 스케줄 수정";
    /** 전체 통합 등록/수정 시 정보 **/
    const [ scheduleParams, setScheduleParams ] = useState<BidScheduleProps>(
        {advId: undefined, bidScheduleId: undefined, bidScheduleName: undefined, bidScheduleDetList: undefined}
    );

    /** 등록 이벤트 호출할 변수 */
    const [ scheduleRegEventCallYn, setScheduleRegEventCallYn] = useState<boolean>(false);
    /** 리셋 진행할 변수 */
    const [ componentResetEventYn, setComponentResetEventYn ] = useState<boolean>(false);

    const scheduleRegValidation = () => {
        if(scheduleParams.advId === undefined || scheduleParams.bidScheduleName === ''){
            errorAlert("필수 입력 정보를 입력해주세요.");
            return false;
        }
        return true;
    }

     /** 광고주 리스트 로드 
      *  쉐도우로그인 안한 어드민일 경우 등록 페이지에서는 전체 광고주 목록을 보면 안되니까
      *  한번더 서버에서 해당 계정으로 등록된 광고주 목록 조회...(전체조회X)
      * **/
     const loadAdvs = () => {
        apiCall.get("/keywordBid/bidSchedule/findAdvList")
        .then(resp => {
            setAdvList(resp.data);
        })
        .catch()
        .finally()
    }

    /** 스케줄 등록 이벤트. 조건 관련 벨리데이션은 조건 컴포넌트에서 처리한다.
     * 여기서는 데이터 확인 후 이상 없을 시 서버단으로 호출. 이외 벨리데이션은 모두 서버단에서 체크.
     * */
    const scheduleRegEvent = () => {
        if (scheduleRegValidation()) {
            apiCall.post("/keywordBid/bidSchedule/regBidSchedule", scheduleParams)
                .then(resp => {
                    successAlert("스케줄 저장이 완료되었습니다.");
                    //각종 초기화 진행
                    setScheduleParams({
                        advId: undefined,
                        bidScheduleId: undefined,
                        bidScheduleName: undefined,
                        bidScheduleDetList: undefined
                    });
                    setScheduleRegEventCallYn(false);
                    //하위 컴포넌트들 모두 초기화
                    setComponentResetEventYn(true);
                    moveAdvMngPage();
                })
                .catch(error => {
                    setScheduleRegEventCallYn(false);
                    errorAlert(error.response.data.message);
                } )
                .finally(() => {})
        }
    }

    // 광고주 관리 화면 이동
    let navigate = useNavigate();
    const moveAdvMngPage = () => {
        let moveAdvMngPage =  "/kwdBid/bidScheduleManage";
        navigate(moveAdvMngPage);
    }

    useEffect(() => {
        loadAdvs(); // 페이지 진입시 로드
        document.title = documentTitle;
        if(scheduleRegEventCallYn) {
            scheduleRegEvent();
            setScheduleRegEventCallYn(false);
        }
    }, [scheduleRegEventCallYn])
    return (
        <>
            <ContentHeader
                pageTitle={documentTitle}
                navigation={["키워드 입찰", "입찰 스케줄 관리", documentTitle]} />
            {/* <!-- 상단 > 광고주 및 스케줄명 컴포넌트 : Start --> */}
            <BidScheduleStateAdv
                advList={advList}
                selectedAdv={selectedAdv}
                scheduleParams={scheduleParams}
                setScheduleParams={setScheduleParams}
                componentResetEventYn={componentResetEventYn}
            />
            {/* <!-- 상단 > 광고주 및 스케줄명 컴포넌트 : End --> */}
            {/* <!-- 중단 > 스케줄 조건 및 타임테이블 컴포넌트 : Start --> */}
            <BidScheduleStateConfig
                selectedAdv={selectedAdv}
                scheduleParams={scheduleParams}
                setScheduleParams={setScheduleParams}
                setRegEventCallYn={setScheduleRegEventCallYn}
                componentResetEventYn={componentResetEventYn}
                setComponentResetEventYn={setComponentResetEventYn}
            />
            {/* <!-- 중단 > 스케줄 조건 및 타임테이블 컴포넌트 : End --> */}
        </>
    )
};

export default BidScheduleState;