import Selecto from "react-selecto";
import {Button, Flex, Typography} from "antd";
import React, {useEffect} from "react";
import {BidScheduleStateConfigProps} from "../../../pages/kwdBid/kwdBidTypes";
import {makeScheduleCubes, paintScheduleCubes, scheduleCubeHeader} from "../../../functions/bidScheduleFn";

/** 실제 기능은 박스 드래그 시 색상 칠하는 Selecto 및 초기화 외에는 없음. 모두 조건 컴포넌트에서 처리한다. */
interface BidScheduleStateTimeTableComponentProps {
    scheduleConfigParams: BidScheduleStateConfigProps[] | [],
    selectedConfig: undefined | BidScheduleStateConfigProps,
    componentResetEventYn: boolean,
    setComponentResetEventYn: React.Dispatch<React.SetStateAction<boolean>>
}
/** 큐브 페인트 지울 때 사용 */
const deleteClassList = ["selected-red", "selected-yellow", "selected-blue", "selected-green", "selected-purple"];
const BidScheduleStateTimeTable = ({scheduleConfigParams, selectedConfig, componentResetEventYn, setComponentResetEventYn}: BidScheduleStateTimeTableComponentProps) => {

    /** 스케쥴 페인트 초기화 시 사용 */
    const timeTableResetEvent = () => {
        deleteClassList.map((className) => {
            const classList = `.cube.${className}`;
            let findScheduleCubes = document.querySelectorAll(classList);
            findScheduleCubes.forEach((v)=> v.classList.remove(className))
        })
    }

    /** 수정 버튼을 통해 들어올 경우 스케줄 상세가 설정되어 있으면 미리 페인팅 처리 */
    useEffect(() => {
        if(scheduleConfigParams.length > 0){
            paintScheduleCubes(scheduleConfigParams);
        }
    }, [scheduleConfigParams])

    /** 전체 컴포넌트가 리셋되는 경우 */
    useEffect(() => {
        if(componentResetEventYn) {
            timeTableResetEvent();
        }
        setComponentResetEventYn(false);
    }, [componentResetEventYn])

    return (
        <>
            <Selecto
                dragContainer={".elements"}
                selectableTargets={[".selecto-area .cube"]}
                hitRate={10}
                selectByClick={true}
                selectFromInside={true}
                continueSelect={true}
                ratio={0}
                onSelectEnd={e => {
                    //조건을 선택한 경우에만 작동하도록
                    if (selectedConfig !== undefined) {
                        const cssClassName = "selected-" + selectedConfig.color.toLowerCase();
                        // 기존 걸 삭제한다. 우리는 오버라이드 할꺼라서 지우고 추가함.
                        e.removed.forEach((el) => {
                            if (el.classList.contains(cssClassName)) {
                                el.classList.remove("selected-red", "selected-green", "selected-blue", "selected-yellow", "selected-purple");
                            } else {
                                el.classList.remove("selected-red", "selected-green", "selected-blue", "selected-yellow", "selected-purple");
                                el.classList.add(cssClassName);
                            }

                        });
                        // 아무것도 없던 녀석일 경우 여길 탄다. 클래스만 추가한다.
                        e.added.forEach(el => {
                            el.classList.remove("selected-red", "selected-green", "selected-blue", "selected-yellow", "selected-purple");
                            el.classList.add(cssClassName);
                        });
                    }
                }}
            />
            <div className="box-left">
                <Typography.Text className="fz-14 fc-gray-300">조건이 설정되지 않은 시간대의 경우, 기존 키워드에 설정된 입찰 조건으로 자동입찰이 진행됩니다</Typography.Text>
            </div>
            <Flex justify="flex-end">
                <Button className="gray" onClick={timeTableResetEvent}>스케줄 초기화</Button>
            </Flex>
            <Flex className="elements selecto-area" id="selecto1" wrap="nowrap"> {/* 스케쥴 크기 맞춤 */}
                <div className="row-day-week">
                    {scheduleCubeHeader().date.map((dateText) => <div className="day"
                                                                      key={`${dateText}DateHeader`}>{dateText}</div>)}
                </div>

                <Flex className="time-box" vertical>
                    <Flex className="col-time">
                        {scheduleCubeHeader().time.map((timeText) => <div className="time"
                                                                          key={`${timeText}TimeHeader`}>{timeText}</div>)}
                    </Flex>
                    <div>
                        {makeScheduleCubes(false).map((divKey) => {
                            if (typeof divKey === "string") {
                                const classList = `cube ${divKey.split("_")[0]}`;
                                return <div className={classList} key={divKey}
                                            id={divKey + "_column"}></div>
                            }
                        })}
                    </div>
                </Flex>
            </Flex>
        </>
    )
};

export default BidScheduleStateTimeTable;